import React from 'react'

import { Container, Col, Row } from 'react-bootstrap'

import { withRedux } from '../../../Redux/'
import * as TYPES from './types'

// import Badge from '../../../Components/Badge'
import { PaperContent } from '../../../Components/Contents'
import Separator from '../../../Components/Separator'
import Up from '../../../Components/Up'

const SearchList: React.FC<TYPES.SearchListProps> = (props: TYPES.SearchListProps) => {
  // get Search Data from Redux
  const { search } = props
  const { httpInProgress, httpError, search_data } = search
  const splitSearchData = (by: number) => {
    // eslint-disable-next-line prefer-spread
    return [].concat.apply([], (search_data.map((element: any, i: number) => {
      return (i % by) ? [] : [search_data.slice(i, i + by)]
    }) as any[]))
  }
  /** Render */
  return (
    <PaperContent className="search-list">
      <Separator type={'blick'}/>
      { (!!search_data.length && !httpInProgress && !httpError) && (
        <Container>
          <Row className="mx-1">
            <Col xs={12} md={3} className="px-mx-0">
              <h2 className="search-list-title text-bold">{search_data.length} PLAYERS FOUND</h2>
            </Col>
          </Row>
          { splitSearchData(4).map((rowOfItems: Array<any>, rowIndex: number) =>
            (<Row key={'search-row-' + rowIndex}>
              {
                rowOfItems.map((item: any, index: number) => (
                  <Col xs={12} md={3} key={'search-item-' + index} className="px-2 mx-0">
                    <a className="search-item-a" href={'/public/profile/' + item.userName}>
                      <div className="search-item">
                        <div className="search-item-inside">
                          <Row>
                            <Col>{item.displayName}</Col>
                            {/* <div className="search-item-badge-cover">
                              <Badge badge={item.userSelectedBadge}/>
                            </div> */}
                          </Row>
                        </div>
                      </div>
                    </a>
                  </Col>
                ))
              }
            </Row>)
          )}
        </Container>
      )}
      { (!!search_data.length && !httpInProgress && !httpError) && (<Up/>)}
    </PaperContent>
  )
}

export default withRedux(SearchList)
