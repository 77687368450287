import React from 'react'

import * as ROUTES from '../Constants/routes'
import * as TYPES from './types'

import { Route, Redirect } from 'react-router'

// redux imports
import { withRedux } from '../Redux/'
/**
 * Authorized Route Class Component
 * Used to redirect not authenticated users to Landing Page
 */
class AuthorizedRoute extends Route<TYPES.AuthorizedRouteProps> {
  public render () {
    const { auth_token } = this.props.auth
    if (auth_token) return <Route {...this.props}/>
    else return <Redirect to={ROUTES.AUTH}/>
  }
}

export default withRedux(AuthorizedRoute)
