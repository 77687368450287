import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
// import all reducers
import { trelloReducer } from './trello/reducers'
import { twitchReducer } from './twitch/reducers'
import { steamReducer } from './steam/reducers'
import { leaderboardsReducer } from './leaderboards/reducers'
import { authReducer } from './auth/reducers'
import { profileReducer } from './profile/reducers'
import { accountReducer } from './account/reducers'
import { searchReducer } from './search/reducers'
import { contactReducer } from './contact/reducers'
// import all actions
import * as trelloActions from './trello/actions'
import * as twitchActions from './twitch/actions'
import * as steamActions from './steam/actions'
import * as leaderboardsActions from './leaderboards/actions'
import * as authActions from './auth/actions'
import * as profileActions from './profile/actions'
import * as accountActions from './account/actions'
import * as searchActions from './search/actions'
import * as contactActions from './contact/actions'
// import all types
import * as trelloTypes from './trello/types'
import * as twitchTypes from './twitch/types'
import * as steamTypes from './steam/types'
import * as leaderboardsTypes from './leaderboards/types'
import * as authTypes from './auth/types'
import * as profileTypes from './profile/types'
import * as accountTypes from './account/types'
import * as searchTypes from './search/types'
import * as contactTypes from './contact/types'

// combine reducers into a single reducer function
const rootReducer = combineReducers({
  trello: trelloReducer,
  twitch: twitchReducer,
  steam: steamReducer,
  leaderboards: leaderboardsReducer,
  auth: authReducer,
  profile: profileReducer,
  account: accountReducer,
  search: searchReducer,
  contact: contactReducer
})

// Redux AppState type
export type ReduxAppState = ReturnType<typeof rootReducer>;

// Redux App Actions interface
export interface ReduxAppInterface extends
trelloTypes.TrelloActionsInterface,
twitchTypes.TwitchActionsInterface,
steamTypes.SteamActionsInterface,
leaderboardsTypes.LeaderboardsActionsInterface,
authTypes.AuthActionsInterface,
profileTypes.ProfileActionsInterface,
accountTypes.AccountActionsInterface,
searchTypes.SearchActionsInterface,
contactTypes.ContactActionsInterface {}

// withRedux connect function for components
export function withRedux (component: ComponentType<any>): ComponentType<any> {
  const mapStateToProps = (state: ReduxAppState) => ({
    trello: state.trello,
    twitch: state.twitch,
    steam: state.steam,
    leaderboards: state.leaderboards,
    auth: state.auth,
    profile: state.profile,
    account: state.account,
    search: state.search,
    contact: state.contact
  })

  const actions = {
    ...trelloActions,
    ...twitchActions,
    ...steamActions,
    ...leaderboardsActions,
    ...authActions,
    ...profileActions,
    ...accountActions,
    ...searchActions,
    ...contactActions
  }

  return connect(
    mapStateToProps,
    actions
  )(component)
}

// store initialization
export default function configureStore () {
  const middlewares = [thunkMiddleware]
  const middleWareEnhancer = applyMiddleware(...middlewares)

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  )

  return store
}
