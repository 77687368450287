import React from 'react'

import { Container, Col, Row } from 'react-bootstrap'

import { PaperContent } from '../../Components/Contents'
import Up from '../../Components/Up'

import { Helmet } from 'react-helmet'

export const TermsOfService: React.FC = () => {
  return (
    <PaperContent className="policies">
      <Helmet>
        <title>Project Genesis | Terms Of Service</title>
        <meta name="description" content="Terms Of Service page for Project Genesis" />
      </Helmet>
      <Container>
        <Row>
          <Col className="px-4">
            <h2 className="text-bold">TERMS OF SERVICE</h2>
          </Col>
        </Row>
        <Row>
          <Col className="px-4">
            <h3 className="text-bold">Who May Use the Products, Services and Sites owned by 8 Circuit Studios</h3>
          </Col>
        </Row>
        <Row>
          <Col className="px-4">
            <p className="text-left">8 Circuit Studios, Inc. (“8 Circuit Studios”) is a corporation registered in Washington State, USA and offers merchandise products, online and mobile games, and other software services (“Sites, Products, and/or Services”) for use, purchase or subscription.
              <br/>You may use 8 Circuit Studios’s Sites, Products and Services, only if you are 13 years of age or older and thus capable of forming a binding contract. Additionally, you agree that you are not barred from using 8 Circuit Studios’ Products, Services and Sites under applicable law. If you are between 13 and 18 years old, you agree that your legal guardian has reviewed and agreed to all Terms and is happy for you to access 8 Circuit Studios’ Products and Services. You agree that you won’t disclose any associated Account passwords to anyone and you’ll notify us immediately if any unauthorized use of your Account involving 8 Circuit Studios Products and Services. You are responsible for all activities that occur under your 8 Circuit Studios owned Accounts, whether or not you know about them.
            </p>

            <h4 className="text-left text-bold">Restrictions</h4>
            <p className="text-left">You may not use any technological or other means (such as by cheating or using bugs or glitches in the Sites, Products and Services, or by using third party tools or software) to use the Sites, Products and Services that is not within the spirit of fair play to these terms. You specifically agree that you will not:
              <br/>&bull; Use the Services for fraudulent or abusive purposes (including, without limitation, by using the Services to impersonate and person or entity, or otherwise misrepresent our affiliation with a person, entity or our Sites, Products and Services);
              <br/>&bull; Interfere with or disrupt the Sites, Products and Services or networks that provide them;
              <br/>&bull; Attempt to decompile, reverse engineer, disassemble or hack any of the Sites, Products and Services, or to defeat or overcome any encryption technologies or security measures or data transmitted, processed or stored by us;
              <br/>&bull; `Harvest`, `Scrape` or collect any information about or regarding other people that use the Sites, Products and Services, including but not limited to any personal data or information (including but not limited to ‘pixel tags’ cookies, graphics interchange formats (‘gifs’) or similar items a.k.a. ‘Spyware’ or ‘pcms’ (passive collection mechanisms);
              <br/>&bull; Disrupt the normal flow of a game or otherwise act in a manner that is likely to negatively affect other players’ ability to compete fairly when playing the games
              <br/>&bull; Disobey and requirements or regulations of any network connected to the Sites, Products, and Services;
              <br/>&bull; Circumvent technological measures designed to control access to, or elements of, the Sites, Products and Services;
            </p>

            <h4 className="text-left text-bold">User Generated Content</h4>
            <p className="text-left">8 Circuit Studios assumes no responsibility for the conduct of any user submitting User Content and assumes no responsibility for monitoring our Sites, Products and Services. By using our Sites, Products and Services, you may be exposed to User Content that is offensive, indecent or otherwise not in line with your expectations.
              <br/>You hereby grant to 8 Circuit Studios, Inc. an irrevocable, perpetual, transferable, fully paid-up, royalty-free, worldwide license (including the right to sublicense and assign to third party) and right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter into computer memory, and use and practice, in any way, your User Content as well as all modified and derivative works thereof in connection with our provision of our Service, including Marketing and promotions of our Service. You also grant to 8 Circuit Studios the right to authorize others to exercise any of the rights granted to 8 Circuit Studios under these Terms of Service. You further hereby grant to 8 Circuit Studios the unconditional, irrevocable right to use your name, likeness and any other information or material included in any User Content and in connection with any User Content and nothing in these Terms of Service is intended to restrict any rights that you may have to use and exploit your own User Content. 8 Circuit Studios has no obligation to monitor or enforce your intellectual property rights in or to your User Content.
              <br/>If you provide 8 Circuit Studios with any feedback or suggestions about its Sites, Products, or Services, 8 Circuit Studios is free to use the feedback or suggestions however it chooses, without any obligation to account to you.
            </p>

            <h4 className="text-left text-bold">DMCA/Copyright Policy</h4>
            <p className="text-left">8 Circuit Studios respects copyright law and expects its users to do the same. It is 8 Circuit Studios’ policy to terminate in appropriate circumstances Account holders who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders.</p>

            <h4 className="text-left text-bold">Suspension / Termination</h4>
            <p className="text-left"> Any terms and conditions of this Agreement. Upon termination of your account, your right to the Products and Services will immediately cease. If you wish to terminate your account, you may (1) discontinue use of Products and Services (2) contact 8 Circuit Studios at yourallies@8circuitstudios.com. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

            <h4 className="text-left text-bold">Disclaimer of Warranties</h4>
            <p className="text-left">Without limiting 8 Circuit Studios’ liability under the section contained within these Terms Limitation of Liability and Indemnification, our Sites, Products and Services are provided on an “as is” and “as available” basis for your use, with no warranties of any kind, express or implied, including without limitation the warranties of merchantability, title, non-infringement, and those arising from a course of dealing or usage of trade. 8 Circuit Studios does not warrant that you will be able to access or use our Sites, Products and Services at the times or locations of your choosing, that our Sites, Products and Services will be uninterrupted or error-free; that defects will be corrected; or that our Sites, Products and Services are free of viruses or other harmful components.</p>

            <h4 className="text-left text-bold">Limitation of Liability and Indemnification</h4>
            <p className="text-left">8 Circuit Studios will not be liable to you for any indirect, incidental, consequential, special, punitive or other similar damages, including but not limited to loss of revenues, loss of profits, lost data or business interruption or other tangible losses (however such losses are qualified), arising out of or relating in any way to these Terms of Service or our Service itself, whether based on contract, tort or any other legal theory, and whether or not 8 Circuit Studios has been advised of the possibility of such damages. As current Sites, Products, and Services are provided without charge by 8 Circuit Studios, your sole remedy (and 8 Circuit Studios’ exclusive liability) for any dispute with 8 Circuit Studios is to stop using our Service and to cancel your account by contacting 8 Circuit Studios at yourallies@8circuitstudios.com.
              <br/>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain types of damages. Accordingly, some of the above disclaimers and limitations may not apply to you. To the extent that 8 Circuit Studios may not, as a matter of applicable law, disclaim any warranty or limit its liability as set forth herein, the scope of such warranty and the extent of 8 Circuit Studios’ liability will be the minimum permitted under such applicable law.
              <br/>In particular, nothing these Terms of Service will affect the statutory rights of any consumer or exclude or restrict any liability for death or personal injury arising from any negligence or fraud of 8 Circuit Studios.
            </p>

            <h4 className="text-left text-bold">Links to Third Party Websites or Resources</h4>
            <p className="text-left">The Products, Services, and Sites may contain links to third party websites or resources. We provide these links only as a convenience and are not responsible for the content, products, or services on or available from those websites or resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from your use of any third party websites or resources.</p>

            <h4 className="text-left text-bold">Updates to our Sites, Products, and Services</h4>
            <p className="text-left">You understand that our Sites, Products, and Services are developing and evolving. 8 Circuit Studios may require you to accept updates. While 8 Circuit Studios will make all reasonable efforts to inform to inform you of any such updates, you acknowledge and agree that 8 Circuit Studios may update our Sites, Products, and Services , with or without informing or otherwise notifying you. You may need to update third party software from time to time in order to access our Sites, Products, and Services.</p>

            <h4 className="text-left text-bold">Prohibited Uses</h4>
            <p className="text-left">You may use the Services only for lawful purposes and in accordance with these Terms of Service. You agree not to access or use the Services for any purpose that is illegal or beyond the scope of the Services’ intended use (in 8 Circuit Studios’ sole judgment).</p>

            <h4 className="text-left text-bold">Governing Law</h4>
            <p className="text-left">These Terms are governed exclusively by the law in force in Washington, United States of America. All legal actions in connection with these terms shall be bought in the state or federal courts locations in Washington, in the United States of America. The Convention on Contracts for the International Sale of Goods will not apply.</p>

            <h4 className="text-left text-bold">Agreement to Terms</h4>
            <p className="text-left">This agreement takes effect as soon as you indicate your acceptance of these terms. You may not use Sites, Products, or Services owned by 8 Circuit Studios if you are under the age of 13. 8 Circuit Studios’ Sites, Products, or Services are not intended for children under 13 and 8 Circuit Studios will not knowingly collect personal information from children under 13.
              <br/>By using 8 Circuit Studios’ Sites, Products and Services, you are agreeing to 8 Circuit Studios’  Terms and Conditions. If you are the parent or legal guardian of a child that is between the ages of 13 and 17 years (the “Parent”), you are agreeing to these Terms on behalf of yourself and your child(ren) who are authorized to use the Services pursuant to these Terms.
            </p>
          </Col>
        </Row>
      </Container>
      <Up/>
    </PaperContent>
  )
}

export default TermsOfService
