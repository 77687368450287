import * as TYPES from './types'

// define initial state object
const initialState: TYPES.SearchState = {
  httpInProgress: false,
  httpError: '',
  search_data: [],
  isFirstSearch: true
}

// define profile reducer
export function searchReducer (
  state = initialState,
  action: TYPES.SearchActionTypes
): TYPES.SearchState {
  switch (action.type) {
    case TYPES.SEARCH_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpInProgress: true, httpError: '', isFirstSearch: false }
      }
    }
    case TYPES.SEARCH_ERROR: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: action.error }
      }
    }
    case TYPES.SEARCH_USERS: {
      return {
        ...state,
        ...{ search_data: action.search_data },
        ...{ httpInProgress: false, httpError: '' }
      }
    }
    default:
      return state
  }
}
