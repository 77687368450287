/* eslint-disable no-var */
import React, { useEffect, useState } from 'react'
import { InputGroup, Form, Col, Alert, Image, Row } from 'react-bootstrap'

import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'
// import * as ROUTES from '../../Constants/routes'
// import * as AUTH from '../../Constants/auth'

// redux imports
import { withRedux } from '../../Redux/'
// import { useWindowDimensions } from '../../Utils'

// icons
// import iconUser from '../../assets/auth/IconUser.svg'
// import iconPw from '../../assets/auth/IconPw.svg'
import iconCode from '../../assets/auth/IconCode.svg'

declare global {
    var google: typeof import('google-one-tap')
}

export const FormAuth: React.FC<TYPES.FormAuthProps> = (props: TYPES.FormAuthProps) => {
  // get needed props
  const {
    auth,
    authLoginWithSteam,
    location
  } = props

  // const [steamKeyCode, setSteamKeyCode] = useState('')

  // const handleSteamKeyCodeChange = (event: any) => {
  //   setSteamKeyCode(event.target.value)
  // }

  useEffect(() => {
    // parse url for steam verification
    const query = new URLSearchParams(location.search)
    const steamID = query.get('SteamID')
    const authToken = query.get('authToken')

    if (steamID && authToken) {
      authLoginWithSteam(steamID, authToken)
    }
  }, [])

  /**
   * Render
   */
  return (
    <Form>
      {/* <Form.Row>
        <Form.Group as={Col} controlId="code">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="prepend">
                <Col className="px-mx-0">
                  <Image className="auth-icon" src={iconCode}/>
                </Col>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Promo Code"
              aria-describedby="prepend"
              className="data-hj-whitelist"
              disabled={auth.httpInProgress}
              onChange={handleSteamKeyCodeChange}
              value={steamKeyCode}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid"> Please provide at least 3 symbols. </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Form.Row> */}

      <Row>
        <Col xs={1}/>
        <Col>
          {auth.httpError && (<Alert variant="danger">{auth.httpError}</Alert>)}
        </Col>
        <Col xs={1}/>
      </Row>

      <div className="bottom-margin"></div>

      <Row>
        <Col className="text-center">
          {!auth.httpInProgress && (
            <a href={auth.steamAuthUrl + '&' + (Math.random() * 1000000)} className="pg-link cursor-pointer">
              <img src="https://steamcdn-a.akamaihd.net/steamcommunity/public/images/steamworks_docs/english/sits_small.png" alt="sits_small.png" title="sits_small.png"/>
            </a>
          )}
          {auth.httpInProgress && (
            <div className={ auth.httpInProgress ? 'spinner-border text-secondary' : 'd-none'} style={{ width: '3rem', height: '3rem' }} role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </Col>
      </Row>

      <div className="bottom-margin"></div>
    </Form>
  )
}

export default withRedux(FormAuth)
