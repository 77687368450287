import React, { useState, useEffect } from 'react'

interface WindowDimensionsInterface {
    width: number;
    height: number;
    isMobile: boolean;
    isDesktopStatic: boolean;
}

const windowDimensions = (): WindowDimensionsInterface => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
    isMobile: width < 768,
    isDesktopStatic: width >= 1300
  }
}

export const useWindowDimensions = (): WindowDimensionsInterface => {
  const [dimensions, setWindowDimensions] = useState(windowDimensions())

  useEffect(() => {
    function handleResize () {
      setWindowDimensions(windowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return dimensions
}

// HOC component

interface WindowComponentState {
  width?: number;
  height?: number;
  isMobile?: boolean;
  isDesktopStatic?: boolean;
}

export interface WindowComponentProps {
  isMobile: boolean;
  isDesktopStatic: boolean;
}

export const withWindowDimensions = <P extends object>(Component: React.ComponentType<P>) =>
  class WithWindowDimensions extends React.Component<P & WindowComponentProps, WindowComponentState> {
    state: Readonly<WindowComponentState> = {
      width: 0,
      height: 0,
      isMobile: false,
      isDesktopStatic: false
    }

    constructor (props: Readonly<P & WindowComponentProps>) {
      super(props)
      const { innerWidth: width, innerHeight: height } = window
      this.state = {
        width,
        height,
        isMobile: width < 768,
        isDesktopStatic: width >= 1300
      }
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    componentDidMount () {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions () {
      const { innerWidth: width, innerHeight: height } = window
      this.setState({
        width,
        height,
        isMobile: width < 768,
        isDesktopStatic: width >= 1300
      })
    }

    render () {
      const { ...props } = this.props
      return (
        <Component {...props as P} isMobile={this.state.isMobile} isDesktopStatic={this.state.isDesktopStatic}/>
      )
    }
  }
