import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'
import * as API from '../api'

const twitchClientID = '3bfcdyfz0kmwqqq65qonyo5v9uklt0'
/**
 * Default Auth State
 */
export const defaultState = {
  httpInProgress: false,
  httpActionInProgress: false,
  httpError: '',
  auth_token: '',
  auth_userSteamID: '',
  auth_userSteamDisplayName: '',
  auth_userSteamProfileImage: '',
  auth_userTwitchID: '',
  auth_userTwitchDisplayName: '',
  auth_userTwitchProfileImage: '',
  auth_userSteamCode: '',
  auth_userSteamKey: ''
}

// define initial state object
const initialState: TYPES.AuthState = {
  steamAuthUrl: 'https://steamcommunity.com/openid/login' +
    '?openid.ns=http://specs.openid.net/auth/2.0' +
    '&openid.mode=checkid_setup' +
    '&openid.return_to=' + API.URL.AUTH_LOGIN_WITH_STEAM_REDIRECT +
    '&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select' +
    '&openid.identity=http://specs.openid.net/auth/2.0/identifier_select',
  twitchAuthUrl: 'https://id.twitch.tv/oauth2/authorize' +
    '?response_type=code' +
    '&client_id=' + twitchClientID +
    '&redirect_uri=' + window.location.protocol + '//' + window.location.host + ROUTES.PROFILE +
    '&scope=viewing_activity_read',
  httpInProgress: defaultState.httpInProgress,
  httpActionInProgress: defaultState.httpActionInProgress,
  httpError: defaultState.httpError,
  auth_token: localStorage.getItem('auth_token') || defaultState.auth_token,
  auth_userSteamID: localStorage.getItem('auth_userSteamID') || defaultState.auth_userSteamID,
  auth_userSteamDisplayName: localStorage.getItem('auth_userSteamDisplayName') || defaultState.auth_userSteamDisplayName,
  auth_userSteamProfileImage: localStorage.getItem('auth_userSteamProfileImage') || defaultState.auth_userSteamProfileImage,
  auth_userTwitchID: localStorage.getItem('auth_userTwitchID') || defaultState.auth_userTwitchID,
  auth_userTwitchDisplayName: localStorage.getItem('auth_userTwitchDisplayName') || defaultState.auth_userTwitchDisplayName,
  auth_userTwitchProfileImage: localStorage.getItem('auth_userTwitchProfileImage') || defaultState.auth_userTwitchProfileImage,
  auth_userSteamCode: localStorage.getItem('auth_userSteamCode') || defaultState.auth_userSteamCode,
  auth_userSteamKey: localStorage.getItem('auth_userSteamKey') || defaultState.auth_userSteamKey
}

// define auth reducer
export function authReducer (
  state = initialState,
  action: TYPES.AuthActionTypes
): TYPES.AuthState {
  switch (action.type) {
    case TYPES.AUTH_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpInProgress: true, httpError: '' }
      }
    }
    case TYPES.AUTH_ACTION_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpActionInProgress: true }
      }
    }
    case TYPES.AUTH_ACTION_IN_PROGRESS_RESET: {
      return {
        ...state,
        ...{ httpActionInProgress: false }
      }
    }
    case TYPES.AUTH_ERROR: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: action.error }
      }
    }
    case TYPES.AUTH_LOGIN: {
      return {
        ...state,
        ...action.payload,
        ...{ httpInProgress: false, httpActionInProgress: false, httpError: '' }
      }
    }
    case TYPES.AUTH_LOGOUT: {
      return {
        ...state,
        ...defaultState
      }
    }
    default:
      return state
  }
}
