/**
 * Defines Twitch State interface
 */
export interface TwitchState {
    // auth url
    twitchAuthUrl: string;
    // http state
    httpInProgress: boolean;
    httpError: string;
    // twitch data
    twitchId: string;
    twitchDisplayName: string;
    twitchProfileImage: string;
}

/**
 * Define Twitch actions names
 */
export const TWITCH_IN_PROGRESS = 'TWITCH_IN_PROGRESS'
export const TWITCH_ERROR = 'TWITCH_ERROR'
export const TWITCH_SUCCESS = 'TWITCH_SUCCESS'
export const TWITCH_GET_DATA = 'TWITCH_GET_DATA'
export const TWITCH_UNLINK = 'TWITCH_UNLINK'

/**
 * Define Twitch actions interfaces
 */
interface TwitchInProgressAction {
    type: typeof TWITCH_IN_PROGRESS;
}
interface TwitchErrorAction {
    type: typeof TWITCH_ERROR;
    error: string;
}
interface TwitchSuccessAction {
    type: typeof TWITCH_SUCCESS;
}
interface TwitchGetDataAction {
    type: typeof TWITCH_GET_DATA;
    payload: any;
}
interface TwitchUnlinkAction {
    type: typeof TWITCH_UNLINK;
}

/**
 * Define all supported actions for twitch
 */
export type TwitchActionTypes = TwitchInProgressAction |
TwitchErrorAction |
TwitchSuccessAction |
TwitchGetDataAction |
TwitchUnlinkAction;

/**
 * Define Twitch Actions Interface
 */
export interface TwitchActionsInterface {
    twitchLink: (code: string, redirectUri: string) => Promise<JSON>;
    twitchGetData: () => Promise<JSON>;
    twitchUnlink: () => Promise<JSON>;
}
