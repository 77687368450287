import React from 'react'

import { Col, Row, Spinner, Alert } from 'react-bootstrap'

import { withRedux } from '../../../Redux/'
import Badge from '../../../Components/Badge'

import * as TYPES from './types'

// classnames for different rank
const item_styles = [
  'gold',
  'silver',
  'bronze'
]

class LeaderboardList extends React.Component<TYPES.LeaderboardListProps, {}> {
  // default leaderboard request
  componentDidMount () {
    const { leaderboardsGet } = this.props
    leaderboardsGet()
  }

  // request leaderboard by type
  shouldComponentUpdate (nextProps: TYPES.LeaderboardListProps) {
    const { leaderboardsGet } = this.props
    if (nextProps.type !== this.props.type) leaderboardsGet(nextProps.type)
    return true
  }

  /**
   * Render
   */
  render () {
    const { httpInProgress, httpError, leaderboards_data, leaderboards_list } = this.props.leaderboards
    const { withBadge } = this.props
    // const { auth_userName } = this.props.auth
    return (
      <div>
        {httpInProgress && !!leaderboards_list.length && <Spinner as="span" animation="grow" role="status" aria-hidden="true" variant="dark" />}
        {httpError && <Alert variant="danger">{httpError}</Alert>}
        {
          !httpInProgress &&
          !httpError &&
          leaderboards_data &&
          leaderboards_data.map(
            (item: any, index: number) => (
              <Row key={'lb_item_' + index}>
                <a className="leaderboards-item-a" href={'/public/profile/' + item.userName} target='_blank' rel="noopener noreferrer">
                  <Col className={
                    'text-bold ' +
                    'px-mx-0 ' +
                    'leaderboards-item ' +
                    (
                      // (auth_userName === item.userName)
                      //   ? 'auth-user '
                      //   : (
                      (index < 3 ? item_styles[index] + ' ' : (index < 10 ? 'first-ten ' : '')) + (index % 2 ? 'odd' : '')
                    // )
                    )
                  }>
                    <div className="leaderboards-item-inside">
                      <Row>
                        <Col xs={3} md={2} className="text-center rank-col pr-0 mr-0">{item.rank}</Col>
                        <Col xs={6} md={7}className="text-left">{item.displayName}</Col>
                        <Col xs={3} className="text-center">{item.value || 0}</Col>
                      </Row>
                    </div>

                  </Col>
                </a>
                {withBadge && (
                  <div className="leaderboards-item-badge-cover">
                    <Badge
                      badgeClass={
                        // (auth_userName === item.userName)
                        //   ? 'auth-user '
                        //   :
                        (index < 3 ? item_styles[index] + ' ' : (index < 10 ? 'first-ten ' : ''))
                      }
                      loading={httpInProgress}
                      badge={item.badge}
                    />
                  </div>
                )}
              </Row>
            )
          )
        }
      </div>
    )
  }
}

export default withRedux(LeaderboardList)
