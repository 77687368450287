import React from 'react'

import { Container, Col, Row, Button } from 'react-bootstrap'

import { PaperContent } from '../../Components/Contents'
import { useWindowDimensions } from '../../Utils'

import Up from '../../Components/Up'
import Separator from '../../Components/Separator'

import { Helmet } from 'react-helmet'

import communityCreatorIcon from '../../assets/community/communityCreatorIconDark.png'
import communityRequirementsIcon from '../../assets/community/communityRequirementsIconDark.png'

const Community: React.FC = () => {
  // check if the render is for Mobile
  const { isMobile } = useWindowDimensions()

  // strings to be rendered for the CREATOR section
  // const rules: Record<string, string> = {
  //   'OFFICIAL LISTING': 'Get your channel listed on the Project Genesis website.',
  //   'EXPOSURE OPPORTUNITIES': 'Your content may be shared on official Project Genesis channels.',
  //   'EXCLUSIVE PREVIEWS': 'See what’s coming to Project Genesis before everybody else.',
  //   'UNIQUE ITEMS': 'Unlock exclusive custom items and show to your community.',
  //   'TALK TO US!': 'Provide input directly to the devs through special channels as Project Genesis develops.'
  // }

  // const renderCreatorDesktop = () => {
  //   const mIndex = Math.ceil(Object.keys(rules).length / 2)
  //   const rArray = []
  //   for (let i = 0; i < mIndex; i++) {
  //     const leftItem = Object.keys(rules)[i]
  //     let rightItem = null

  //     if (i + mIndex < Object.keys(rules).length) rightItem = Object.keys(rules)[i + mIndex]

  //     rArray.push(
  //       <Row key={'row_index' + i} className="pg-community-content">
  //         <Col md={2}/>
  //         <Col md={4}>
  //           <h4><span className="text-bold">{leftItem}</span>
  //             <br/>{rules[leftItem]}</h4>
  //         </Col>
  //         <Col md={4}>
  //           {rightItem && (
  //             <h4><span className="text-bold">{rightItem}</span>
  //               <br/>{rules[rightItem]}</h4>
  //           )}
  //         </Col>
  //         <Col md={2}/>
  //       </Row>
  //     )
  //   }
  //   return rArray
  // }
  // const renderCreatorMobile = () => {
  //   return Object.keys(rules).map((item, index) => {
  //     return (
  //       <Row key={'row_index' + index} className="pg-community-content">
  //         <Col xs={1}/>
  //         <Col xs={10}>
  //           <h4><span className="text-bold">{item}</span>
  //             <br/>{rules[item]}</h4>
  //         </Col>
  //         <Col xs={1}/>
  //       </Row>
  //     )
  //   })
  // }

  /**
   * Render
   */
  return (
    <PaperContent>
      <Helmet>
        <title>Project Genesis | Community</title>
        <meta name="description" content="Project Genesis Community Page" />
      </Helmet>
      <div className="pg-community-header">
        <Container>
          <Row>
            <Col xs={6} className="d-none d-md-block"/>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={8} className="px-5 px-md-2">
                  <h1 className="text-bold">COMMUNITY</h1>
                  <p>Pilots are a key part of our community. Whether you’re interested in creating content, hunting for bugs, displaying your combat expertise, or providing community support, there’s a role for you.</p>
                  <p>Join our Affiliate Partner Program and show your skills to the world!</p>
                </Col>
                <Col xs={1} md={4} className="d-none d-md-block"/>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {!isMobile && (<Separator/>)}
      <Container>
        <Row className="pg-community-label-row">
          <Col>
            <Row>
              <Col/>
              <Col xs={4} md={2} className="px-mx-2 text-center"><img src={communityCreatorIcon} alt="creator icon"/></Col>
              <Col/>
            </Row>
            <Row>
              <Col xs={1} md={2}/>
              <Col className="label-dash d-none d-md-block"/>
              <Col xs={10} md={3}><h2 className="text-bold text-center">BECOME A CREATOR</h2></Col>
              <Col className="label-dash d-none d-md-block"/>
              <Col xs={1} md={2}/>
            </Row>
          </Col>
        </Row>
        <Row className="pg-community-content">
          <Col xs={1}/>
          <Col xs={10}>
            <h3>Creators are on the frontlines of the community, producing dynamic content<br/>that showcases a variety of facets within the game. Creators<br/>express themselves through various mediums that display<br/>their own personalities within the Project Genesis ecosystem</h3>
          </Col>
          <Col xs={1}/>
        </Row>
        <Row className="pg-community-content btn-margin">
          <Col xs={4} md={5}/>
          <Col xs={4} md={2} className="text-center">
            <Button
              className="btn-contact apply-content"
              onClick={() => { window.open('https://form.jotform.com/220456530455149') }}
            >
              APPLY
            </Button>
          </Col>
          <Col xs={4} md={5}/>
        </Row>
        <Row className="pg-community-label-row">
          <Col>
            <Row>
              <Col/>
              <Col xs={4} md={2} className="px-mx-2 text-center"><img src={communityRequirementsIcon} alt="test pilot icon"/></Col>
              <Col/>
            </Row>
            <Row>
              <Col xs={1} md={2}/>
              <Col className="label-dash d-none d-md-block"/>
              <Col xs={10} md={3}><h2 className="text-bold text-center">BECOME A TEST PILOT</h2></Col>
              <Col className="label-dash d-none d-md-block"/>
              <Col xs={1} md={2}/>
            </Row>
          </Col>
        </Row>
        <Row className="pg-community-content">
          <Col xs={1}/>
          <Col xs={10}>
            <h3>Test Pilots enjoy breaking things, are obsessed with quality, and<br/>help to identify and reproduce bugs. With first access to unreleased, private<br/>builds, Test Pilots assist in verifying the game and new features through<br/>testing to ensure a stable enough build-state for wider release.</h3>
          </Col>
          <Col xs={1}/>
        </Row>
        <Row className="pg-community-content btn-margin">
          <Col xs={4} md={5}/>
          <Col xs={4} md={2} className="text-center">
            <Button
              className="btn-contact apply-content"
              onClick={() => { window.open('https://form.jotform.com/213086836745161') }}
            >
              APPLY
            </Button>

          </Col>
          <Col xs={4} md={5}/>
        </Row>
        <Row className="pg-community-label-row">
          <Col>
            <Row>
              <Col/>
              <Col xs={4} md={2} className="px-mx-2 text-center"><img src={communityRequirementsIcon} alt="ace pilot icon"/></Col>
              <Col/>
            </Row>
            <Row>
              <Col xs={1} md={2}/>
              <Col className="label-dash d-none d-md-block"/>
              <Col xs={10} md={3}><h2 className="text-bold text-center">BECOME AN ACE PILOT</h2></Col>
              <Col className="label-dash d-none d-md-block"/>
              <Col xs={1} md={2}/>
            </Row>
          </Col>
        </Row>
        <Row className="pg-community-content">
          <Col xs={1}/>
          <Col xs={10}>
            <h3>Ace Pilots are competitive community members who help refine play balance,<br/>identify problematic exploits, and finetune a visceral experience<br/>that is ‘easy to play, but hard to master.’ With high-level<br/>experience in game titles within the FPS and space combat<br/>genres, Ace Pilots bring the game sense and mechanical skills<br/>developed from their gaming experience to Project Genesis,<br/>optimizing gameplay for all skill ranges.</h3>
          </Col>
          <Col xs={1}/>
        </Row>
        <Row className="pg-community-content btn-margin">
          <Col xs={4} md={5}/>
          <Col xs={4} md={2} className="text-center">
            <Button
              className="btn-contact apply-content"
              onClick={() => { window.open('https://form.jotform.com/220456272244148') }}
            >
              APPLY
            </Button>
          </Col>
          <Col xs={4} md={5}/>
        </Row>
        <Row className="pg-community-label-row">
          <Col>
            <Row>
              <Col/>
              <Col xs={4} md={2} className="px-mx-2 text-center"><img src={communityRequirementsIcon} alt="Ambasador icon"/></Col>
              <Col/>
            </Row>
            <Row>
              <Col xs={1} md={2}/>
              <Col className="label-dash d-none d-md-block"/>
              <Col xs={10} md={3}><h2 className="text-bold text-center">BECOME AN AMBASADOR</h2></Col>
              <Col className="label-dash d-none d-md-block"/>
              <Col xs={1} md={2}/>
            </Row>
          </Col>
        </Row>
        <Row className="pg-community-content">
          <Col xs={1}/>
          <Col xs={10}>
            <h3>Ambassadors function as the recruiting and welcoming committee for the<br/>community, as they build relationships within the community, engage<br/>members incommunity activities and events, and fulfill the role<br/>of moderators within the Discord community.</h3>
          </Col>
          <Col xs={1}/>
        </Row>
        <Row className="pg-community-content btn-margin">
          <Col xs={4} md={5}/>
          <Col xs={4} md={2} className="text-center">
            <Button
              className="btn-contact apply-content"
              onClick={() => { window.open('https://form.jotform.com/220456221833146') }}
            >
              APPLY
            </Button>
          </Col>
          <Col xs={4} md={5}/>
        </Row>
        {/* {isMobile ? renderCreatorMobile() : renderCreatorDesktop()} */}
      </Container>
      <Up/>
    </PaperContent>
  )
}

export default Community
