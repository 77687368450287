import React from 'react'

import { Col, Row, Spinner, Button } from 'react-bootstrap'

import * as TYPES from './types'

import { withRedux } from '../../../Redux'
import { useWindowDimensions } from '../../../Utils'

import SpanCopyToClipboard from '../SpanCopyToClipboard'

import notificationWelcomeIcon from '../../../assets/notifications/IconWelcomeProfileDark.png'
import eyeCloseIcon from '../../../assets/profile/eyeCloseIcon.png'
import eyeShowIcon from '../../../assets/profile/eyeShowIcon.png'

import profileIconSteam from '../../../assets/profile/GetStartedSteamKey/SteamBtnLogo.png'
import linkToSteam from '../../../assets/profile/GetStartedSteamKey/LinkToSteamBtn.png'
import linkedToSteam from '../../../assets/profile/GetStartedSteamKey/LinkedToSteam.png'
import linkedToSteamMobile from '../../../assets/profile/GetStartedSteamKey/LinkedToSteamMobile.png'

const ProfileGetStartedSteamKey: React.FC<TYPES.ProfileGetStartedSteamKeyProps> = (props: TYPES.ProfileGetStartedSteamKeyProps) => {
  // get needed props
  const { auth } = props

  // check if the render is for Mobile
  const { isMobile } = useWindowDimensions()

  // steam Key eye state
  const [steamKeyHidden, setSteamKeyHidden] = React.useState(true)

  return (
    <Row className="profile-block-content">
      <Col className="px-mx-0">
        <Row className="profile-block-content-padding px-0 mx-0">
          <Col xs={1} md={1} className="px-md-0 mx-md-0"/>
          {!auth.httpInProgress && auth.auth_userSteamKey && (
            <Col className="px-0 mx-0">
              <h4 className="text-bold">Here is your Steam Key:</h4>
              <SpanCopyToClipboard loading={auth.httpInProgress} className="key-span cursor-pointer" string={(steamKeyHidden && auth.auth_userSteamKey) ? auth.auth_userSteamKey.split('').map(() => { return '*' }).join(' ').slice(0, -1) : auth.auth_userSteamKey}/>
              {!auth.httpInProgress && auth.auth_userSteamKey && (<img className="key-eye cursor-pointer" src={steamKeyHidden ? eyeShowIcon : eyeCloseIcon} alt="hide eye icon" onClick={() => { setSteamKeyHidden(!steamKeyHidden) }}/>)}
            </Col>
          )}
          {auth.httpInProgress && (
            <Col className="px-md-0 mx-md-0 text-center py-0">
              <Spinner as="span" animation="grow" role="status" aria-hidden="true" variant="dark" />
            </Col>
          )}
          <Col xs={1} md={1} />
        </Row>
        <Row className="profile-block-content-padding px-0 mx-0">
          <Col xs={2} md={1} className="px-md-0 mx-md-0"/>
          <Col xs={8} md={2} className="px-md-0 mx-md-0">
            <img className="w-100" src={notificationWelcomeIcon} alt="notification steam"/>
          </Col>
          <Col xs={2} className="d-block d-md-none"/>
          <Col xs={1} className="d-block d-md-none"/>
          <Col xs={10} md={4}>
            <h2 className={(isMobile ? 'text-center' : 'float-left') + ' profile-block-content-padding text-bold p-top-welcome'}>CONGRATULATIONS, PILOT,<br/>YOU’VE MADE IT!</h2>
          </Col>
          <Col xs={1} md={5}/>
        </Row>
        <Row className="profile-block-content-padding px-0 mx-0">
          <Col xs={1} md={1} className="px-0 mx-0"/>
          <Col xs={10} md={10} className="px-0 mx-0">
            <p className={isMobile ? 'text-center' : 'float-left'}>We are excited to see in-game! Make sure to activate your key within the next 24-hours and keep the game in your Steam library permanently!</p>
          </Col>
          <Col xs={1} md={1} className="px-0 mx-0"/>
        </Row>
        <Row className="profile-block-content-padding px-0 mx-0">
          <Col xs={1} md={1} className="px-0 mx-0"/>
          <Col xs={10} md={10} className="px-0 mx-0">
            <p className={(isMobile ? 'text-center' : 'float-left') + ' text-bold'}>Click below to access our Steam Page and install Project Genesis:</p>
          </Col>
          <Col xs={1} md={1} className="px-0 mx-0"/>
        </Row>
        <Row className="px-0 mx-0">
          <Col xs={1} md={1} className="px-0 mx-0"/>
          <Col xs={10} md={2} className="px-0 mx-0">
            <Button
              className="get-started-steam-btn"
              onClick={() => { window.open('https://store.steampowered.com/app/700240/Project_Genesis/') }}
            >
              <img
                src={profileIconSteam}
                alt="steam whish us button"
                className="w-100"/>
            </Button>
          </Col>
          <Col xs={1} md={9} className="px-0 mx-0"/>
        </Row>
        {auth.auth_userSteamDisplayName && (
          <Row className="profile-block-content-padding px-0 mx-0">
            <Col xs={1} md={1} className="px-0 mx-0"/>
            <Col xs={10} md={3} className="px-0 mx-0">
              <img className="w-100" src={isMobile ? linkedToSteamMobile : linkedToSteam} alt="Linked to Steam Notification"/>
            </Col>
            <Col xs={1} md={7} className="px-0 mx-0"/>
          </Row>
        )}
        {/* {!steam.steamDisplayName && (
          <Row className="profile-block-content-padding px-0 mx-0">
            <Col xs={1} md={1} className="px-0 mx-0"/>
            <Col xs={10} md={10} className="px-0 mx-0">
              <p className={(isMobile ? 'text-center' : 'float-left')}>If you have any <span className="text-bold">Level 2 Badge</span> and have installed Project Genesis <span className="text-bold">before 05/22/2020</span>, link your Project Genesis account to your Steam account to access the Early Access assets package.</p>
            </Col>
            <Col xs={1} md={1} className="px-0 mx-0"/>
          </Row>
        )} */}
        {/* {!steam.steamDisplayName && (
          <Row className="px-0 mx-0">
            <Col xs={1} md={1} className="px-0 mx-0"/>
            <Col xs={10} md={2} className="px-0 mx-0">
              {!profile.httpInProgress && !steam.httpInProgress && (
                <Button
                  className="get-started-link-to-steam-btn"
                  onClick={() => { window.open(steam.steamAuthUrl, '_self') }}
                >
                  <img
                    src={linkToSteam}
                    alt="Link to Steam Notification"
                    className="w-100"/>
                </Button>
              )}
            </Col>
            <Col xs={1} md={9} className="px-0 mx-0"/>
          </Row>
        )} */}
      </Col>
    </Row>
  )
}

export default withRedux(ProfileGetStartedSteamKey)
