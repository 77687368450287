/**
 * Defines Steam State interface
 */
export interface SteamState {
    // auth url
    steamAuthUrl: string;
    // http state
    httpInProgress: boolean;
    httpError: string;
    // twitch data
    steamId: string;
    steamDisplayName: string;
    steamProfileImage: string;
}

/**
 * Define Steam actions names
 */
export const STEAM_IN_PROGRESS = 'STEAM_IN_PROGRESS'
export const STEAM_ERROR = 'STEAM_ERROR'
export const STEAM_SUCCESS = 'STEAM_SUCCESS'
export const STEAM_GET_DATA = 'STEAM_GET_DATA'
export const STEAM_UNLINK = 'STEAM_UNLINK'

/**
 * Define Steam actions interfaces
 */
interface SteamInProgressAction {
    type: typeof STEAM_IN_PROGRESS;
}
interface SteamErrorAction {
    type: typeof STEAM_ERROR;
    error: string;
}
interface SteamSuccessAction {
    type: typeof STEAM_SUCCESS;
}
interface SteamGetDataAction {
    type: typeof STEAM_GET_DATA;
    payload: any;
}
interface SteamUnlinkAction {
    type: typeof STEAM_UNLINK;
}

/**
 * Define all supported actions for steam
 */
export type SteamActionTypes = SteamInProgressAction |
SteamErrorAction |
SteamSuccessAction |
SteamGetDataAction |
SteamUnlinkAction;

/**
 * Define Steam Actions Interface
 */
export interface SteamActionsInterface {
    steamLink: (steamId: string) => Promise<JSON>;
    steamGetData: () => Promise<JSON>;
    steamUnlink: () => Promise<JSON>;
}
