import React from 'react'
import { Container, Col, Row, /* Nav, */ Modal, Button } from 'react-bootstrap'

import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'

import { PaperContent } from '../../Components/Contents'

// import FormLogin from './FormLogin'
// import FormSignUp from './FormSignUp'
// import FormForgotPassword from './FormForgotPassword'
// import FormRenewPassword from './FormRenewPassword'

import { withRedux } from '../../Redux/'
import { withRouter, Redirect } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import bgImage from '../../assets/landing/PGHackerDesktopDark.png'
import iconModalWarning from '../../assets/auth/IconModalWarning.png'
import iconModalWarningClose from '../../assets/auth/IconModalWarningClose.png'
import FormAuth from './FormAuth'

const Auth: React.FC<TYPES.AuthProps> = (props: TYPES.AuthProps) => {
  // get needed props
  const {
    auth,
    location
  } = props

  // if user already authenticated -> move to Profile page
  if (auth.auth_userSteamID.length) {
    return <Redirect to={ROUTES.PROFILE}/>
  }

  // parse url
  // const query = new URLSearchParams(location.search)
  // const openid_sig = query.get('openid.sig')

  // if (openid_sig) {
  //   const steamVerificationParams: any = {}
  //   query.forEach((value, key) => {
  //     steamVerificationParams[key] = decodeURI(value)
  //   })
  //   authLoginWithSteam(steamVerificationParams)
  // }

  // if renew password token -> remove query string from url and open AuthModal with passwrod renew content
  // if (token) {
  //   // authSetPasswordRenewToken(token)
  //   // authSetType(AUTH.AUTH_TYPE_RENEW_PW)
  //   return <Redirect to={ROUTES.AUTH}/>
  // }

  // check if it is a warning login
  // means verification email that need login
  // if (location.pathname === '/auth/warning') {
  //   // authSetType(AUTH.AUTH_TYPE_LOGIN_WITH_WARNING)
  //   return <Redirect to={ROUTES.AUTH}/>
  // }

  // check if it is a time for warning modal
  const isWarning = false // auth.auth_type === AUTH.AUTH_TYPE_LOGIN_WITH_WARNING

  /**
   * Render Email Verification Warning Modal
   */
  const renderWarningModal = () => {
    return (
      <Modal
        animation
        centered
        dialogClassName="auth-warning-modal"
        show={isWarning}
        // onHide={() => authSetType(AUTH.AUTH_TYPE_LOGIN)}
      >
        <Modal.Header>
          <Container>
            <Row className="w-100 m-0 p-0">
              <Col xs={11}/>
              <Col xs={1} className="p-0">
                <Button
                  className="auth-modal-close"
                  // onClick={() => authSetType(AUTH.AUTH_TYPE_LOGIN)}
                >
                  <img src={iconModalWarningClose} alt="auth modal warning close" />
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container className="auth-modal-warning-container">
            <Row>
              <Col className="d-block d-md-none"/>
              <Col xs={5} md={3}>
                <img src={iconModalWarning} className="w-100" alt="auth modal warning"/>
              </Col>
              <Col className="d-block d-md-none"/>
              <Col xs={12} md={9}>
                <h4>Looks like you‘ve registered in a different browser or device. Please login here to finish your email verification.</h4>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    )
  }
  /**
   * Render Auth Tabs
   */
  // const renderTabs = () => {
  //   return Object.values([AUTH.AUTH_TYPE_SIGNUP, AUTH.AUTH_TYPE_LOGIN]).map(
  //     (key) => (
  //       <Nav.Item key={'tab_' + key}>
  //         <Nav.Link eventKey={key} active={key === auth.auth_type} disabled={auth.httpInProgress}> {key} </Nav.Link>
  //       </Nav.Item>
  //     ))
  // }

  /**
   * Render
   */
  return (
    <PaperContent className="auth">
      <Helmet>
        <title>Project Genesis | Login | Register</title>
        <meta name="description" content="Login page featuring a dialogue where you can sign up or login to an account from Project Genesis." />
      </Helmet>
      <Container className="auth-content">
        <Row className="auth-bg-image">
          <Col md={1} className="d-none d-md-block"></Col>
          <Col md={6} className="d-none d-md-block text-center pr-0 mr-0 pg-text">
            <img className="auth-bg-image" src={bgImage} alt="auth bg"/>
          </Col>
          <Col xs={12} md={5} className="pg-text px-mx-0">
            <Row className={/* (auth.auth_type === AUTH.AUTH_TYPE_SIGNUP ? 'down' : 'down') + */'down auth-form-row'}>
              <Col xs={1} md={1}></Col>
              <Col xs={10} md={10} className="px-mx-0">
                <h1 className="text-bold text-center">Welcome!</h1>
                {/* {auth.auth_type !== AUTH.AUTH_TYPE_SIGNUP && (<h1 className="text-bold">Welcome back!</h1>)} */}
                {/* {auth.auth_type === AUTH.AUTH_TYPE_SIGNUP && (<h1 className="text-bold">Sign up for<br/>Project Genesis!</h1>)} */}
              </Col>
              <Col xs={1} md={1}></Col>
            </Row>
            <Row>
              <Col xs={1} md={2}></Col>
              <Col xs={10} md={8} className="auth-form-block px-mx-0">
                {/* <Nav
                  variant="tabs"
                  fill={true}
                  defaultActiveKey={auth.auth_type}
                  onSelect={(selectedKey: string) => authSetType(selectedKey)}
                >
                  {renderTabs()}
                </Nav> */}
                <FormAuth location={location}/>
                {/* {auth.auth_type === AUTH.AUTH_TYPE_LOGIN && (<FormLogin />)}
                {auth.auth_type === AUTH.AUTH_TYPE_LOGIN_WITH_WARNING && (<FormLogin />)}
                {auth.auth_type === AUTH.AUTH_TYPE_SIGNUP && (<FormSignUp />)}
                {auth.auth_type === AUTH.AUTH_TYPE_FORGOT_PW && (<FormForgotPassword/>)}
                {auth.auth_type === AUTH.AUTH_TYPE_RENEW_PW && (<FormRenewPassword/>)} */}
              </Col>
              <Col xs={1} md={2}></Col>
            </Row>
            <Row className="auth-form-under-block pl-2">
              <Col>
                <h4>This version of Project Genesis is an Early Access</h4>
                <h4>Version and may differ from the final product</h4>
                <h4>@2022. 8 Circuit Studios. All rights reserved.</h4>
              </Col>
            </Row>
          </Col>
          <Col md={1} className="d-none d-md-block"></Col>
        </Row>
        {renderWarningModal()}
      </Container>
      <Container>
        <Row>
          <Col xs={1} className="d-none d-md-block"></Col>
          <Col xs={12} md={10} className="text-center pl-auto px-mx-0 pg-text">
            <iframe src='https://www.youtube.com/embed/SLmZgvUCruc'
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen={true}
              title='video'
            />
          </Col>
          <Col xs={1} className="d-none d-md-block"></Col>
        </Row>
      </Container>
    </PaperContent>
  )
}

export default withRedux(withRouter(Auth))
