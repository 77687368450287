import React from 'react'

import { Container, Col, Row, Spinner } from 'react-bootstrap'

import * as TYPES from './types'

import { withRedux } from '../../Redux'
import { useWindowDimensions } from '../../Utils'

import profileIconDiscord from '../../assets/profile/Community/profileIconDiscord.png'
import profileIconDiscordHover from '../../assets/profile/Community/profileIconDiscordHover.png'
import profileIconDiscordActive from '../../assets/profile/Community/profileIconDiscordActive.png'
import profileIconTwitch from '../../assets/profile/Community/profileIconTwitch.png'
import profileIconTwitchHover from '../../assets/profile/Community/profileIconTwitchHover.png'
import profileIconTwitchActive from '../../assets/profile/Community/profileIconTwitchActive.png'
import profileIconSteam from '../../assets/profile/Community/profileIconSteam.png'
import profileIconSteamHover from '../../assets/profile/Community/profileIconSteamHover.png'
import profileIconSteamActive from '../../assets/profile/Community/profileIconSteamActive.png'

const ProfileCommunity: React.FC<TYPES.ProfileCommunityProps> = (props: TYPES.ProfileCommunityProps) => {
  // get needed props
  const { profile } = props
  // get needed profile props
  const { httpInProgress, profile_steamKey, profile_is_ea } = profile
  // check if the render is for Mobile
  const { isMobile } = useWindowDimensions()

  // button state src
  const [profileIconDiscordSrc, setProfileIconDiscordSrc] = React.useState(profileIconDiscord)
  const [profileIconTwitchSrc, setProfileIconTwitchSrc] = React.useState(profileIconTwitch)
  const [profileIconSteamSrc, setProfileIconSteamSrc] = React.useState(profileIconSteam)

  /**
   * Render
   */
  return (
    <Container className="profile-block">
      <Row>
        <Col xs={1}/>
        <Col xs={10} className="pl-0 ml-0 profile-block-title">
          <Row>
            <Col className="text-center text-md-left">
              <h4 className={(isMobile ? 'text-center' : 'float-left') + ' text-bold'}>COMMUNITY CHANNELS</h4>
            </Col>
          </Row>
        </Col>
        <Col xs={1}/>
      </Row>
      {httpInProgress && (
        <Row className="profile-block-content-padding px-mx-0">
          <Col xs={1}/>
          <Col xs={10} className="px-mx-0 profile-block-content">
            <Row className="px-md-0 mx-md-0">
              <Col className="px-md-0 mx-md-0">
                <Col className="px-md-0 mx-md-0 text-center py-0">
                  <Spinner as="span" animation="grow" role="status" aria-hidden="true" variant="dark" />
                </Col>
              </Col>
            </Row>
          </Col>
          <Col xs={1}/>
        </Row>
      )}
      {!httpInProgress && (
        <>
          <Row className="profile-block-content-padding px-0 mx-0">
            <Col xs={1}/>
            <Col xs={10} className="pl-0 ml-0 w-100">
              <Row>
                <Col className="text-center text-md-left">
                  {isMobile && (<p className="text-center">Check out our channels<br/>for more content:</p>)}
                  {!isMobile && (<p> We’d love to hear from you. Check out our channels to join our community of Pilots.</p>)}
                </Col>
              </Row>
            </Col>
            <Col xs={1}/>
          </Row>
          {!isMobile && (
            <Row>
              <Col md={1} className="px-0 mx-0"/>
              {(profile_is_ea || !profile_steamKey) && (
                <Col md={2} className="pl-md-0 pr-md-2 mx-0">
                  <a className="pg-link cursor-pointer" href="https://store.steampowered.com/app/700240/Project_Genesis/" target="_blank" rel="noopener noreferrer">
                    <img
                      src={profileIconSteamSrc}
                      onMouseEnter={() => setProfileIconSteamSrc(profileIconSteamHover)}
                      onMouseOut={() => setProfileIconSteamSrc(profileIconSteam)}
                      onMouseDown={() => setProfileIconSteamSrc(profileIconSteamActive)}
                      onMouseUp={() => setProfileIconSteamSrc(profileIconSteamHover)}
                      alt="steam whish us button"
                      className="w-100"/>
                  </a>
                </Col>
              )}
              <Col md={2} className="pl-md-0 pr-md-2 mx-0">
                <a className="pg-link cursor-pointer" href="https://www.twitch.tv/playprojectgenesis" target="_blank" rel="noopener noreferrer">
                  <img
                    src={profileIconTwitchSrc}
                    onMouseEnter={() => setProfileIconTwitchSrc(profileIconTwitchHover)}
                    onMouseOut={() => setProfileIconTwitchSrc(profileIconTwitch)}
                    onMouseDown={() => setProfileIconTwitchSrc(profileIconTwitchActive)}
                    onMouseUp={() => setProfileIconTwitchSrc(profileIconTwitchHover)}
                    alt="twitch channel button"
                    className="w-100"/>
                </a>
              </Col>
              <Col md={2} className="pl-md-0 pr-md-2 mx-0">
                <a className="pg-link cursor-pointer" href="https://discordapp.com/invite/pMR7ERU" target="_blank" rel="noopener noreferrer">
                  <img
                    src={profileIconDiscordSrc}
                    onMouseEnter={() => setProfileIconDiscordSrc(profileIconDiscordHover)}
                    onMouseOut={() => setProfileIconDiscordSrc(profileIconDiscord)}
                    onMouseDown={() => setProfileIconDiscordSrc(profileIconDiscordActive)}
                    onMouseUp={() => setProfileIconDiscordSrc(profileIconDiscordHover)}
                    alt="discord channel button"
                    className="w-100"/>
                </a>
              </Col>
              <Col md={5}/>
            </Row>
          )}
          {isMobile && (
            <Row>
              {(profile_is_ea || !profile_steamKey) && (
                <>
                  <Col xs={2}/>
                  <Col xs={8}>
                    <a className="pg-link cursor-pointer" href="https://store.steampowered.com/app/700240/Project_Genesis/" target="_blank" rel="noopener noreferrer">
                      <img
                        src={profileIconSteamSrc}
                        onMouseEnter={() => setProfileIconSteamSrc(profileIconSteamHover)}
                        onMouseOut={() => setProfileIconSteamSrc(profileIconSteam)}
                        onMouseDown={() => setProfileIconSteamSrc(profileIconSteamActive)}
                        onMouseUp={() => setProfileIconSteamSrc(profileIconSteamHover)}
                        alt="steam whish us button"
                        className="w-100"/>
                    </a>
                  </Col>
                  <Col xs={2}/>
                </>
              )}
              <Col xs={2}/>
              <Col xs={8}>
                <a className="pg-link cursor-pointer" href="https://www.twitch.tv/playprojectgenesis" target="_blank" rel="noopener noreferrer">
                  <img
                    src={profileIconTwitchSrc}
                    onMouseEnter={() => setProfileIconTwitchSrc(profileIconTwitchHover)}
                    onMouseOut={() => setProfileIconTwitchSrc(profileIconTwitch)}
                    onMouseDown={() => setProfileIconTwitchSrc(profileIconTwitchActive)}
                    onMouseUp={() => setProfileIconTwitchSrc(profileIconTwitchHover)}
                    alt="twitch channel button"
                    className="w-100"/>
                </a>
              </Col>
              <Col xs={2}/>
              <Col xs={2}/>
              <Col xs={8}>
                <a className="pg-link cursor-pointer" href="https://discordapp.com/invite/pMR7ERU" target="_blank" rel="noopener noreferrer">
                  <img
                    src={profileIconDiscordSrc}
                    onMouseEnter={() => setProfileIconDiscordSrc(profileIconDiscordHover)}
                    onMouseOut={() => setProfileIconDiscordSrc(profileIconDiscord)}
                    onMouseDown={() => setProfileIconDiscordSrc(profileIconDiscordActive)}
                    onMouseUp={() => setProfileIconDiscordSrc(profileIconDiscordHover)}
                    alt="discord channel button"
                    className="w-100"/>
                </a>
              </Col>
              <Col xs={2}/>
            </Row>
          )}
        </>
      )}
    </Container>
  )
}
export default withRedux(ProfileCommunity)
