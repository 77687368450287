/**
 * Treelo State Interface
 * */
export interface ContactState {
    /* api status */
    httpInProgress: boolean;
    httpError: string;
    httpSuccess: boolean;
}

/**
 * Actions
 */
export const CONTACT_IN_PROGRESS = 'CONTACT_IN_PROGRESS'
export const CONTACT_ERROR = 'CONTACT_ERROR'
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS'

/**
 * Action Interfaces
 */
interface ContactInProgressAction {
    type: typeof CONTACT_IN_PROGRESS;
}
interface ContactErrorAction {
    type: typeof CONTACT_ERROR;
    error: string;
}
interface ContactSuccessAction {
    type: typeof CONTACT_SUCCESS;
}

/**
 * Export all action interfaces
 */
export type ContactActionTypes = ContactInProgressAction | ContactErrorAction | ContactSuccessAction;

/**
 * Export Actions Interface
 */
export interface ContactActionsInterface {
    contactSendEmail: (email: string, userName: string, message: string) => void;
}
