import React from 'react'

import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'

import { withRedux } from '../../Redux'
import { withRouter } from 'react-router-dom'

import { PaperContent } from '../../Components/Contents'

// import ProfileHeader from './ProfileHeader'
import ProfileGetStarted from './ProfileGetStarted'
import ProfileLinkage from './ProfileLinkage'
import ProfileCommunity from './ProfileCommunity'
// import ProfileStats from '../../Components/ProfileStats'

import Up from '../../Components/Up'

import { Helmet } from 'react-helmet'

class Profile extends React.Component<TYPES.ProfileProps, {}> {
  componentDidMount () {
    // get constants from props
    const {
      authLinkTwitchData,
      history
    } = this.props

    // parse get params
    const query = new URLSearchParams(this.props.location.search)
    const code = query.get('code') || ''

    if (code) { // twitch redirect
      const redirectUrl = window.location.protocol + '//' + window.location.host + window.location.pathname
      history.push(ROUTES.PROFILE)
      authLinkTwitchData(code, redirectUrl)
    }
  }

  render () {
    return (
      <PaperContent className="profile">
        <Helmet>
          <title>Project Genesis | Profile</title>
          <meta name="description" content="Profile page featuring your player data from Project Genesis: Badges, Rank, Credits, XP, Instructions section and Career Stats." />
        </Helmet>
        {/* <ProfileHeader/> */}
        <ProfileGetStarted/>
        <ProfileLinkage/>
        <ProfileCommunity/>
        {/* <ProfileStats stats={profile_stats}/> */}
        <Up/>
      </PaperContent>
    )
  }
}

export default withRedux(withRouter(Profile))
