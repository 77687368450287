import React from 'react'

import { Container, Col, Row } from 'react-bootstrap'

import { PaperContent } from '../../Components/Contents'
import Up from '../../Components/Up'

import { Helmet } from 'react-helmet'

export const UserAgreement: React.FC = () => {
  return (
    <PaperContent className="policies">
      <Helmet>
        <title>Project Genesis | License Agreement</title>
        <meta name="description" content="License Agreement page for Project Genesis" />
      </Helmet>
      <Container>
        <Row>
          <Col className="px-4">
            <h2 className="text-bold">END-USER LICENSE AGREEMENT</h2>
          </Col>
        </Row>
        <Row>
          <Col className="px-4">
            <p className="text-left">Please read this End-User License Agreement (“Agreement”) carefully before clicking the “I Agree” button, downloading or using Sites, Products or Services as mentioned in the Terms.
              <br/>By clicking the “I Agree” button, or using the Application, you are agreeing to be bound by the terms and conditions of this Agreement.
              <br/>If you do not agree to the terms of this Agreement, do not click on the “I Agree” button and do not download or use any 8 Circuit Studios owned Sites, Products or Services.
            </p>

            <h4 className="text-left text-bold">License</h4>
            <p className="text-left">8 Circuit Studios grants you a revocable, non-exclusive, non-transferable, limited license to use the Site solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.</p>

            <h4 className="text-left text-bold">Limitations of Warranties</h4>
            <p className="text-left">You acknowledge and agree that the Sites, Products and Services are provided on an “as is” and “as available” basis, and that your use or reliance on these and any third party content and services accessed thereby is at your sole risk and discretion. 8 Circuit Studios and its affiliates, partners, suppliers, and licensors hereby disclaim any and all representations, warranties, and guaranties regarding the Sites, Products, and Services, whether express, implied or statutory, and including, without limitation, the implied warranties of merchantability and non-infringement. Furthermore, 8 Circuit Studios and its affiliates, partners, suppliers and licensors make no warranty that (I) the Sites, Products or Services will meet your requirements; (II) the Sites, Products or Services or third party content and services will be uninterrupted, accurate, reliable, timely secure or error-free; (III) the quality of any Sites, Products, Services, or information or other material accessed or obtained by you thought the Sites, Products, or Services will be as represented or meet your expectations; or (IV) any errors in the application or third party content and services will be corrected.</p>

            <h4 className="text-left text-bold">Term and Termination</h4>
            <p className="text-left">This Agreement shall remain in effect until terminated by you or 8 Circuit Studios.
              <br/>8 Circuit Studios may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.
              <br/>This Agreement will terminate immediately, without prior notice from 8 Circuit Studios, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by emailing 8 Circuit Studios at yourallies@8circuitstudios.com.
              <br/>This will eliminate access to your personal account and anything contained within your personal account.
            </p>

            <h4 className="text-left text-bold">Severability</h4>
            <p className="text-left">If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>

            <h4 className="text-left text-bold">Infringement Acknowledgement</h4>
            <p className="text-left">You and 8 Circuit Studios acknowledge and agree that, in the event of a third party claim that the Sites, Products, and Services or Your use of the Sites, Products, and Services infringes any third party’s intellectual property rights, You (and not 8 Circuit Studios) will be responsible for the investigation, defense, settlement and discharge of any such claim of intellectual property infringement. You will, however, promptly notify 8 Circuit Studios in writing of such a claim.</p>

            <h4 className="text-left text-bold">Amendments to this Agreement</h4>
            <p className="text-left">8 Circuit Studios reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 7 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

            <h4 className="text-left text-bold">Contact Information</h4>
            <p className="text-left">If you have any questions about this Agreement, please contact us at yourallies@8circuitstudios.com</p>

            <h3 className="text-left text-bold">PRIVACY POLICY</h3>

            <p className="text-left">8 Circuit Studios, Inc. (“8 Circuit Studios”, “we”, “our”) respects the privacy of its online visitors and customers of its products and services and complies with applicable laws for the protection of your privacy, including the European Union General Data Protection Regulation (“GDPR”).
              <br/>This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Sites, Products, and Services.
            </p>

            <h4 className="text-left text-bold">Information Collection And Use</h4>
            <p className="text-left">While using our Sites, Products, and Services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name and email address (“Personal Information”).8 Circuit Studios collects and process Personal Information where it is necessary for the performance of our agreement with you to provide all the pertinent features available to you and the associated content and services.</p>

            <h4 className="text-left text-bold">Log Data</h4>
            <p className="text-left">Like many site operators, we collect information that your browser sends whenever you use or visit our Sites, Products, and Services (“Log Data”).
              <br/>This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Sites, Products, and Services that you visit, the time and date of your visit, the time spent on those pages and other statistics.
              <br/>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive.
              <br/>We use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.
              <br/>In addition, we may use third party services such as Google Analytics or other software that collect, monitor and analyze data from the use of our Sites, Products, and Services.
            </p>

            <h4 className="text-left text-bold">Who Has Access To This Data</h4>
            <p className="text-left">8 Circuit Studios may share your Personal Information with its 3rd party partners to achieve the purpose of executing the features of our Site, Products, and Services. We may also share your data with companies or institutions where it is necessary for compliance with legal obligations that we are subject to. Additionally, we may share your data for the purposes of legitimate and legal interests of 8 Circuit Studios.
              <br/>We will not sell your Personal Information to 3rd parties for any purpose.
            </p>

            <h4 className="text-left text-bold">Communications</h4>
            <p className="text-left">We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information in relation to our Sites, Products, and Services.</p>

            <h4 className="text-left text-bold">Security</h4>
            <p className="text-left">The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

            <h4 className="text-left text-bold">Changes To This Privacy Policy</h4>
            <p className="text-left">This Privacy Policy is effective as of November 5th, 2019 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted.
              <br/>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of our Sites, Products, and Services after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
              <br/>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.
            </p>

            <h3 className="text-left text-bold">MEDIA RELEASE TERMS</h3>

            <p className="text-left"><span className="text-bold">Organization/Program:</span> Project Genesis Playtests with 8 Circuit Studios.
              <br/>We will occasionally use and promote the content created by those playing our Sites, Products, and Services, such as streamers and YouTubers to promote our Sites, Products, and Services.
              <br/>You authorize 8 Circuit Studios to record and edit your likeness, image, voice, interview and performance. You agree that 8 Circuit Studios may use and authorize the use of any part of this documentation for, but not limited to, exhibition, publication, educational, website, and social media purposes. Additionally, you waive any right to royalties or other compensation arising or related to the use of your image or recording. You also understand that this material may be used in diverse settings within an unrestricted geographic area.
              <br/>By accepting this form, you acknowledge that you have completely read and fully understand the above release and agree to be bound thereby.
            </p>

            <h4 className="text-left text-bold">Contact Us</h4>
            <p className="text-left">If you have any questions about this Privacy Policy, please contact us at yourallies@8circuitstudios.com.</p>
          </Col>
        </Row>
      </Container>
      <Up/>
    </PaperContent>
  )
}

export default UserAgreement
