import React from 'react'
import * as ROUTES from '../../Constants/routes'

import { Col, Row } from 'react-bootstrap'

import { useWindowDimensions } from '../../Utils'

import imageProfileButton from '../../assets/auth/thanks/ProfileButton.png'
import imageMobileProfileButton from '../../assets/auth/thanks/MobileProfileButton.png'
import imageDiscordButton from '../../assets/auth/thanks/DiscordButton.png'
import imageSteamButton from '../../assets/auth/thanks/SteamButton.png'
import imageNewsletterButton from '../../assets/auth/thanks/NewsletterButton.png'

const ContainerEmailVerified: React.FC = () => {
  // get screen dimensions hook { isMobile }
  const { isMobile } = useWindowDimensions()

  const openUrl = (url: string, blank: boolean) => {
    const win = window.open(url, blank ? '_blank' : '_self')
    if (win != null) win.focus()
  }

  return (
    <div className="pg-text thanks-content thanks-verified">
      <Row>
        <Col xs={1} className="d-none d-md-block"></Col>
        <Col xs={12} md={11} className="px-mx-0">
          <h1 className="text-bold text-align-center-left">Thank You for&nbsp;
            <br className="d-md-none"/>verifying your email&nbsp;
          </h1>
        </Col>
      </Row>
      <Row>
        <Col xs={1} className="d-none d-md-block"></Col>
        <Col xs={12} md={11} className="px-mx-0 d-none d-md-block">
          <h4 className="text-align-center-left"> Check out the links below for Steam Key access,&nbsp;
            <br className="d-none d-md-block"/>exclusive content and community channels.
          </h4>
        </Col>
      </Row>
      <Row className="thanks-content-icons-row">
        <Col xs={1}></Col>
        <Col xs={10} md={10} className="px-mx-0">
          <img
            src={isMobile ? imageMobileProfileButton : imageProfileButton}
            alt="profile button"
            className="thanks-icon"
            onClick = {() => openUrl(ROUTES.PROFILE, false)}
          />
        </Col>
        <Col xs={1}></Col>
      </Row>
      <Row>
        <Col xs={4} md={1}></Col>
        <Col xs={4} md={true} className="px-0 mx-0"><img
          src={imageDiscordButton}
          alt="discord button"
          className="thanks-icon"
          onClick = {() => openUrl('https://discordapp.com/invite/kTQRsJp', true)}
        /></Col>
        <Col xs={4} className="d-block d-md-none"></Col>
        <Col xs={4} className="d-block d-md-none"></Col>
        <Col xs={4} md={true} className="px-0 mx-0" ><img
          src={imageSteamButton}
          alt="steam button"
          className="thanks-icon"
          onClick = {() => openUrl('https://store.steampowered.com/app/700240/Project_Genesis/', true)}
        /></Col>
        <Col xs={4} className="d-block d-md-none"></Col>
        <Col xs={4} className="d-block d-md-none"></Col>
        <Col xs={4} md={true} className="px-0 mx-0"><img
          src={imageNewsletterButton}
          alt="newsletter button"
          className="thanks-icon"
          onClick = {() => openUrl('https://8circuitstudios.us16.list-manage.com/subscribe/post?u=37a2aaa8e4dfd06ff76381ef1&id=7e60e578e2', true)}
        /></Col>
        <Col xs={4} md={1}></Col>
      </Row>
      <Row>
        <Col xs={1}></Col>
        <Col xs={10} md={11} className="px-mx-0">
          <p className="text-align-center-left px-mx-0">
            This version of Project Genesis is a Pre-Apha Version and may differ from the final product.&nbsp;
            <br className="d-none d-md-block"/>© 2020. 8 Circuit Studios, All rights reserved.
          </p>
        </Col>
        <Col xs={1} className="d-md-none"></Col>
      </Row>
    </div>
  )
}

export default ContainerEmailVerified
