import * as TYPES from './types'

/**
 * Default Auth State
 */
export const defaultState = {
  httpInProgress: false,
  httpError: '',
  account_displayName: '',
  account_email: ''
}
// define initial state object
const initialState: TYPES.AccountState = {
  httpInProgress: defaultState.httpInProgress,
  httpError: defaultState.httpError,
  account_displayName: defaultState.account_displayName,
  account_email: defaultState.account_email
}

// define profile reducer
export function accountReducer (
  state = initialState,
  action: TYPES.AccountActionTypes
): TYPES.AccountState {
  switch (action.type) {
    case TYPES.ACCOUNT_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpInProgress: true, httpError: '' }
      }
    }
    case TYPES.ACCOUNT_ERROR: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: action.error }
      }
    }
    case TYPES.ACCOUNT_UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
        ...{ httpInProgress: false, httpError: '' }
      }
    }
    default:
      return state
  }
}
