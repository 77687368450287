import * as TYPES from './types'
import * as API from '../api'

import { BadgeMaxLevel } from '../../Components/Badge/types'

import { getUserStats } from '../search/actions'
import { steamGetData, steamLink } from '../steam/actions'
import { twitchGetData, twitchLink } from '../twitch/actions'

const generateProfileStorageIsEmailVerified = (json: any) => {
  localStorage.setItem('profile_isEmailVerified', ('isEmailVerified' in json.scriptData) ? json.scriptData.isEmailVerified : 'false')
  return (('isEmailVerified' in json.scriptData) ? json.scriptData.isEmailVerified : false)
}
/**
 * Set Email Verification Token
 */
export function profileSetVerificationToken (token: string) {
  return async (dispatch: any) => {
    localStorage.setItem('profile_verificationToken', token)
    dispatch({ type: TYPES.PROFILE_SET_VERIFICATION_TOKEN, token })
    return null
  }
}
/**
 * SEND Verification Email to user
 */
export function profileSendVerifyEmail () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.PROFILE_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.PROFILE_ERROR, error: ('Error: ' + JSON.stringify(json.error)) || 'wrong request' })
        return null
      }

      dispatch({ type: TYPES.PROFILE_EMPTY_SUCCESS })
      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.PROFILE_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'sendVerificationEmail',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      environment: API.environment
    }

    try {
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
/**
 * Set isEmailVerified parameter from outside
 * parsing json.scriptData.isEmailVerified
 */
export function profileSetIsEmailVerified (json: any) {
  return async (dispatch: any) => {
    dispatch({
      type: TYPES.PROFILE_GET,
      payload: {
        profile_isEmailVerified: generateProfileStorageIsEmailVerified(json)
      }
    })
    return null
  }
}
/**
 * Verify Email Request
 */
export function profileVerifyEmail () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth, profile } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.PROFILE_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.PROFILE_ERROR, error: ('Error: ' + JSON.stringify(json.error)) || 'wrong request' })
        return null
      }

      dispatch(profileSetIsEmailVerified(json))
      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.PROFILE_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'verifyEmail',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      token: profile.profile_verificationToken
    }

    try {
      // clean local storage verification token
      localStorage.setItem('profile_verificationToken', '')
      dispatch({ type: TYPES.PROFILE_SET_VERIFICATION_TOKEN, token: '' })

      // make a request
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
export function profileSelectBadge (badge: string) {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    let badge_level = 1
    if (badge.includes('_locked')) badge = badge.substring(0, badge.length - 7)
    if (badge.includes('_lvl')) {
      badge_level = parseInt(badge.substring(badge.indexOf('_lvl') + 4, badge.length - 1)) || 1
      badge = badge.substring(0, badge.indexOf('_lvl'))
    }
    badge = 'b_' + badge

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'selectBadge',
      badge: badge,
      playerId: auth.auth_userId,
      authToken: auth.auth_token
    }

    try {
      // make a request
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      // if error return empty response
      if (json.error) { return null }

      if (badge.includes('b_')) badge = badge.substring(2)
      if (badge.includes('_lvl')) badge = badge.substring(0, badge.indexOf('_lvl'))
      badge += '_lvl' + badge_level
      dispatch({
        type: TYPES.PROFILE_SELECT_BADGE,
        payload: {
          profile_selectedBadge: badge
        }
      })

      return json
    } catch (error) {
      // if error return empty response
      return null
    }
  }
}
/**
 * get user steam key
 */
function profileGetSteamKey () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'getSteamKey',
      playerId: auth.auth_userId,
      authToken: auth.auth_token
    }

    try {
      // make a request
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      // if error return empty response
      if (json.error) { return null }

      dispatch({
        type: TYPES.PROFILE_GET_STEAM_KEY,
        key: json.scriptData.key
      })

      return json
    } catch (error) {
      // if error return empty response
      return null
    }
  }
}
/**
 * Get user Stats
 */
export function prfoileGetStats () {
  // return async
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    return dispatch(getUserStats(auth.auth_userName)).then((json: any) => {
      return dispatch({
        type: TYPES.PROFILE_GET_STATS,
        payload: {
          profile_stats: (json && 'leaderboards' in json) ? json.leaderboards : [],
          profile_credits: (json && 'score' in json) ? json.score : 0,
          profile_rank: (json && 'playerlevel' in json) ? json.playerlevel : 0
        }
      })
    })
  }
}

/**
 * Get User Profile Data
 */
export function profileGet () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.PROFILE_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.PROFILE_ERROR, error: json.error.DETAILS || 'wrong request' })
        return null
      }

      // filter selected badge data from all badges
      const selected_badge_data = json.scriptData.items
        .filter((item: Record<string, any>) => {
          return item.category === 'badge'
        }).find((item: Record<string, any>) => {
          return item.itemType === json.scriptData.selected_badge
        })

      // generate selected badge name with level value
      let selected_badge = ''
      if (json.scriptData.selected_badge) {
        selected_badge = (json.scriptData.selected_badge.includes('b_') ? json.scriptData.selected_badge.substring(2) : json.scriptData.selected_badge)
        selected_badge += '_lvl'
        selected_badge += (selected_badge_data ? selected_badge_data.level : '1')
      }

      // generate website-like structured badges array
      const badges = json.scriptData.items
      // filter only badges
        .filter((item: Record<string, any>) => {
          return item.category === 'badge'
        })
      // filter 'b_' types
        .filter((item: Record<string, any>) => {
          return item.itemType.substring(0, 2) === 'b_'
        })
      // sort badges by id
        .sort((a: Record<string, any>, b: Record<string, any>) => {
          if (a && a.id && b && b.id) {
            return parseInt(b.id) - parseInt(a.id)
          }
          return 0
        })
      // generate new array with strings only (without 'b_')
        .map((item: Record<string, any>) => {
          return item.itemType.substring(2) + '_lvl' + (item.level || 1)
        })
      // unique array of badges
        .filter((item: string, index: number, array: Array<string>) => {
          return array.indexOf(item) === index
        })

      // get steam key before stats and profile data
      return dispatch(profileGetSteamKey()).then(() => {
        // get stats before profile data
        return dispatch(prfoileGetStats()).then(() => {
          // get profile data
          dispatch({
            type: TYPES.PROFILE_GET,
            payload: {
              profile_xp: (json.scriptData && 'xp' in json.scriptData) ? json.scriptData.xp : 0,
              profile_isEmailVerified: generateProfileStorageIsEmailVerified(json),
              profile_avatar: 'hacker',
              profile_selectedBadge: selected_badge,
              // take badges array and add/sort locked unlocked badges
              profile_badges: badges.concat(
                Object.keys(BadgeMaxLevel).map((item: string) => {
                  let newItem = ''
                  badges.forEach((badge: string) => {
                    if (badge.match(new RegExp(['^.*', item, '.*$'].join('')))) {
                      const lvl = parseInt(badge.slice(-1))
                      if (lvl < BadgeMaxLevel[item]) newItem = badge.replace(/.$/, String(lvl + 1)) + '_locked'
                      else newItem = 'deleted'
                    }
                  })

                  if (newItem === '') newItem = item + '_lvl1_locked'

                  return newItem
                }).filter((item: string) => {
                  if (item === 'deleted') return false
                  else return true
                })
              ),
              // check if there is a founder badge -> ea user or not
              profile_is_ea: json.scriptData.items
                .filter((item: Record<string, any>) => {
                  return item.category === 'badge'
                }).find((item: Record<string, any>) => {
                  return item.itemType === 'b_founder'
                }) as boolean
            }
          })

          return json
        })
      })
    }

    function onError (json: any) {
      dispatch({ type: TYPES.PROFILE_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.AccountDetailsRequest',
      playerId: auth.auth_userId,
      authToken: auth.auth_token
    }
    try {
      const json = await API.httpRequest(API.URL.PROFILE_PLAYER, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}

/**
 * Get User Profile Data with social accounts check
 */
export function profileGetWithSocials () {
  return async (dispatch: any) => {
    // define request state as in progress
    dispatch({ type: TYPES.PROFILE_IN_PROGRESS })
    // check steam data
    return dispatch(steamGetData()).then(() =>
      // check twitch data
      dispatch(twitchGetData()).then(() =>
        // get profile data
        dispatch(profileGet())
      )
    )
  }
}

/**
 * Get User Profile Data with social account check and steam link
 */
export function profileGetWithSocialsAndSteamLink (steamId: string) {
  return async (dispatch: any) => {
    // define request state as in progress
    dispatch({ type: TYPES.PROFILE_IN_PROGRESS })
    // link steam
    return dispatch(steamLink(steamId)).then(() =>
      // check twitch data
      dispatch(twitchGetData()).then(() =>
        // get profile data
        dispatch(profileGet())
      )
    )
  }
}

/**
 * Get User Profile Data with social account check and steam link
 */
export function profileGetWithSocialsAndTwitchLink (code: string, redirectUri: string) {
  return async (dispatch: any) => {
    // define request state as in progress
    dispatch({ type: TYPES.PROFILE_IN_PROGRESS })
    // link twitch
    return dispatch(twitchLink(code, redirectUri)).then(() =>
      // check steam data
      dispatch(steamGetData()).then(() =>
        // get profile data
        dispatch(profileGet())
      )
    )
  }
}
