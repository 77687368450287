import React from 'react'

import { Container, Col, Row } from 'react-bootstrap'

import { PaperContent } from '../../Components/Contents'
import Up from '../../Components/Up'

import { Helmet } from 'react-helmet'

export const PrivacyPolicy: React.FC = () => {
  return (
    <PaperContent className="policies">
      <Helmet>
        <title>Project Genesis | Privacy Policy</title>
        <meta name="description" content="Privacy Policy page for Project Genesis" />
      </Helmet>
      <Container>
        <Row>
          <Col className="px-4">
            <h2 className="text-bold">PRIVACY POLICY</h2>
          </Col>
        </Row>
        <Row>
          <Col className="px-4">
            <p className="text-left">8 Circuit Studios, Inc. (“8 Circuit Studios”, “we”, “our”) respects the privacy of its online visitors and customers of its products and services and complies with applicable laws for the protection of your privacy, including the European Union General Data Protection Regulation (“GDPR”).This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Sites, Products, and Services.</p>

            <h4 className="text-left text-bold">Information Collection And Use</h4>

            <p className="text-left">While using our Sites, Products, and Services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name and email address (“Personal Information”).8 Circuit Studios collects and process Personal Information where it is necessary for the performance of our agreement with you to provide all the pertinent features available to you and the associated content and services.</p>

            <h4 className="text-left text-bold">Log Data</h4>

            <p className="text-left">Like many site operators, we collect information that your browser sends whenever you use or visit our Sites, Products, and Services (“Log Data”).
              <br/>This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Sites, Products, and Services that you visit, the time and date of your visit, the time spent on those pages and other statistics.
              <br/>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive.
              <br/>We use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.
              <br/>In addition, we may use third party services such as Google Analytics or other software that collect, monitor and analyze data from the use of our Sites, Products, and Services.
            </p>

            <h4 className="text-left text-bold">Who Has Access To This Data</h4>

            <p className="text-left">8 Circuit Studios may share your Personal Information with its 3rd party partners to achieve the purpose of executing the features of our Site, Products, and Services. We may also share your data with companies or institutions where it is necessary for compliance with legal obligations that we are subject to. Additionally, we may share your data for the purposes of legitimate and legal interests of 8 Circuit Studios.
              <br/>We will not sell your Personal Information to 3rd parties for any purpose.
            </p>

            <h4 className="text-left text-bold">Communications</h4>

            <p className="text-left">We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information in relation to our Sites, Products, and Services.</p>

            <h4 className="text-left text-bold">Security</h4>

            <p className="text-left">The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

            <h4 className="text-left text-bold">Changes To This Privacy Policy</h4>

            <p className="text-left">This Privacy Policy is effective as of November 5th, 2019 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted.
              <br/>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of our Sites, Products, and Services after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
              <br/>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.
            </p>

            <h4 className="text-left text-bold">Contact Us</h4>

            <p className="text-left">If you have any questions about this Privacy Policy, please contact us at yourallies@8circuitstudios.com.</p>
          </Col>
        </Row>
      </Container>
      <Up/>
    </PaperContent>
  )
}

export default PrivacyPolicy
