import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import * as ROUTES from '../../Constants/routes'

import Separator from '../Separator'

import discordIcon from '../../assets/footer/social_discord.svg'
// import mediumIcon from '../../assets/footer/social_medium.svg'
import youtubeIcon from '../../assets/footer/social_youtube.svg'
import fbIcon from '../../assets/footer/social_facebook.svg'
import twitterIcon from '../../assets/footer/social_twitter.svg'
import redditIcon from '../../assets/footer/social_reddit.png'

const Footer: React.FC = () => {
  return (
    <footer>
      <Separator type={'blick'}/>
      <Container className="justify-content-center">
        <Row className="justify-content-center mx-0 px-0">
          <Col xs={1} md={3} className="mx-0 px-0"></Col>
          <Col xs={10} md={6} className="mx-0 px-0">
            <Row className="text-center mx-0 px-0">
              <Col md={1} className="d-none d-md-block mx-0 px-0"></Col>
              <Col xs={4} md={2} className="mx-0 px-0"><a href="https://discord.gg/kTQRsJp" rel="noopener noreferrer" target="_blank"><img alt="Discord social icon" src={discordIcon}/></a></Col>
              <Col xs={4} md={2} className="mx-0 px-0"><a href="https://www.reddit.com/r/8circuitstudios/" rel="noopener noreferrer" target="_blank"><img alt="Reddit social icon" src={redditIcon}/></a></Col>
              <Col xs={4} md={2} className="mx-0 px-0"><a href="https://www.youtube.com/channel/UCNPgn6Z54YGXExvSWUeDDHg" rel="noopener noreferrer" target="_blank"><img alt="Youtube social icon" src={youtubeIcon}/></a></Col>
              <Col xs={2} className="d-md-none mx-0 px-0"></Col>
              <Col xs={4} md={2} className="mx-0 px-0"><a href="https://www.facebook.com/8circuitstudios/" rel="noopener noreferrer" target="_blank"><img alt="Facebook social icon" src={fbIcon}/></a></Col>
              <Col xs={4} md={2} className="mx-0 px-0"><a href="https://twitter.com/8circuitstudios" rel="noopener noreferrer" target="_blank"><img alt="Twitter social icon" src={twitterIcon}/></a></Col>
              <Col xs={2} md={1} className="mx-0 px-0"></Col>
            </Row>
          </Col>
          <Col xs={1} md={3} className="mx-0 px-0"></Col>
        </Row>
        <Row>
          <Col xs={2} md={1}></Col>
          <Col xs={8} md={10}>
            <Row className="text-center">
              <h4 className="w-100">
              &copy; 2024 - 8 Circuit Studios.&nbsp;
                <br className="d-md-none"/>All Rights Reserved.
              </h4>
            </Row>
          </Col>
          <Col xs={2} md={1}></Col>
        </Row>
        <Row>
          <Col xs={1} ></Col>
          <Col xs={10}>
            <Row className="text-center">
              <h4 className="w-100">
                <a href={ROUTES.TERMS_OF_SERVICE} className="a-inline-colored">Terms of Service</a> and <a href={ROUTES.PRIVACY_POLICY} className="a-inline-colored">Policy</a>
              </h4>
            </Row>
          </Col>
          <Col xs={1}></Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
