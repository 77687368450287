/**
 * Defines Account State interface
 */
export interface AccountState {
    // http state
    httpInProgress: boolean;
    httpError: string;
    // modal state
    account_displayName: string;
    account_email: string;
  }

/**
 * Defines Account actions names
 */
export const ACCOUNT_IN_PROGRESS = 'ACCOUNT_IN_PROGRESS'
export const ACCOUNT_ERROR = 'ACCOUNT_ERROR'
export const ACCOUNT_UPDATE_STATE = 'ACCOUNT_UPDATE_STATE'

/**
 * Defines Account actions interfaces
 */
interface AccountInProgressAction {
    type: typeof ACCOUNT_IN_PROGRESS;
}
interface AccountErrorAction {
    type: typeof ACCOUNT_ERROR;
    error: string;
}
interface AccountUpdateStateAction {
    type: typeof ACCOUNT_UPDATE_STATE;
    payload: any;
}

/**
 * Defines all suported actions for Auth
 */
export type AccountActionTypes = AccountInProgressAction | AccountErrorAction | AccountUpdateStateAction;

/**
 * Defines AccountActionsInterface
 */
export interface AccountActionsInterface {
    accountGet: () => Promise<JSON>;
    accountSetNewDisplayName: (displayName: string) => Promise<JSON>;
    accountSetNewPassword: (password: string) => Promise<JSON>;
    accountDelete: () => Promise<JSON>;
  }
