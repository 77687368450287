import React from 'react'

import { Container, Col, Row, Spinner } from 'react-bootstrap'

import * as TYPES from './types'

import { withRedux } from '../../Redux'
import { useWindowDimensions } from '../../Utils'

import linkingIconTwitch from '../../assets/profile/Linking/linkingIconTwitch.png'
import linkingIconSteam from '../../assets/profile/Linking/linkingIconSteam.png'
import profileBtnLink from '../../assets/profile/Linking/profileBtnLink.png'
// import profileBtnUnlink from '../../assets/profile/Linking/profileBtnUnlink.png'

const ProfileLinkage: React.FC<TYPES.ProfileLinkageProps> = (props: TYPES.ProfileLinkageProps) => {
  // get needed props
  const { auth } = props

  // check if the render is for Mobile
  const { isMobile } = useWindowDimensions()
  /**
   * Render
   */
  return (
    <Container className="profile-block">
      <Row>
        <Col xs={1}/>
        <Col xs={10} className="pl-0 ml-0 profile-block-title">
          <Row>
            <Col className="text-center">
              <h4 className={(isMobile ? 'text-center' : 'float-left') + ' text-bold'}>LINKED ACCOUNTS</h4>
            </Col>
          </Row>
        </Col>
        <Col xs={1}/>
      </Row>
      <Row>
        <Col xs={1}/>
        <Col xs={10} className="px-mx-0 profile-block-content-padding">
          <Row className="px-md-0 mx-md-0">
            <Col xs={4} className="d-block d-md-none px-mx-0"/>
            <Col xs={4} md={1} className="pl-md-0 ml-md-0">
              <img src={linkingIconTwitch} alt="twitch linked icon" className="w-100"/>
            </Col>
            <Col xs={4} className="d-block d-md-none px-mx-0"/>
            <Col xs={1} className="d-block d-md-none px-mx-0"/>
            <Col xs={10} md={2} className="account-detailes-col pl-0 pr-0 pl-md-2 pr-md-2">
              <h4 className="text-bold">{auth.auth_userTwitchDisplayName.length ? auth.auth_userTwitchDisplayName : 'TWITCH'}</h4>
            </Col>
            <Col xs={1} className="d-block d-md-none px-mx-0"/>
            <Col xs={3} className="d-block d-md-none px-mx-0"/>
            <Col xs={6} md={2} className="account-detailes-col text-center pl-0 pr-0 pl-md-2 pr-md-2">
              {(auth.httpActionInProgress) && (
                <Spinner as="span" animation="border" role="status" aria-hidden="true" variant="dark" />
              )}
              {!auth.httpActionInProgress && !auth.auth_userTwitchDisplayName.length && (
                <a className="pg-link cursor-pointer" href={auth.twitchAuthUrl}>
                  <img
                    src={profileBtnLink}
                    alt="link twitch button"
                    className="w-100"
                  />
                </a>
              )}
              {/* {auth.httpActionInProgress && auth.auth_userTwitchDisplayName.length && (
                <button
                  className="pg-link"
                  onClick={() => { twitchUnlink(); window.location.reload() }}>
                  <img
                    src={profileBtnUnlink}
                    alt="unlink twitch button"
                    className="w-100"
                  />
                </button>
              )} */}
            </Col>
            <Col xs={3} className="d-block d-md-none px-mx-0"/>
            {/* {twitch.httpError && (
              <>
                <Col xs={1} className="d-block d-md-none"/>
                <Col xs={10} md={4} className="account-detailes-col text-center">
                  <p className="linkage-error">{twitch.httpError}</p>
                </Col>
                <Col xs={1} className="d-block d-md-none"/>
              </>
            )} */}
          </Row>
        </Col>
        <Col xs={1}/>
      </Row>
      <Row>
        <Col xs={1}/>
        <Col xs={10} className="px-mx-0 profile-block-content-padding">
          <Row className="px-md-0 mx-md-0">
            <Col xs={4} className="d-block d-md-none px-mx-0"/>
            <Col xs={4} md={1} className="pl-md-0 ml-md-0">
              <img src={linkingIconSteam} alt="steam linked icon" className="w-100"/>
            </Col>
            <Col xs={4} className="d-block d-md-none px-mx-0"/>
            <Col xs={1} className="d-block d-md-none px-mx-0"/>
            <Col xs={10} md={2} className="account-detailes-col pl-0 pr-0 pl-md-2 pr-md-2">
              <h4 className="text-bold">{auth.auth_userSteamDisplayName}</h4>
            </Col>
            <Col xs={1} className="d-block d-md-none px-mx-0"/>
            <Col xs={3} className="d-block d-md-none px-mx-0"/>
            <Col xs={6} md={2} className="account-detailes-col text-center pl-0 pr-0 pl-md-2 pr-md-2">
              {/* {auth.httpActionInProgress && (
                <Spinner as="span" animation="border" role="status" aria-hidden="true" variant="dark" />
              )} */}
              {/* {!profile.httpInProgress && !steam.httpInProgress && !steamDisplayName && (
                <a className="pg-link cursor-pointer" href={steam.steamAuthUrl}>
                  <img
                    src={profileBtnLink}
                    alt="link steam button"
                    className="w-100"
                  />
                </a>
              )} */}
              {/* {!profile.httpInProgress && !steam.httpInProgress && steamDisplayName && (
                <button
                  className="pg-link"
                  onClick={() => { steamUnlink(); window.location.reload() }}>
                  <img
                    src={profileBtnUnlink}
                    alt="unlink steam button"
                    className="w-100"
                  />
                </button>
              )} */}
            </Col>
            <Col xs={3} className="d-block d-md-none px-mx-0"/>
            {/* {steam.httpError && (
              <>
                <Col xs={1} className="d-block d-md-none"/>
                <Col xs={10} md={4} className="account-detailes-col text-center"><p className="linkage-error">{steam.httpError}</p></Col>
                <Col xs={1} className="d-block d-md-none"/>
              </>
            )} */}
          </Row>
        </Col>
        <Col xs={1}/>
      </Row>
    </Container>
  )
}
export default withRedux(ProfileLinkage)
