export const LANDING = '/'
export const AUTH = '/auth'
export const SCHEDULE = '/schedule'
export const COMMUNITY = '/community'
export const SEARCH = '/search'
export const PUBLIC_PROFILE = '/public/profile/:userName'
export const TEST_PILOT_PROGRAM = '/testpilotprogram'
export const LEADERBOARDS = '/leaderboards'
export const LEADERBOARDS_WITHOUT_BG = '/leaderboardswithoutbg'
export const CAREERS = '/careers'
export const PROFILE = '/profile'
export const ACCOUNT = '/account'
export const THANKS = '/thanks'
export const CONTACT_US = '/contact'
export const TERMS_OF_SERVICE = '/termsofservice'
export const USER_AGREEMENT = '/useragreement'
export const PRIVACY_POLICY = '/privacypolicy'
