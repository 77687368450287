import * as TYPES from './types'
import * as API from '../api'
import * as TRELLO from '../../Constants/trello'

/**
 * Get List Cards
 * @param listID {string} -> Trello List ID
 */
function trelloGetListCards (listID: string) {
  return async (dispatch: any) => {
    function onSuccess (json: any) {
      dispatch({
        type: TYPES.TRELLO_SET_CARDS,
        listID,
        trello_cards: json.filter((el: any) => {
          return !el.closed
        }).map((el: any) => {
          return el.name
        })
      })
    }

    function onError (json: any) {
      dispatch({ type: TYPES.TRELLO_ERROR, error: 'Trello ERROR! ' + JSON.stringify(json) })
    }

    try {
      const json = await API.httpRequest(API.URL.TRELLO_GET_CARDS + listID + '/cards', {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
      })

      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
/**
 * Get Board Lists
 */
export function trelloGetBoard () {
  return async (dispatch: any, getState: any) => {
    // define request state as in progress
    dispatch({ type: TYPES.TRELLO_IN_PROGRESS })

    function onSuccess (json: any) {
      if (!json.lists || !json.lists.length) {
        dispatch({ type: TYPES.TRELLO_ERROR, error: 'Trello Board empty or Wrong JSON DATA' })
      }

      dispatch({
        type: TYPES.TRELLO_SET_LISTS,
        trello_lists: json.lists.filter((el: any) => {
          return !el.closed
        }).map((el: any) => {
          return { id: el.id, name: el.name }
        })
      })

      const { trello_lists } = getState().trello

      trello_lists.forEach((el: any) => {
        dispatch(trelloGetListCards(el.id))
      })
    }

    function onError (json: any) {
      dispatch({ type: TYPES.TRELLO_ERROR, error: 'Trello ERROR! ' + JSON.stringify(json) })
    }

    try {
      const json = await API.httpRequest(API.URL.TRELLO_GET_BOARD + TRELLO.TRELLO_BOARD_ID + '?fields=name&lists=all', {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
      })

      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
