import React from 'react'

import { Container, Col, Row } from 'react-bootstrap'

import { PaperContent, MountiansContent } from '../../Components/Contents'

import Trello from './Trello'
import Up from '../../Components/Up'

import { Helmet } from 'react-helmet'

const Schedule: React.FC = () => {
  return (
    <PaperContent className="schedule">
      <Helmet>
        <title>Project Genesis | Schedule</title>
        <meta name="description" content="Schedule page featuring access to our Trello development board for Project Genesis." />
      </Helmet>
      <MountiansContent className="promo-schedule">
        <Container>
          <Row>
            <Col xs={12} className="text-align-center-left pg-text w-100">
              <h1 className="text-bold">SCHEDULE</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="text-align-center-left pg-text w-100">
              <h3 className="text-bold">
                Check to see&nbsp;
                <br className="d-md-none"/>when the next&nbsp;
                <br />playtest is scheduled.
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={1} className="d-md-none"></Col>
            <Col xs={10} md={9} className="float-left pg-text">
              <h4>Interested in participating in the Project Genesis Pilot Program? Join in our private playtests! Get recognized for your contribution as a Test Pilot, content creator, competitive playtester, or community Ambassador. These playtests are a great way for community members to earn recognition as they go above and beyond the call of duty.</h4>
              <h4>Find a time on the schedule below to determine if you are available to participate.</h4>
            </Col>
            <Col xs={1} className="d-md-none"></Col>
          </Row>
          <Row>
            <Col xs={12} className="text-align-center-left pg-text">
              <h2>
                  PROJECT GENESIS&nbsp;
                <br className="d-md-none"/>PLAYTEST SCHEDULE
              </h2>
            </Col>
          </Row>
        </Container>
      </MountiansContent>
      <Trello/>
      <Up/>
    </PaperContent>
  )
}

export default Schedule
