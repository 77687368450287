import React from 'react'

import { Spinner } from 'react-bootstrap'

import * as TYPES from './types'

import imageBadgeLock from '../../assets/profile/badgeLock.png'

const Badge: React.FC<TYPES.BadgeProps> = (props: TYPES.BadgeProps) => {
  const { loading, badge, locked, onClick, badgeClass } = props

  return (
    <div className={'badge ' + badgeClass}>
      <div className="badge-content">
        {loading && <Spinner className="badge-spinner" as="span" animation="grow" role="status" aria-hidden="true" variant="dark" />}
        {!loading && (
          <img
            className="badge-icon"
            src={ TYPES.BadgeMap[(badge && badge in TYPES.BadgeMap) ? badge : 'default_lvl1'] }
            alt={badge || 'default'}
            onClick = {() => (onClick && !locked) ? onClick() : () => { return null }}/>
        )}
        {locked && (<div className="badge-locked-div"><img src={imageBadgeLock} alt={badge + 'locked'}/></div>)}
      </div>
    </div>
  )
}

export default Badge
