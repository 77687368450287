import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import upBtn from '../../assets/upBtn.png'

const Up: React.FC = () => {
  return (
    <Container className="justify-content-center up text-center">
      <Row className="w-100 px-mx-0">
        <Col></Col>
        <Col xs={2} className="text-center">
          <a className="pg-text" href="#header">
            <img className="mx-auto d-block d-md-none circle" alt="icon UP button" src={upBtn}/>
            <img className="mx-auto d-none d-md-block block" alt="icon Back button" src={upBtn}/>
            <p className="text-bold">UP</p>
          </a>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  )
}

export default Up
