import * as TYPES from './types'

// define initial state object
const initialState: TYPES.TrelloState = {
  httpInProgress: false,
  httpError: '',
  trello_lists: [],
  trello_cards: {}
}

// define profile reducer
export function trelloReducer (
  state = initialState,
  action: TYPES.TrelloActionTypes
): TYPES.TrelloState {
  switch (action.type) {
    case TYPES.TRELLO_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpInProgress: true, httpError: '' }
      }
    }
    case TYPES.TRELLO_ERROR: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: action.error }
      }
    }
    case TYPES.TRELLO_SET_LISTS: {
      return {
        ...state,
        ...{ trello_lists: action.trello_lists },
        ...{ httpError: '' }
      }
    }
    case TYPES.TRELLO_SET_CARDS: {
      state.trello_cards[action.listID] = action.trello_cards
      return {
        ...state,
        ...{ httpError: '' }
      }
    }
    default:
      return state
  }
}
