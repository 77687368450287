import * as TYPES from './types'
import * as API from '../api'

/**
 * link steam account
 * @param {string} steamId - OAuth steam User ID redirect
 */
export function steamLink (steamId: string) {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.STEAM_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.STEAM_ERROR, error: json.error.ERROR || 'wrong request' })
        return null
      }

      dispatch({
        type: TYPES.STEAM_GET_DATA,
        payload: {
          steamId: json.scriptData.data.steamId,
          steamDisplayName: json.scriptData.data.steamDisplayName,
          steamProfileImage: json.scriptData.data.steamProfileImage
        }
      })

      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.STEAM_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'steam',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      scriptData: {
        action: 'link',
        steamId64Bit: steamId
      }
    }

    try {
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
/**
 * get steam (user) data
 */
export function steamGetData () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.STEAM_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        // dispatch({ type: TYPES.STEAM_ERROR, error: json.error.ERROR || 'wrong request' })
        dispatch({ type: TYPES.STEAM_SUCCESS })
        return null
      }

      dispatch({
        type: TYPES.STEAM_GET_DATA,
        payload: {
          steamId: json.scriptData.data.steamId,
          steamDisplayName: json.scriptData.data.steamDisplayName,
          steamProfileImage: json.scriptData.data.steamProfileImage
        }
      })

      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.STEAM_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'steam',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      scriptData: {
        action: 'getData'
      }
    }

    try {
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
/**
 * unlink steam account
 */
export function steamUnlink () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.STEAM_IN_PROGRESS })

    function onSuccess (json: any) {
      dispatch({ type: TYPES.STEAM_UNLINK })

      return json.error ? null : json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.STEAM_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'steam',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      scriptData: {
        action: 'unlink'
      }
    }

    try {
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
