import * as TYPES from './types'
import * as API from '../api'

import { authLogout } from '../auth/actions'
/**
 * Get User Account Detailes
 */
export function accountGet () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.ACCOUNT_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.ACCOUNT_ERROR, error: ('Error: ' + JSON.stringify(json.error)) || 'wrong request' })
        return null
      }

      dispatch({
        type: TYPES.ACCOUNT_UPDATE_STATE,
        payload: {
          account_displayName: json.scriptData.displayName,
          account_email: json.scriptData.email
        }
      })
      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.ACCOUNT_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'getUserAccountDetails',
      playerId: auth.auth_userId,
      authToken: auth.auth_token
    }

    try {
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}

export function accountSetNewDisplayName (displayName: string) {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.ACCOUNT_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.ACCOUNT_ERROR, error: JSON.stringify(json.error) })
        return { error: JSON.stringify(json.error) }
      }

      dispatch({
        type: TYPES.ACCOUNT_UPDATE_STATE,
        payload: {
          account_displayName: displayName
        }
      })
      // dispatch(updateDisplayName(displayName))
      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.ACCOUNT_ERROR, error: JSON.stringify(json) })
      return { error: JSON.stringify(json) }
    }

    const body = {
      '@class': '.ChangeUserDetailsRequest',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      displayName: displayName,
      scriptData: {
        environment: API.environment
      }
    }

    try {
      const json = await API.httpRequest(API.URL.ACCOUNT_CHANGE_DETAILS, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}

export function accountSetNewPassword (password: string) {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.ACCOUNT_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.ACCOUNT_ERROR, error: ('Error: ' + JSON.stringify(json.error)) || 'wrong request' })
        return null
      }
      dispatch(authLogout())
      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.ACCOUNT_ERROR, error: JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.ChangeUserDetailsRequest',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      newPassword: password
    }

    try {
      const json = await API.httpRequest(API.URL.ACCOUNT_CHANGE_DETAILS, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}

export function accountDelete () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.ACCOUNT_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.ACCOUNT_ERROR, error: ('Error: ' + JSON.stringify(json.error)) || 'wrong request' })
        return null
      }
      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.ACCOUNT_ERROR, error: JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'deleteAccount',
      playerId: auth.auth_userId,
      authToken: auth.auth_token
    }

    try {
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
