import React from 'react'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'

import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'

import logo from '../../assets/header/logoMenuHercules.png'

import { withRedux } from '../../Redux'
import { withRouter } from 'react-router-dom'

import Separator from '../Separator'

const Header: React.FC<TYPES.HeaderProps> = (props: TYPES.HeaderProps) => {
  // get needed props
  const {
    auth,
    authLogout,
    history
  } = props

  // const { auth_isLoggedIn, auth_displayName } = auth
  const { auth_userSteamDisplayName } = auth

  /**
   * Logout function
   */
  const logout = () => {
    authLogout()
    history.push(ROUTES.LANDING)
  }

  // /**
  //  * Delete Account function
  //  */
  // const deleteAccount = () => {
  //   if (window.confirm('Are you sure you want to delete account?')) {
  //     accountDelete()
  //     logout()
  //   } else {
  //     // Do nothing!
  //     console.log('Thing was not saved to the database.')
  //   }
  // }

  /**
   * Render
   */
  return (
    <header >
      <Container>
        <Navbar collapseOnSelect expand="md" variant="dark">
          <Navbar.Brand href="/"><img alt="Project Genesis menu logo" src={logo}/></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown title="HOME" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/#trailer">Community Update</NavDropdown.Item>
                {/* <NavDropdown.Item href="/#press">In The Press</NavDropdown.Item> */}
                {/* update for Enceladus <NavDropdown.Item href="/#screenshots">Screenshots</NavDropdown.Item> */}
              </NavDropdown>
              {/* <Nav.Link href={ROUTES.SCHEDULE}>SCHEDULE</Nav.Link> */}
              <NavDropdown title="COMMUNITY" id="collasible-nav-dropdown">
                {/* <NavDropdown.Item href={ROUTES.TEST_PILOT_PROGRAM}>Pilot Program</NavDropdown.Item> */}
                <NavDropdown.Item href={ROUTES.COMMUNITY}>Community Page</NavDropdown.Item>
                <NavDropdown.Item href={ROUTES.SEARCH}>Player Search</NavDropdown.Item>
                <NavDropdown.Item href="https://discord.gg/kTQRsJp" target="_blank">Discord</NavDropdown.Item>
                <NavDropdown.Item href="https://www.youtube.com/channel/UCNPgn6Z54YGXExvSWUeDDHg" target="_blank">YouTube</NavDropdown.Item>
                <NavDropdown.Item href="https://store.steampowered.com/app/700240/Project_Genesis/" target="_blank">Steam</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href={ROUTES.LEADERBOARDS}>LEADERBOARDS</Nav.Link>
              {/* <Nav.Link href={ROUTES.CAREERS}>CAREERS</Nav.Link> */}
              <Nav.Link href="http://8circuitstudios.com/" target="_blank">8CS</Nav.Link>
              <NavDropdown title={ auth_userSteamDisplayName || 'PROFILE'} id="collasible-nav-dropdown">
                {/* {auth.auth_userSteamID.length > 0 && (<NavDropdown.Item href={ROUTES.ACCOUNT}>Account</NavDropdown.Item>)} */}
                {auth_userSteamDisplayName ? (<NavDropdown.Item href={auth_userSteamDisplayName ? ROUTES.PROFILE : ROUTES.THANKS}>{auth_userSteamDisplayName ? 'Profile Page' : 'Verify Email'}</NavDropdown.Item>) : (<NavDropdown.Item href={ROUTES.AUTH}>Sign Up/Log In</NavDropdown.Item>)}
                {/* {auth.auth_userSteamID.length > 0 && (<NavDropdown.Item onClick={() => deleteAccount()}>Delete Account</NavDropdown.Item>)} */}
                {auth_userSteamDisplayName && (<NavDropdown.Item onClick={() => logout()}>Log Out</NavDropdown.Item>)}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <Separator type={'blick'}/>
    </header>
  )
}

export default withRedux(withRouter(Header))
