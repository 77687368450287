import * as TYPES from './types'
import * as API from '../api'

/**
 * link twitch account
 * @param {string} code - OAuth twitch redirect code
 * @param {string} redirectUri - OAuth twitch redirectUri
 */
export function twitchLink (code: string, redirectUri: string) {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.TWITCH_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.TWITCH_ERROR, error: json.error.ERROR || 'wrong request' })
        return null
      }

      dispatch({
        type: TYPES.TWITCH_GET_DATA,
        payload: {
          twitchId: json.scriptData.data.twitchId,
          twitchDisplayName: json.scriptData.data.twitchDisplayName,
          twitchProfileImage: json.scriptData.data.twitchProfileImage
        }
      })

      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.TWITCH_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'twitch',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      scriptData: {
        action: 'link',
        code: code,
        redirectUri: redirectUri
      }
    }

    try {
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
/**
 * get twitch (user) data
 */
export function twitchGetData () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.TWITCH_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        // dispatch({ type: TYPES.TWITCH_ERROR, error: json.error.ERROR || 'wrong request' })
        dispatch({ type: TYPES.TWITCH_SUCCESS })
        return null
      }

      dispatch({
        type: TYPES.TWITCH_GET_DATA,
        payload: {
          twitchId: json.scriptData.data.twitchId,
          twitchDisplayName: json.scriptData.data.twitchDisplayName,
          twitchProfileImage: json.scriptData.data.twitchProfileImage
        }
      })

      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.TWITCH_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'twitch',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      scriptData: {
        action: 'getData'
      }
    }

    try {
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
/**
 * unlink twitch account
 */
export function twitchUnlink () {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.TWITCH_IN_PROGRESS })

    function onSuccess (json: any) {
      dispatch({ type: TYPES.TWITCH_UNLINK })

      return json.error ? null : json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.TWITCH_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    const body = {
      '@class': '.LogEventRequest',
      eventKey: 'twitch',
      playerId: auth.auth_userId,
      authToken: auth.auth_token,
      scriptData: {
        action: 'unlink'
      }
    }

    try {
      const json = await API.httpRequest(API.URL.EVENT, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
