import * as TYPES from './types'

// define initial state object
const initialState: TYPES.ContactState = {
  httpInProgress: false,
  httpError: '',
  httpSuccess: false
}

// define profile reducer
export function contactReducer (
  state = initialState,
  action: TYPES.ContactActionTypes
): TYPES.ContactState {
  switch (action.type) {
    case TYPES.CONTACT_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpInProgress: true, httpError: '' }
      }
    }
    case TYPES.CONTACT_ERROR: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: action.error }
      }
    }
    case TYPES.CONTACT_SUCCESS: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: '', httpSuccess: true }
      }
    }
    default:
      return state
  }
}
