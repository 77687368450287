/**
 * Defines Auth State interface
 */
export interface AuthState {
    steamAuthUrl: string;
    twitchAuthUrl: string;
    // http state
    httpInProgress: boolean;
    httpActionInProgress: boolean;
    httpError: string;
    // auth data
    auth_token: string;
    auth_userSteamID: string;
    auth_userSteamDisplayName: string;
    auth_userSteamProfileImage: string;
    auth_userTwitchID: string;
    auth_userTwitchDisplayName: string;
    auth_userTwitchProfileImage: string;
    auth_userSteamCode: string;
    auth_userSteamKey: string;
}

/**
   * Defines Auth actions names
   */
export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS'
export const AUTH_ACTION_IN_PROGRESS = 'AUTH_ACTION_IN_PROGRESS'
export const AUTH_ACTION_IN_PROGRESS_RESET = 'AUTH_ACTION_IN_PROGRESSRESET'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

/**
 * * Defines Auth actions interfaces
 * */
interface AuthInProgressAction {
  type: typeof AUTH_IN_PROGRESS;
}
interface AuthActionInProgressAction {
  type: typeof AUTH_ACTION_IN_PROGRESS;
}
interface AuthActionInProgressResetAction {
  type: typeof AUTH_ACTION_IN_PROGRESS_RESET;
}
interface AuthErrorAction {
  type: typeof AUTH_ERROR;
  error: string;
}
interface AuthLoginAction {
  type: typeof AUTH_LOGIN;
  payload: AuthState;
}
interface AuthLogoutAction {
  type: typeof AUTH_LOGOUT;
}

/**
   * Defines all suported actions for Auth
   */
export type AuthActionTypes =
  AuthLoginAction |
  AuthInProgressAction |
  AuthActionInProgressAction |
  AuthActionInProgressResetAction |
  AuthLogoutAction |
  AuthErrorAction;
/**
 * Defines AuthActionsInterface
 */
export interface AuthActionsInterface {
  // authLoginWithGoogle: (googleToken: string, steamKeyCode?: string) => Promise<JSON>;
  authLoginWithSteam: (steamID: string, authToken: string) => Promise<JSON>;
  authLogout: () => void;
  authAssignSteamKeyCode: (steamKeyCode: string) => Promise<JSON>;
  authLinkTwitchData: (twitchRegistrationCode: string, twitchRedirectUrl: string) => Promise<JSON>;
}
