import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

import { useWindowDimensions } from '../../Utils'

import { PaperContent } from '../../Components/Contents'

import drone from '../../assets/404/drone.png'
import droneShadow from '../../assets/404/drone-shadow.png'
import desktopLabel from '../../assets/404/Desktop404Label.png'
import mobileLabel from '../../assets/404/Mobile404Label.png'

const AbsentPage: React.FC = () => {
  // get screen dimensions hook { isMoble }
  const { isMobile } = useWindowDimensions()

  const renderDesktop = () => (
    <Row>
      <Col className="text-center">
        <Row>
          <Col/>
          <Col md={6}className="px-mx-0"><img className="img-absent-page-label" alt="404 label" src={desktopLabel}/></Col>
          <Col md={4}><img className="img-absent-page-drone" alt="absent drone" src={drone}/></Col>
          <Col/>
        </Row>
        <Row>
          <Col md={7}/>
          <Col md={4}><img className="img-absent-page-drone-shadow" alt="absent drone shadow" src={droneShadow}/></Col>
          <Col/>
        </Row>
      </Col>
    </Row>
  )

  const renderMobile = () => (
    <Row>
      <Col xs={2} md={4}></Col>
      <Col className="text-center">
        <Row>
          <Col><img className="img-absent-page-label" alt="404 label" src={mobileLabel}/></Col>
        </Row>
        <Row>
          <Col ><img className="img-absent-page-drone" alt="absent drone" src={drone}/></Col>
        </Row>
        <Row>
          <Col ><img className="img-absent-page-drone-shadow" alt="absent drone shadow" src={droneShadow}/></Col>
        </Row>
      </Col>
      <Col xs={2} md={4}></Col>
    </Row>
  )
  /**
   * Render
   */
  return (
    <PaperContent className="absent-page">
      <Container>
        {isMobile ? renderMobile() : renderDesktop()}
      </Container>
    </PaperContent>
  )
}

export default AbsentPage
