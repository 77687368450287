import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'

const twitchClientID = '3bfcdyfz0kmwqqq65qonyo5v9uklt0'

// define initial state object
const initialState: TYPES.TwitchState = {
  twitchAuthUrl: 'https://id.twitch.tv/oauth2/authorize' +
  '?response_type=code' +
  '&client_id=' + twitchClientID +
  '&redirect_uri=' + window.location.protocol + '//' + window.location.host + ROUTES.PROFILE +
  '&scope=viewing_activity_read',
  httpInProgress: false,
  httpError: '',
  twitchId: '',
  twitchDisplayName: '',
  twitchProfileImage: ''
}

// define profile reducer
export function twitchReducer (
  state = initialState,
  action: TYPES.TwitchActionTypes
): TYPES.TwitchState {
  switch (action.type) {
    case TYPES.TWITCH_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpInProgress: true, httpError: '' }
      }
    }
    case TYPES.TWITCH_ERROR: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: action.error }
      }
    }
    case TYPES.TWITCH_SUCCESS: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: '' }
      }
    }
    case TYPES.TWITCH_GET_DATA: {
      return {
        ...state,
        ...action.payload,
        ...{ httpInProgress: false, httpError: '' }
      }
    }
    case TYPES.TWITCH_UNLINK: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state
  }
}
