import React from 'react'

import * as TYPES from './types'

import { Container, Col, Row } from 'react-bootstrap'

import { MountiansContent } from '../../../Components/Contents'

import Separator from '../../../Components/Separator'

import Badge from '../../../Components/Badge'
import ProfileAvatar from '../../../Components/ProfileAvatar'

import imageBGRank from '../../../assets/profile/RankBGImage.png'

const ProfileHeader: React.FC<TYPES.ProfileHeaderProps> = (props: TYPES.ProfileHeaderProps) => {
  // get needed props
  const { displayName, userSelectedBadge, level } = props

  /**
   * Render
   */
  return (
    <MountiansContent >
      <Container className="public-profile-page">
        <Row>
          <Col xs={0} md={1} className="d-none d-md-block"/>
          <Col xs={12} md={4}>
            <Separator type="ui"/>
            <Row>
              <Col><h5 className="public-profile-label">Public Profile</h5></Col>
            </Row>
            <Row className="public-profile-badge-row">
              <Col xs={3}/>
              <Col><Badge badge={userSelectedBadge}/></Col>
              <Col xs={3}/>
            </Row>
            <Row>
              <Col className="text-center public-profile-display-name text-bold">
                {displayName.toUpperCase()}
              </Col>
            </Row>
            <Row className="public-profile-rank-row px-mx-0">
              <Col xs={3}/>
              <Col className="text-center public-profile-rank-image">
                <img src={imageBGRank} alt="background profile rank"/>
                { <h4 className="public-profile-rank-value text-bold">{level || 0}</h4> }
              </Col>
              <Col xs={3}/>
            </Row>
            <Separator type="ui"/>
          </Col>
          <Col xs={0} md={2} className="d-none d-md-block"/>
          <Col xs={0} md={4} className="d-none d-md-block">
            <ProfileAvatar avatar={'default'}/>
          </Col>
          <Col xs={0} md={1} className="d-none d-md-block"/>
        </Row>
      </Container>
    </MountiansContent>
  )
}

export default ProfileHeader
