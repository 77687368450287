/**
 * Leaderboards State Interface
 * */
export interface LeaderboardsState {
    /* api status */
    httpInProgress: boolean;
    httpError: string;
    /* leaderboards list */
    leaderboards_list: Array<any>;
    /* leaderboards data */
    leaderboards_data: Array<any>;
}

/**
 * Actions
 */
export const LEADERBOARDS_IN_PROGRESS = 'LEADERBOARDS_IN_PROGRESS'
export const LEADERBOARDS_ERROR = 'LEADERBOARDS_ERROR'
export const LEADERBOARDS_LIST = 'LEADERBOARDS_LIST'
export const LEADERBOARDS_GET = 'LEADERBOARDS_GET'
export const LEADERBOARDS_ADD_AUTH_ROW = 'LEADERBOARDS_ADD_AUTH_ROW'

/**
 * Action Interfaces
 */
interface LeaderboardsInProgressAction {
    type: typeof LEADERBOARDS_IN_PROGRESS;
}
interface LeaderboardsErrorAction {
    type: typeof LEADERBOARDS_ERROR;
    error: string;
}
interface LeaderboardsListAction {
    type: typeof LEADERBOARDS_LIST;
    payload: any;
}
interface LeaderboardsGetAction {
    type: typeof LEADERBOARDS_GET;
    payload: any;
}
interface LeaderboardsAddAuthRow {
    type: typeof LEADERBOARDS_ADD_AUTH_ROW;
    payload: any;
}

/**
 * Export all action interfaces
 */
export type LeaderboardsActionTypes = LeaderboardsInProgressAction | LeaderboardsErrorAction | LeaderboardsListAction | LeaderboardsGetAction | LeaderboardsAddAuthRow;

/**
 * Export Actions Interface
 */
export interface LeaderboardsActionsInterface {
    leaderboardsList: () => Promise<JSON>;
    leaderboardsGet: (type?: string) => void;
}
