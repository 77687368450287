import React from 'react'

import * as TYPES from './types'
import * as TABLE_TYPES from './ProfileStatsTable/types'

import { Container, Col, Row, Spinner } from 'react-bootstrap'

import { GrayContent } from '../../Components/Contents'

import ProfileStatsTable from './ProfileStatsTable'

import { withRedux } from '../../Redux/'

const ProfileStats: React.FC<TYPES.ProfileStatsProps> = (props: TYPES.ProfileStatsProps) => {
  const { stats } = props

  // render stats tables
  const renderStatsTables = () => {
    return ['GAME', 'COMBAT'].map((category: string, category_index: number) => (
      <Col xs={12} md={6} key={'key_' + category}>
        <ProfileStatsTable
          // name={category}
          data = {
            stats.filter((item: TABLE_TYPES.ProfileStatsTableObject, index: number) => {
              return (index % 2) === category_index
            })
          }
        />
      </Col>
    ))
  }

  return (
    <GrayContent>
      <Container className="profile-stats">
        <Row>
          <Col xs={1}/>
          <Col xs={10} className="pl-0 ml-0 profile-stats-title">
            <h4 className="text-bold text-align-center-left">CAREER STATS</h4>
          </Col>
          <Col xs={1}/>
        </Row>
        <Row className="profile-stats-content">
          <Col xs={1}/>
          <Col xs={10} className="px-0 mx-0">
            <Row>
              {!stats.length && <Col className="w-100 text-center"><Spinner as="span" animation="grow" role="status" aria-hidden="true" variant="light" /></Col>}
              {!!stats.length && renderStatsTables()}
            </Row>
          </Col>
          <Col xs={1}/>
        </Row>
      </Container>
    </GrayContent>
  )
}

export default withRedux(ProfileStats)
