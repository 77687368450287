import React from 'react'

import * as TYPES from './types'

import { Col, Row } from 'react-bootstrap'

const ProfileStatsTable: React.FC<TYPES.ProfileStatsTableProps> = (props: TYPES.ProfileStatsTableProps) => {
  // get needed props
  const { name, data } = props

  return (
    <div className="profile-stats-table">
      {name && (<div className="profile-stats-table-title text-bold">{name}</div>)}
      {data.map((item: TYPES.ProfileStatsTableObject, index: number) => {
        return (
          <div key={'row_' + index} className={ 'profile-stats-table-row ' + ((index % 2) ? 'row-odd' : 'row-even')}>
            <Row className="w-100 px-mx-0">
              <Col className="px-mx-0 text-bold">{item.name}</Col>
              <Col xs={1} className="px-mx-0 text-right">{item.value}</Col>
            </Row>
          </div>
        )
      })}
    </div>
  )
}

export default ProfileStatsTable
