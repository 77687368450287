import React from 'react'

import { Col, Row } from 'react-bootstrap'

import * as TYPES from './types'

import { withRedux } from '../../Redux/'

const ContainerVerifyEmail: React.FC<TYPES.ContainerVerifyEmailProps> = (props: TYPES.ContainerVerifyEmailProps) => {
  // get needed props
  const { profileSendVerifyEmail } = props
  // profile props
  const { httpInProgress, httpError } = props.profile

  // state
  const [emailVerificationSent, setEmailVerificationSent] = React.useState(false)

  /**
   * Send Verification Email
   */
  const sendVerificationEmail = () => {
    if (httpInProgress) return
    setEmailVerificationSent(false) // hide 'Sent' label
    profileSendVerifyEmail().then((json: any) => {
      if (json) setEmailVerificationSent(true)
    })
  }

  /**
   * Render
   */
  return (
    <div className="pg-text thanks-content">
      <Row>
        <Col xs={1}></Col>
        <Col xs={10} className="px-mx-0">
          <h2 className="text-bold text-align-center-left">
                Thank You for&nbsp;
            <br className="d-md-none"/>signing up&nbsp;
            <br className="d-none d-md-block"/>for&nbsp;
            <br className="d-md-none"/>Project Genesis.
          </h2>
        </Col>
        <Col xs={1}></Col>
      </Row>
      <Row className="">
        <Col xs={1}></Col>
        <Col xs={10} className="check-email-row px-mx-0">
          <h1 className="text-align-center-left text-bold">
                Check your email to&nbsp;
            <br className="d-none d-md-block" />verify your account.
          </h1>
        </Col>
        <Col xs={1}></Col>
      </Row>
      <Row>
        <Col xs={1}></Col>
        <Col xs={10} className="px-mx-0">
          <h4 className="text-align-center-left">
            Didn’t get an email from us?
            <br/>Click {httpInProgress ? ('....') : (<button className="pg-link" onClick={() => { sendVerificationEmail() }}>here</button>)} to receive a new one.
          </h4>
        </Col>
        <Col xs={1}></Col>
      </Row>
      <Row>
        <Col xs={1}></Col>
        <Col xs={10} md={8} className="text-align-center-left px-mx-0">
          {httpError && (<div className="thanks-verification-error">{httpError}</div>)}
          {emailVerificationSent && (<div className="thanks-verification-success text-bold w-100">
            Email Sent!
          </div>)}
        </Col>
        <Col xs={4}></Col>
      </Row>
      <Row>
        <Col xs={1}></Col>
        <Col xs={10} md={11} className="px-mx-0">
          <p className="text-align-center-left px-mx-0">
            This version of Project Genesis is a Pre-Apha&nbsp;
            <br className="d-block d-md-none"/>Version and may differ&nbsp;
            <br className="d-none d-md-block"/>from the final product.&nbsp;
            <br className="d-block d-md-none"/>© 2020. 8 Circuit Studios, All rights reserved.
          </p>
        </Col>
        <Col xs={1} className="d-md-none"></Col>
      </Row>
    </div>
  )
}

export default withRedux(ContainerVerifyEmail)
