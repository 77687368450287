import React from 'react'

import { Overlay, Tooltip, Spinner } from 'react-bootstrap'

import * as TYPES from './types'

const SpanCopyToClipboard: React.FC<TYPES.SpanCopyToClipboardProps> = (props: TYPES.SpanCopyToClipboardProps) => {
  // get custom props
  const { loading, string, className } = props
  // clipboard tooltip states
  const [ClipboardTooltip, setClipboardTooltip] = React.useState('')
  const [showTooltip, setShowTooltip] = React.useState(false)
  // target for tooltip
  const target = React.useRef(null)

  const copyToClipboard = (copyText: string) => {
    if (ClipboardTooltip) return
    try {
      if ((navigator as any).clipboard) (navigator as any).clipboard.writeText(copyText)
      // IE
      else if ((window as any).clipboardData) (window as any).clipboardData.setData('text', copyText)
      // Chrome, Safari, Mac OS
      else {
        const dummy = document.createElement('textarea')
        document.body.appendChild(dummy)
        dummy.value = copyText
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)
      }

      setClipboardTooltip('Copied to Clipboard')
    } catch (e) {
      setClipboardTooltip('Copy manually')
    }

    // show tooltip
    setShowTooltip(true)

    // hide tooltip
    setTimeout(() => {
      setShowTooltip(false)
    }
    , 2000)

    // clean tooltip text
    setTimeout(() => {
      setClipboardTooltip('')
    }
    , 2200)
  }

  return (
    <>
      <span
        className={className}
        onClick={() => { if (!loading) copyToClipboard(string || ''); else return null }}
        ref={target}>
        {!loading && string}
        {loading && (<Spinner className="badge-spinner" as="span" animation="grow" role="status" aria-hidden="true" variant="dark" />)}
      </span>
      <Overlay
        target={target.current as any}
        show={showTooltip}
        placement="top"
      >
        <Tooltip id="span-tooltip">
          {ClipboardTooltip}
        </Tooltip>
      </Overlay>
    </>
  )
}

export default SpanCopyToClipboard
