import React from 'react'

import { Container, Col, Row, Image } from 'react-bootstrap'

import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'

import { PaperContent } from '../../Components/Contents'

import { withRedux } from '../../Redux/'
import { withRouter } from 'react-router-dom'

import imageHacker from '../../assets/landing/PGHackerDesktopDark.png'

import ContainerVerifyEmail from './ContainerVerifyEmail'
import ContainerEmailVerified from './ContainerEmailVerified'

import { Helmet } from 'react-helmet'
import { LoadingPage } from '../404'

class Thanks extends React.Component<TYPES.ThanksProps, TYPES.ThanksState> {
  state: Readonly<TYPES.ThanksState> = {
    isInVerificationProcess: false
  };

  componentDidMount () {
    // get needed props
    const {
      auth,
      profile,
      profileSetVerificationToken,
      profileVerifyEmail,
      location,
      history
    } = this.props

    const { auth_token } = auth
    const { profile_verificationToken, profile_isEmailVerified } = profile

    /**
      * Conditions for thank you page
      * authenticated -> THANK YOU with verify your email button
      * authenticated with token -> write down token and relaunch THANKS page
      */

    const query = new URLSearchParams(location.search)
    const token = query.get('token') || profile_verificationToken

    if (token) {
      this.setState({ isInVerificationProcess: true })
      profileSetVerificationToken(token).then(() => {
        if (auth_token.length) { // if logged in -> verify email
          profileVerifyEmail().then(() => {
            history.push(ROUTES.PROFILE)
          })
        } else { // if not logged in -> redirect to Login Page with another device/browser warning
          history.push(ROUTES.AUTH + '/warning')
        }
      })
      return
    }

    // if NOT authenticated -> redirect to AUTH
    // if authenticated and email is verified -> redirect to Profile
    if (!auth_token.length) history.push(ROUTES.AUTH)
    else if (profile_isEmailVerified) history.push(ROUTES.PROFILE)
  }

  /**
   * Render
   */
  render () {
    // get isEmailVerified from profile
    const { profile_isEmailVerified } = this.props.profile
    return (
      <PaperContent className="thanks">
        <Helmet>
          <title>Project Genesis | Thank You</title>
          <meta name="description" content="Thank you page featuring an email verification link to complete your registration, and links to Pre Alpha Application, Discord Community, Newsletter Sign Up, Steam Product page and Project Genesis website." />
        </Helmet>
        {this.state.isInVerificationProcess && <LoadingPage/>}
        {!this.state.isInVerificationProcess && (
          <Container className="thanks-container">
            <Row>
              <Col md={1} className="d-none d-md-block px-mx-0"></Col>
              <Col md={5} className="d-none d-md-block px-mx-0">
                <Image src={imageHacker} className="thanks-bg-image"/>
              </Col>
              <Col xs={12} md={6}>
                { profile_isEmailVerified && <ContainerEmailVerified/>}
                { !profile_isEmailVerified && <ContainerVerifyEmail/>}
              </Col>
            </Row>
          </Container>
        )}
      </PaperContent>
    )
  }
}

export default withRedux(withRouter(Thanks))
