import * as TYPES from './types'
import * as API from '../api'

import { leaderboardsList } from '../leaderboards/actions'
/**
 * Search Users by string
 * @param {string} searchString. Search string.
 */
export function searchUsers (searchString: string) {
  return async (dispatch: any) => {
    // define request state as in progress
    dispatch({ type: TYPES.SEARCH_IN_PROGRESS })

    // error handler
    function onError () {
      dispatch({ type: TYPES.SEARCH_USERS, search_data: [] })
      return null
    }

    // success handler
    function onSuccess (json: any) {
      // error
      if (!json || json.error) return onError()

      // success
      return dispatch({
        type: TYPES.SEARCH_USERS,
        search_data: json.map((item: any) => {
          // item.userSelectedBadge = item.scriptData.selected_badge || ''
          return item
        })
      })
    }
    // request
    try {
      const json = await API.httpRequest(API.URL.SEARCH_USERS + '?q=' + encodeURI(searchString), {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
      })
      return onSuccess(json)
    } catch (error) {
      return onError()
    }
  }
}

/**
 * Get Public Profile Page by User displayName
 * @param {string} profileDisplayName. Display Name to search
 */
export function getPublicProfile (profileUserName: string) {
  // return async
  return async () => {
    // error handler
    function onError () { return null }

    // success handler
    function onSuccess (json: any) {
      // error
      if (json.error || !json) return onError()

      // parse selected badge
      const selected_badge_data = json.scriptData.items
        .filter((item: Record<string, any>) => {
          return item.category === 'badge'
        }).find((item: Record<string, any>) => {
          return item.itemType === json.scriptData.selected_badge
        })
      let selected_badge = ''
      if (json.scriptData.selected_badge) {
        selected_badge = (json.scriptData.selected_badge.includes('b_') ? json.scriptData.selected_badge.substring(2) : json.scriptData.selected_badge)
        selected_badge += '_lvl'
        selected_badge += (selected_badge_data ? selected_badge_data.level : '1')
      }
      json.userSelectedBadge = selected_badge
      json.userID = json._id.$oid || 'empty'
      return json
    }

    // catches a malformed URI
    try {
      profileUserName = decodeURI(profileUserName)
    } catch (e) {
      return onError()
    }

    // request
    try {
      const json = await API.httpRequest(API.URL.PUBLIC_PROFILE_BASE + profileUserName.toLowerCase(), {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
      })
      return onSuccess(json)
    } catch (error) {
      return onError()
    }
  }
}
/**
 * Get User Stats
 * @param {string }userName
 */
export function getUserStats (userName: string) {
  const publicStatsRequest = (userName: string, leaderboards: Array<any>) => {
    return async () => {
      /* anonymous stats request */

      // error handler
      function onError () { return null }

      // success handler
      function onSuccess (json: any) {
        // error
        if (json.error || !json) return onError()

        json.leaderboards = leaderboards.map(leaderboard => {
          leaderboard.value = json[leaderboard.shortCode] || 0
          return leaderboard
        })

        return json
      }

      // request
      try {
        const json = await API.httpRequest(API.URL.PUBLIC_PROFILE_STATS_BASE + userName, {
          method: 'get',
          headers: { 'Content-Type': 'application/json' }
        })
        return onSuccess(json)
      } catch (error) {
        return onError()
      }
    }
  }
  // return async
  return async (dispatch: any, getState: any) => {
    const { leaderboards } = getState()
    if (!leaderboards.leaderboards_list.length) {
      return dispatch(leaderboardsList()).then((json: any) => {
        if (!json) return json
        return dispatch(publicStatsRequest(userName, json.leaderboards))
      })
    } else return dispatch(publicStatsRequest(userName, leaderboards.leaderboards_list))
  }
}
