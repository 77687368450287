import React from 'react'

import * as TYPES from './types'

const MountiansContent: React.FC<TYPES.MountiansContentProps> = (props: TYPES.MountiansContentProps) => {
  const { className, hideMobile } = props
  return (
    <div className={'pg-mountians-content' + (hideMobile ? '-mobile-off' : '') + ' ' + className}>
      <div className="shadow-top"/>
      <div className="shadow-bottom"/>
      {props.children}
    </div>
  )
}

export default MountiansContent
