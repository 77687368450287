import * as TYPES from './types'

// define initial state object
const initialState: TYPES.LeaderboardsState = {
  httpInProgress: false,
  httpError: '',
  leaderboards_list: [],
  leaderboards_data: []
}

// define profile reducer
export function leaderboardsReducer (
  state = initialState,
  action: TYPES.LeaderboardsActionTypes
): TYPES.LeaderboardsState {
  switch (action.type) {
    case TYPES.LEADERBOARDS_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpInProgress: true, httpError: '' }
      }
    }
    case TYPES.LEADERBOARDS_ERROR: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: action.error }
      }
    }
    case TYPES.LEADERBOARDS_LIST: {
      return {
        ...state,
        ...action.payload
      }
    }
    case TYPES.LEADERBOARDS_GET: {
      return {
        ...state,
        ...action.payload,
        ...{ httpInProgress: false, httpError: '' }
      }
    }
    case TYPES.LEADERBOARDS_ADD_AUTH_ROW: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}
