import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'

// define initial state object
const initialState: TYPES.SteamState = {
  steamAuthUrl: 'https://steamcommunity.com/openid/login' +
  '?openid.ns=http://specs.openid.net/auth/2.0' +
  '&openid.mode=checkid_setup' +
  '&openid.return_to=' + window.location.protocol + '//' + window.location.host + ROUTES.PROFILE +
  '&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select' +
  '&openid.identity=http://specs.openid.net/auth/2.0/identifier_select',
  httpInProgress: false,
  httpError: '',
  steamId: '',
  steamDisplayName: '',
  steamProfileImage: ''
}

// define steam reducer
export function steamReducer (
  state = initialState,
  action: TYPES.SteamActionTypes
): TYPES.SteamState {
  switch (action.type) {
    case TYPES.STEAM_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpInProgress: true, httpError: '' }
      }
    }
    case TYPES.STEAM_ERROR: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: action.error }
      }
    }
    case TYPES.STEAM_SUCCESS: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: '' }
      }
    }
    case TYPES.STEAM_GET_DATA: {
      return {
        ...state,
        ...action.payload,
        ...{ httpInProgress: false, httpError: '' }
      }
    }
    case TYPES.STEAM_UNLINK: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state
  }
}
