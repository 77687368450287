import React from 'react'

import { Container, Col, Row } from 'react-bootstrap'

import { PaperContent, MountiansContent } from '../../Components/Contents'
import Up from '../../Components/Up'

import BadgeTestPilot from '../../assets/testPilot/BadgeTestPilot.png'
import IconCommunityBadges from '../../assets/testPilot/iconCommunityBadges.png'
import IconAmbassador from '../../assets/testPilot/iconAmbassador.png'
import IconTestPilot from '../../assets/testPilot/iconTestPilot.png'
import IconAcePilot from '../../assets/testPilot/iconAcePilot.png'
import IconPioneers from '../../assets/testPilot/iconPioneers.png'
import IconHowToApply from '../../assets/testPilot/iconHowToApply.png'

const TestPilotProgram: React.FC = () => {
  return (
    <PaperContent className="test-pilot-program">
      <MountiansContent >
        <Container className="test-pilot-program-header">
          <Row>
            <Col xs={12} md={7} className="pg-text">
              <h1 className="text-bold text-align-center-left">TEST PILOT
                <br/>PROGRAM
              </h1>
              <h3 className="text-bold text-align-center-left">
                  Badges for the
                <br className="d-md-none"/>Pilot Program
                <br/>(Available During
                <br className="d-md-none"/>Pre-Alpha)
              </h3>
              <p>
              Interested in participating in the Project Genesis Pilot Program to get recognized for your contribution as a Test Pilot, content creator, competitive playtester, or community Ambassador? We have an exclusive offer for community members who want to go above and beyond the call of duty.
              </p>
            </Col>
            <Col md={5} className="text-center d-none d-md-block px-4">
              <img src={BadgeTestPilot} className="w-100" alt="Badge test pilot"/>
            </Col>
          </Row>
        </Container>
      </MountiansContent>
      <Container className="test-pilot-program-item">
        <Row>
          <Col md={2} className="d-none d-md-block"/>
          <Col xs={12} md={4}>
            <img src={IconCommunityBadges} className="w-100" alt="icon community badges"/>
          </Col>
          <Col xs={12} md={6} className="px-mx-0">
            <h2 className="community-badges-first-line text-align-center-left text-bold">COMMUNITY</h2>
            <h2 className="community-badges-second-line text-align-center-left text-bold">BADGES</h2>
          </Col>
        </Row>
        <Row className="test-pilot-program-item-paragraph">
          <Col xs={12} md={6}>
            <p>If you like breaking things, consider yourself a competitive gamer, enjoy creating content, or just want to contribute to our growing community, we have an official badge program to recognize your contribution to the Project Genesis community within one of four categories:</p>
            <p>&bull; Testing
              <br/>&bull; Balancing
              <br/>&bull; Content Creating
              <br/>&bull; Moderating
            </p>
          </Col>
          <Col xs={12} md={6}>
            <p>Badges for each category will live in several places within the game and on profile assets outside the game.</p>
            <p>Each badge will offer level progression. This means that a badge at level two will appear different, in effect, greater than, a badge at level one. A badge at level three, greater than a badge at level two and so on. Level progression for badges is achieved using a point and weighting system. The system takes into account how a community member contributes according to the badge’s category.</p>
          </Col>
        </Row>
        <Row>
          <Col className="test-pilot-program-item-separator"/>
        </Row>
      </Container>
      <Container className="test-pilot-program-item">
        <Row>
          <Col md={1} className="d-none d-md-block"/>
          <Col xs={12} md={4}>
            <img src={IconAmbassador} className="w-100" alt="icon ambassador"/>
          </Col>
          <Col xs={12} md={7} className="px-mx-0">
            <h2 className="ambassador-first-line text-align-center-left text-bold">AMBASSADORS</h2>
          </Col>
        </Row>
        <Row className="test-pilot-program-item-paragraph">
          <Col xs={12} md={6}>
            <p>Ambassadors fulfill the role of moderators and hosts for new community members as well as extend that role across platforms. They excel at helping others have a great experience with Project Genesis and play emcee within our growing community.</p>
            <p>Badge progression within this category is weighted according to the following (in order from most points to least):</p>
          </Col>
          <Col xs={12} md={6}>
            <p>&bull; Platform (Discord, Reddit, Facebook) engagement
              <br/>&bull; Moderating actions on platform (Discord, Reddit, Facebook)
              <br/>&bull; Attending community playtest sessions
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="test-pilot-program-item-separator"/>
        </Row>
      </Container>
      <Container className="test-pilot-program-item">
        <Row>
          <Col md={2} className="d-none d-md-block"/>
          <Col xs={12} md={4}>
            <img src={IconTestPilot} className="w-100" alt="icon test pilot"/>
          </Col>
          <Col xs={12} md={6} className="px-mx-0">
            <h2 className="test-pilot-first-line text-align-center-left text-bold">TEST</h2>
            <h2 className="test-pilot-second-line text-align-center-left text-bold">PILOTS</h2>
          </Col>
        </Row>
        <Row className="test-pilot-program-item-paragraph">
          <Col xs={12} md={6}>
            <p>Test Pilots enjoy breaking things, are obsessed with quality, and will help reproduce and sleuth bugs. They will often get the earliest access to builds to verify the game or run new features through testing to ensure a stable enough build state for wider release.</p>
            <p>Badge progression within this category is weighted according to the following (in order from most points to least):</p>
          </Col>
          <Col xs={12} md={6}>
            <p>&bull; Attending test pilot sessions
              <br/>&bull; Submitting bug reports
              <br/>&bull; Attending community playtest sessions
              <br/>&bull; Discord engagement
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="test-pilot-program-item-separator"/>
        </Row>
      </Container>
      <Container className="test-pilot-program-item">
        <Row>
          <Col md={2} className="d-none d-md-block"/>
          <Col xs={12} md={4}>
            <img src={IconAcePilot} className="w-100" alt="icon ace pilot"/>
          </Col>
          <Col xs={12} md={6} className="px-mx-0">
            <h2 className="test-pilot-first-line text-align-center-left text-bold">ACE</h2>
            <h2 className="test-pilot-second-line text-align-center-left text-bold">PILOTS</h2>
          </Col>
        </Row>
        <Row className="test-pilot-program-item-paragraph">
          <Col xs={12} md={6}>
            <p>Ace Pilots are competitive community members who help refine play balance, find and quash exploits and help create the most visceral experience that is ‘easy to play, hard to master’. </p>
            <p>They often have experience as competitive players in hero shooters or space combat games, and may even find themselves on ranked lists in their favorite game genres.</p>
          </Col>
          <Col xs={12} md={6}>
            <p>Badge progression within this category of contribution is weighted according to the following (in order from most points to least):
              <br/>&bull; Attending Ace pilot sessions
              <br/>&bull; Submitting post-Ace-pilot session feedback
              <br/>&bull; Attending community playtest sessions
              <br/>&bull; Discord engagement
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="test-pilot-program-item-separator"/>
        </Row>
      </Container>
      <Container className="test-pilot-program-item">
        <Row>
          <Col md={1} className="d-none d-md-block"/>
          <Col xs={12} md={4}>
            <img src={IconPioneers} className="w-100" alt="icon pioneers"/>
          </Col>
          <Col xs={12} md={7} className="px-mx-0">
            <h2 className="pioneers-first-line text-align-center-left text-bold">PIONEERS</h2>
          </Col>
        </Row>
        <Row className="test-pilot-program-item-paragraph">
          <Col xs={12} md={6}>
            <p>Pioneers are the content creators in the Pilot Program: artists, partners, streamers and content producers. They are the kind of early adopters who blaze trails in new territory for others. They examine and demonstrate what is worth experiencing in Project Genesis to their audience in fun and creative ways.</p>
            <p>Badge progression within this category is weighted according to the following (in order from most points to least):</p>
          </Col>
          <Col xs={12} md={6}>
            <p>&bull; Live streaming or post-producing playtest content
              <br/>&bull; Sharing created content with Discord or Steam
              <br/>&bull; Creating memes, art, creative writing or any other type of creative work
              <br/>&bull; Attending community playtest sessions
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="test-pilot-program-item-separator"/>
        </Row>
      </Container>
      <Container className="test-pilot-program-item">
        <Row>
          <Col md={1} className="d-none d-md-block"/>
          <Col xs={12} md={5}>
            <img src={IconHowToApply} className="w-100" alt="icon how to apply"/>
          </Col>
          <Col xs={12} md={6} className="px-mx-0">
            <h2 className="how-to-apply-first-line text-align-center-left text-bold">HOW TO</h2>
            <h2 className="how-to-apply-second-line text-align-center-left text-bold">APPLY</h2>
          </Col>
        </Row>
        <Row className="test-pilot-program-item-paragraph">
          <Col xs={12}>
            <p>To indicate your interest in participating in the Partner Pilot Program and earn a badge, simply participate in a community playtest. Then complete the post-playtest survey and indicate the badge(s) you’re interested in. Community members can accrue badges across all categories and are not committed to a single type.</p>
          </Col>
        </Row>
      </Container>
      <Up/>
    </PaperContent>
  )
}

export default TestPilotProgram
