import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

import { PaperContent } from '../../Components/Contents'

import drone from '../../assets/404/drone.png'
import droneShadow from '../../assets/404/drone-shadow.png'
import loadingSign from '../../assets/404/loadingSign.png'

const LoadingPage: React.FC = () => {
  /**
   * Render
   */
  return (
    <PaperContent className="loading-page">
      <Container>
        <Row>
          <Col xs={2} md={4}></Col>
          <Col className="text-center">
            <Row>
              <Col><img className="img-loading-page-drone" alt="search drone" src={drone}/></Col>
            </Row>
            <Row>
              <Col><img className="img-loading-page-drone-shadow" alt="search drone shadow" src={droneShadow}/></Col>
            </Row>
            <Row>
              <Col><img className="img-loading-page-sign" alt="search loading sign" src={loadingSign}/></Col>
            </Row>
          </Col>
          <Col xs={2} md={4}></Col>
        </Row>
      </Container>
    </PaperContent>
  )
}

export default LoadingPage
