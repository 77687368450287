import React, { useState } from 'react'

import { Col, Row, Button, Form, InputGroup } from 'react-bootstrap'

import * as TYPES from './types'

import { withRedux } from '../../../Redux'
import { useWindowDimensions } from '../../../Utils'

import profileIconSteam from '../../../assets/profile/GetStartedSteamKey/SteamBtnLogo.png'
// import linkToSteam from '../../../assets/profile/GetStartedSteamKey/LinkToSteamBtn.png'
// import linkedToSteam from '../../../assets/profile/GetStartedSteamKey/LinkedToSteam.png'
// import linkedToSteamMobile from '../../../assets/profile/GetStartedSteamKey/LinkedToSteamMobile.png'

const ProfileGetStartedDefault: React.FC<TYPES.ProfileGetStartedDefaultProps> = (props: TYPES.ProfileGetStartedDefaultProps) => {
  // get needed props
  const { auth, authAssignSteamKeyCode } = props
  // get needed profile props
  const { httpActionInProgress, auth_userSteamCode } = auth

  const [steamKeyCodeValue, setSteamKeyCodeValue] = useState('')

  // check if the render is for Mobile
  const { isMobile } = useWindowDimensions()

  const steamKeyCodeValueChange = (event: any) => {
    setSteamKeyCodeValue(event.target.value.toUpperCase())
  }

  const clickAssignSteamCode = () => {
    if (!auth.httpActionInProgress && !auth.httpInProgress && !auth.httpError.length) {
      authAssignSteamKeyCode(steamKeyCodeValue)
    }
  }

  return (
    <Row className="profile-block-content">
      <Col className="px-mx-0">
        <Row className="profile-block-content-padding px-0 mx-0">
          <Col xs={1} md={1} className="px-md-0 mx-md-0"/>
          <Col xs={10} md={10} className="px-md-0 mx-md-0">
            <h2 className={(isMobile ? 'text-center' : 'float-left') + ' profile-block-content-padding text-bold p-top-welcome'}>
              WELCOME TO&nbsp;<br className="d-block d-md-none"/>PROJECT GENESIS,&nbsp;<br className="d-block d-md-none"/>PILOT!
            </h2>
          </Col>
          <Col xs={1} md={1} />
        </Row>
        {/* <Row className="profile-block-content-half-padding px-0 mx-0">
          <Col xs={1} md={1} className="px-0 mx-0"/>
          <Col xs={10} md={6} className="px-0 mx-0">
            <p className={isMobile ? 'text-center' : 'float-left'}>Link your Steam account to access your available <span className="text-bold">Founders Pack</span> items when you purchase <span className="text-bold">Project Genesis Early Access</span>. If you don’t intend to purchase Early Access, linking your account is not required.</p>
          </Col>
          <Col xs={1} md={1} className="px-0 mx-0"/>
        </Row>
        {steam.steamDisplayName && ( */}
        {/* <Row className="px-0 mx-0">
          <Col xs={1} md={1} className="px-0 mx-0"/>
          <Col xs={10} md={3} className="px-0 mx-0">
            <img className="w-100" src={isMobile ? linkedToSteamMobile : linkedToSteam} alt="Linked to Steam Notification"/>
          </Col>
          <Col xs={1} md={7} className="px-0 mx-0"/>
        </Row> */}
        {/* )} */}
        {/* {!steam.steamDisplayName && (
          <Row className="px-0 mx-0">
            <Col xs={1} md={1} className="px-0 mx-0"/>
            <Col xs={10} md={2} className="px-0 mx-0">
              {!profile.httpInProgress && !steam.httpInProgress && (
                <Button
                  className="get-started-link-to-steam-btn"
                  onClick={() => { window.open(steam.steamAuthUrl, '_self') }}
                >
                  <img
                    src={linkToSteam}
                    alt="Link to Steam Notification"
                    className="w-100"/>
                </Button>
              )}
            </Col>
            <Col xs={1} md={9} className="px-0 mx-0"/>
          </Row>
        )} */}
        {!auth_userSteamCode.length && (
          <Row className="profile-block-content-padding px-0 mx-0">
            <Col xs={1} md={1} className="px-md-0 mx-md-0"/>
            <Col>
              <Row>
                <h4 className="text-bold">Do you have a PROMO code? {isMobile ? '' : ':'}&nbsp;&nbsp;</h4>
              </Row>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="PROMO Code"
                    aria-label="PROMO Code"
                    aria-describedby="promo-input"
                    value={steamKeyCodeValue}
                    onChange={steamKeyCodeValueChange}
                    disabled={httpActionInProgress}
                  />
                  <InputGroup.Text id="promo-input" className='cursor-pointer' onClick={clickAssignSteamCode}>Add PROMO Code</InputGroup.Text>
                </InputGroup>
              </Row>
            </Col>
            <Col xs={1} md={5} />
          </Row>
        )}
        {auth_userSteamCode.length > 0 && (
          <Row className="profile-block-content-padding px-0 mx-0">
            <Col xs={1} md={1} className="px-md-0 mx-md-0"/>
            <Col className="px-0 mx-0 profile-block-content-padding">
              <h4>Your account PROMO {isMobile ? '' : ':'}&nbsp;&nbsp;<span className="text-bold">{auth_userSteamCode}</span></h4>
            </Col>
            <Col xs={1} md={1} />
          </Row>
        )}
        {auth_userSteamCode.length > 0 && (
          <Row className="px-0 mx-0">
            <Col xs={1} md={1} className="px-0 mx-0"/>
            <Col xs={10} md={6} className="px-0 mx-0 profile-block-content-padding">
              <p className={isMobile ? 'text-center' : 'float-left'}>Sorry, the code you entered is invalid, or part of a promotion that is no longer active. For additional information on current promotions or to request support, please visit us on <a href="https://discord.gg/kTQRsJp" rel="noopener noreferrer" target="_blank" className="a-inline-colored">Discord</a></p>
            </Col>
            <Col xs={1} md={1} className="px-0 mx-0"/>
          </Row>
        )}
        <Row className="profile-block-content-padding px-0 mx-0">
          <Col xs={1} md={1} className="px-0 mx-0"/>
          <Col xs={10} md={6} className="px-0 mx-0 profile-block-content-padding">
            <p className={isMobile ? 'text-center' : 'float-left'}>If you would like to become a <span className="text-bold">Founder</span> and immediately receive your honorary Founders badge and unlock battle craft and avatar customization options, visit our Steam page to purchase <span className="text-bold">Early Access.</span></p>
          </Col>
          <Col xs={1} md={1} className="px-0 mx-0"/>
        </Row>
        {!isMobile && (
          <Row className="px-0 mx-0">
            <Col xs={1} md={1} className="px-0 mx-0"/>
            <Col xs={10} md={6} className="px-0 mx-0">
              <iframe className="w-100" src="https://store.steampowered.com/widget/700240/" frameBorder="0" width="646" height="190" title="steam frame"></iframe>
            </Col>
            <Col xs={1} md={7} className="px-0 mx-0"/>
          </Row>
        )}
        {isMobile && (
          <Row className="px-0 mx-0">
            <Col xs={1} md={1} className="px-0 mx-0"/>
            <Col xs={10} md={2} className="px-0 mx-0">
              <Button
                className="get-started-steam-btn"
                onClick={() => { window.open('https://store.steampowered.com/app/700240/Project_Genesis/') }}
              >
                <img
                  src={profileIconSteam}
                  alt="steam whish us button"
                  className="w-100"/>
              </Button>
            </Col>
            <Col xs={1} md={9} className="px-0 mx-0"/>
          </Row>
        )}
      </Col>
    </Row>
  )
}

export default withRedux(ProfileGetStartedDefault)
