import React from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
// redux usage
import { Provider } from 'react-redux'
import reduxStore from '../Redux'

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import * as ROUTES from '../Constants/routes'

import Landing from '../Containers/Landing'
import Schedule from '../Containers/Schedule'
import Community from '../Containers/Community'
import Search from '../Containers/Search'
import PublicProfile from '../Containers/PublicProfile'
import TestPilotProgram from '../Containers/TestPilotProgram'
import Leaderboards from '../Containers/Leaderboards'
// import Careers from '../Containers/Careers'
import Auth from '../Containers/Auth'
import Thanks from '../Containers/Thanks'
import Account from '../Containers/Account'
import Profile from '../Containers/Profile'
// import ContactUs from '../Containers/ContactUs'
import { PrivacyPolicy, TermsOfService, UserAgreement } from '../Containers/Policies'

import { AbsentPage } from '../Containers/404'

import AuthorizedRoute from './AuthorizedRoute'

const store = reduxStore()

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <Header/>
        <Switch>
          <Route exact={true} path={ROUTES.LANDING} component={Landing} />
          <Route path={ROUTES.SCHEDULE} component={Schedule} />
          <Route path={ROUTES.COMMUNITY} component={Community} />
          <Route path={ROUTES.SEARCH} component={Search} />
          <Route path={ROUTES.PUBLIC_PROFILE} component={PublicProfile} />
          <Route path={ROUTES.TEST_PILOT_PROGRAM} component={TestPilotProgram} />
          <Route path={ROUTES.LEADERBOARDS} component={Leaderboards} />
          <Route path={ROUTES.LEADERBOARDS_WITHOUT_BG} component={Leaderboards} />
          {/* <Route path={ROUTES.CAREERS} component={Careers} /> */}
          <Route path={ROUTES.AUTH} component={Auth} />
          <Route path={ROUTES.THANKS} component={Thanks} />
          <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService} />
          <Route path={ROUTES.USER_AGREEMENT} component={UserAgreement} />
          <AuthorizedRoute path={ROUTES.PROFILE} component={Profile} />
          <AuthorizedRoute path={ROUTES.ACCOUNT} component={Account} />
          <Route component={AbsentPage}/>
        </Switch>
        <Footer/>
      </Router>
    </Provider>
  )
}

export default App
