import React from 'react'

import { Container, Col, Row, Spinner } from 'react-bootstrap'

import * as TYPES from './types'

import { withRedux } from '../../../Redux'
import { useWindowDimensions } from '../../../Utils'

import ProfileGetStartedDefault from './ProfileGetStartedDefault'
import ProfileGetStartedSteamKey from './ProfileGetStartedSteamKey'
import ProfileGetStartedEA from './ProfileGetStartedEA'

const ProfileGetStarted: React.FC<TYPES.ProfileGetStartedProps> = (props: TYPES.ProfileGetStartedProps) => {
  // get needed props
  const { auth } = props

  // get needed profile props
  const { httpInProgress, auth_userSteamKey } = auth

  // check if the render is for Mobile
  // const { isMobile } = useWindowDimensions()

  /**
   * Render
   */
  return (
    <Container className="profile-block">
      {/* <Row>
        <Col xs={1}/>
        <Col xs={10} className="profile-block-title pl-0 ml-0">
          <Row>
            <Col className="text-center">
              <h4 className={(isMobile ? 'text-center' : 'float-left') + ' text-bold'}>GET STARTED</h4>
            </Col>
          </Row>
        </Col>
        <Col xs={1}/>
      </Row> */}
      {/* {httpInProgress && (
        <Row className="profile-block-content-padding px-mx-0">
          <Col xs={1}/>
          <Col xs={10} className="px-mx-0 profile-block-content">
            <Row className="px-md-0 mx-md-0">
              <Col className="px-md-0 mx-md-0">
                <Col className="px-md-0 mx-md-0 text-center py-0">
                  <Spinner as="span" animation="grow" role="status" aria-hidden="true" variant="dark" />
                </Col>
              </Col>
            </Row>
          </Col>
          <Col xs={1}/>
        </Row>
      )} */}
      {/* {!httpInProgress && profile_is_ea && (<ProfileGetStartedEA/>)} */}
      {!httpInProgress && auth_userSteamKey && (<ProfileGetStartedSteamKey/>)}
      {!httpInProgress && !auth_userSteamKey && (<ProfileGetStartedDefault/>)}
    </Container>
  )
}

export default withRedux(ProfileGetStarted)
