import * as TYPES from './types'
import * as API from '../api'

// import { profileGet, profileSetIsEmailVerified } from '../profile/actions'

const generateAuthStoragePayload = (
  authToken: string,
  userSteamID: string,
  userSteamDisplayName: string,
  userSteamProfileImage: string,
  userTwitchID: string,
  userTwitchDisplayName: string,
  userTwitchProfileImage: string,
  userSteamCode: string,
  userSteamKey: string
) => {
  localStorage.setItem('auth_token', authToken)
  localStorage.setItem('auth_userSteamID', userSteamID)
  localStorage.setItem('auth_userSteamDisplayName', userSteamDisplayName)
  localStorage.setItem('auth_userSteamProfileImage', userSteamProfileImage)
  localStorage.setItem('auth_userTwitchID', userTwitchID)
  localStorage.setItem('auth_userTwitchDisplayName', userTwitchDisplayName)
  localStorage.setItem('auth_userTwitchProfileImage', userTwitchProfileImage)
  localStorage.setItem('auth_userSteamCode', userSteamCode)
  localStorage.setItem('auth_userSteamKey', userSteamKey)

  return {
    auth_token: authToken,
    auth_userSteamID: userSteamID,
    auth_userSteamDisplayName: userSteamDisplayName,
    auth_userSteamProfileImage: userSteamProfileImage,
    auth_userTwitchID: userTwitchID,
    auth_userTwitchDisplayName: userTwitchDisplayName,
    auth_userTwitchProfileImage: userTwitchProfileImage,
    auth_userSteamCode: userSteamCode,
    auth_userSteamKey: userSteamKey
  }
}

/**
 * Logout
 */
export function authLogout () {
  return async (dispatch: any) => {
    localStorage.removeItem('auth_token')
    localStorage.removeItem('auth_userSteamID')
    localStorage.removeItem('auth_userSteamDisplayName')
    localStorage.removeItem('auth_userSteamProfileImage')
    localStorage.removeItem('auth_userTwitchID')
    localStorage.removeItem('auth_userTwitchDisplayName')
    localStorage.removeItem('auth_userTwitchProfileImage')
    localStorage.removeItem('auth_userSteamCode')
    localStorage.removeItem('auth_userSteamKey')
    localStorage.removeItem('profile_isEmailVerified')
    localStorage.removeItem('profile_verificationToken')
    dispatch({ type: TYPES.AUTH_LOGOUT })
  }
}

/**
 * Login with Steam Sign In
 * @param googleToken
 * @param steamKeyCode
 * @returns {Promise<JSON>} Request result
 */
export function authLoginWithSteam (steamID: string, authToken: string) {
  return async (dispatch: any) => {
    // define request state as in progress
    dispatch({ type: TYPES.AUTH_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.AUTH_ERROR, error: json.error.DETAILS || 'Wrong request.' })
        return null
      }

      dispatch({
        type: TYPES.AUTH_LOGIN,
        payload: generateAuthStoragePayload(
          json.authToken,
          json.steamID,
          json.steamDisplayName || '',
          json.steamProfileImage || '',
          json.twitchID || '',
          json.twitchDisplayName || '',
          json.twitchProfileImage || '',
          json.steamKeyCode,
          json.steamKey)
      })

      return json
    }

    function onError (json: any) {
      dispatch({ type: TYPES.AUTH_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    try {
      const json = await API.httpRequest(API.URL.AUTH_LOGIN_WITH_STEAM, {
        method: 'post',
        headers: { 'Content-Type': 'application/json', Authorization: authToken },
        body: JSON.stringify({ steamID })
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}

export function authAssignSteamKeyCode (steamKeyCode: string) {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.AUTH_ACTION_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.AUTH_ACTION_IN_PROGRESS_RESET })
        return null
      }

      dispatch({
        type: TYPES.AUTH_LOGIN,
        payload: generateAuthStoragePayload(
          json.authToken,
          json.steamID,
          json.steamDisplayName || '',
          json.steamProfileImage || '',
          json.twitchID || '',
          json.twitchDisplayName || '',
          json.twitchProfileImage || '',
          json.steamKeyCode,
          json.steamKey)
      })

      return json
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onError (json: any) {
      dispatch({ type: TYPES.AUTH_ACTION_IN_PROGRESS_RESET })
      return null
    }

    try {
      const json = await API.httpRequest(API.URL.AUTH_ASSIGN_STEAM_CODE, {
        method: 'post',
        headers: { 'Content-Type': 'application/json', Authorization: auth.auth_token },
        body: JSON.stringify({ steamID: auth.auth_userSteamID, steamKeyCode: steamKeyCode })
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}

export function authLinkTwitchData (twitchRegistrationCode: string, twitchRedirectUrl: string) {
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { auth } = getState()

    // define request state as in progress
    dispatch({ type: TYPES.AUTH_ACTION_IN_PROGRESS })

    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.AUTH_ACTION_IN_PROGRESS_RESET })
        return null
      }

      dispatch({
        type: TYPES.AUTH_LOGIN,
        payload: generateAuthStoragePayload(
          json.authToken,
          json.steamID,
          json.steamDisplayName || '',
          json.steamProfileImage || '',
          json.twitchID || '',
          json.twitchDisplayName || '',
          json.twitchProfileImage || '',
          json.steamKeyCode,
          json.steamKey)
      })
      return json
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onError (json: any) {
      dispatch({ type: TYPES.AUTH_ACTION_IN_PROGRESS_RESET })
      return null
    }

    try {
      const json = await API.httpRequest(API.URL.AUTH_LINK_TWITCH_DATA, {
        method: 'post',
        headers: { 'Content-Type': 'application/json', Authorization: auth.auth_token },
        body: JSON.stringify({ steamID: auth.auth_userSteamID, twitchRegistrationCode, twitchRedirectUrl })
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
