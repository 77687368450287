import React from 'react'

import * as TYPES from './types'

const PaperContent: React.FC<TYPES.PaperContentProps> = (props: TYPES.PaperContentProps) => {
  const { className } = props
  return (
    <div className={'pg-paper-content ' + className}>
      {props.children}
    </div>
  )
}

export default PaperContent
