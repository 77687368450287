import React from 'react'

import { Spinner } from 'react-bootstrap'

import * as TYPES from './types'

const ProfileAvatar: React.FC<TYPES.ProfileAvatarProps> = (props: TYPES.ProfileAvatarProps) => {
  const { loading, avatar } = props
  return (
    <div className="profile-avatar">
      <div className="profile-avatar-content">
        {loading && <Spinner className="profile-avatar-spinner" as="span" animation="grow" role="status" aria-hidden="true" variant="light" />}
        {!loading && avatar && <img className="profile-avatar-icon" src={ TYPES.AvatarMap[(avatar in TYPES.AvatarMap) ? avatar : 'default'] } alt={avatar}/>}
      </div>
    </div>
  )
}

export default ProfileAvatar
