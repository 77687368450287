/**
 * Defines Profile State interface
 */
export interface ProfileState {
    // http state
    httpInProgress: boolean;
    httpError: string;
    // email verification
    profile_verificationToken: string;
    // profile server structure
    profile_isEmailVerified: boolean;
    profile_avatar: string;
    profile_credits: number;
    profile_xp: number;
    profile_rank: number;
    profile_selectedBadge: string;
    profile_badges: Array<string>;
    profile_steamKey: string;
    profile_personalCode: string;
    profile_is_ea: boolean;
    // stats
    profile_stats: any[];
  }

/**
  * Define Profile actions names
  */
export const PROFILE_IN_PROGRESS = 'PROFILE_IN_PROGRESS'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const PROFILE_EMPTY_SUCCESS = 'PROFILE_EMPTY_SUCCESS'
export const PROFILE_SET_VERIFICATION_TOKEN = 'PROFILE_SET_VERIFICATION_TOKEN'
export const PROFILE_GET = 'PROFILE_GET'
export const PROFILE_SEND_VERIFY_EMAIL = 'PROFILE_SEND_VERIFY_EMAIL'
export const PROFILE_SELECT_BADGE = 'PROFILE_SELECT_BADGE'
export const PROFILE_GET_STEAM_KEY = 'PROFILE_GET_STEAM_KEY'
export const PROFILE_GET_STATS = 'PROFILE_GET_STATS'

  /**
   * Defines Profile actions interfaces
   */
  interface ProfileInProgressAction {
    type: typeof PROFILE_IN_PROGRESS;
  }
  interface ProfileErrorAction {
    type: typeof PROFILE_ERROR;
    error: string;
  }
  interface ProfileEmptySuccessAction {
    type: typeof PROFILE_EMPTY_SUCCESS;
  }
  interface ProfileSetVerificationToken {
    type: typeof PROFILE_SET_VERIFICATION_TOKEN;
    token: string;
  }
  interface ProfileGetAction {
    type: typeof PROFILE_GET;
    payload: ProfileState;
  }
  interface ProfileSendVerifyEmail{
    type: typeof PROFILE_SEND_VERIFY_EMAIL;
  }
  interface ProfileSelectBadge{
    type: typeof PROFILE_SELECT_BADGE;
    payload: any;
  }
  interface ProfileGetSteamKey{
    type: typeof PROFILE_GET_STEAM_KEY;
    key: string;
  }
  interface ProfileGetStats{
    type: typeof PROFILE_GET_STATS;
    payload: any;
  }

/**
   * Defines all suported actions for Profile
   */
export type ProfileActionTypes = ProfileInProgressAction |
ProfileErrorAction |
ProfileEmptySuccessAction |
ProfileSetVerificationToken |
ProfileGetAction |
ProfileSendVerifyEmail |
ProfileSelectBadge |
ProfileGetSteamKey |
ProfileGetStats;
/**
 * Defines ProfileActionsInterface
 */
export interface ProfileActionsInterface {
  profileSetVerificationToken: (token: string) => Promise<JSON>;
  profileGet: () => Promise<JSON>;
  profileGetWithSocials: () => Promise<JSON>;
  profileGetWithSocialsAndSteamLink: (steamId: string) => Promise<JSON>;
  profileGetWithSocialsAndTwitchLink: (code: string, redirectUri: string) => Promise<JSON>;
  profileSendVerifyEmail: () => Promise<JSON>;
  profileSetIsEmailVerified: (json: any) => Promise<JSON>;
  profileVerifyEmail: () => Promise<JSON>;
  profileSelectBadge: (badge: string) => Promise<JSON>;
  profileGetSteamKey: () => Promise<JSON>;
  prfoileGetStats: () => Promise<JSON>;
}
