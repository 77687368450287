import React from 'react'
import { Container, Col, Row, Modal, Button } from 'react-bootstrap'

import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'

import { withRouter } from 'react-router-dom'

import logoMobile from '../../assets/landing/PGLogoNewMobile.png'
import logoDesktop from '../../assets/landing/PGLogoNewDesktop.jpg'

import { PaperContent, MountiansContent } from '../../Components/Contents'

import { Helmet } from 'react-helmet'

const Landing: React.FC<TYPES.LandingProps> = (props: TYPES.LandingProps) => {
  const { history, location } = props
  const isLoggedIn = localStorage.getItem('auth_userId')
  const isPlayTest = location.hash === '#playtest'

  // close Play Test Modal Function
  const closePlayTestPopup = () => { history.push(ROUTES.LANDING) }

  // render PlayTest Popup
  const renderPlayTestPopup = () => {
    return (
      <Modal className="playtest-modal" backdropClassName="landing" show={isPlayTest} onHide={closePlayTestPopup} centered={true}>
        <Modal.Header closeButton={true}></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} md={6} className="px-0 playtest-modal-video-col">
                <iframe src='https://www.youtube.com/embed/JpTjNbbgyMs'
                  frameBorder='0'
                  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen={true}
                  title='video'
                />
              </Col>
              <Col xs={12} md={6} className="playtest-modal-text-col">
                <h2 className="text-bold text-center">Join the Project Genesis</h2>
                <h2 className="text-bold text-center">Playtest</h2>
                <br />
                <h4 className="text-center">Calling all Pilots! Answer the call and secure your spot in our upcoming free playtest courtesy of Steam.</h4>
                <br />
                <Row>
                  <Col />
                  <Col xs={6} className="px-0">
                    <Button
                      block={true}
                      className="playtest-button text-bold text-center"
                      onClick={() => { window.open(' https://store.steampowered.com/app/700240/Project_Genesis/', '_blank'); closePlayTestPopup() }}
                    >
                      REQUEST ACCESS
                    </Button>
                  </Col>
                  <Col />
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    )
  }

  // render Landing
  const renderLanding = () => {
    return (
      <>
        <Container className="promo-buttons">
          <Row className="px-0 mx-0">
            <Col xs={1} md={3} className="px-0 mx-0" />
            <Col xs={10} md={6} className="px-0 mx-0">
              <iframe className="w-100" src="https://store.steampowered.com/widget/700240/" frameBorder="0" width="646" height="190" title="steam frame"></iframe>
            </Col>
            <Col xs={1} md={3} className="px-0 mx-0" />
          </Row>
        </Container>
        <MountiansContent hideMobile={true} className="promo-video">
          <Container id='trailer'>
            <Row>
              <Col xs={1} className="d-none d-md-block"></Col>
              <Col xs={12} md={10} className="text-center pl-auto px-mx-0 pg-text">
                {/* <ScrollableAnchor id='trailer'><h2> </h2></ScrollableAnchor> */}
                <iframe src='https://www.youtube.com/embed/Ah88S9iVeLU'
                  frameBorder='0'
                  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen={true}
                  title='video'
                />
              </Col>
              <Col xs={1} className="d-none d-md-block"></Col>
            </Row>
          </Container>
        </MountiansContent>
      </>
    )
  }

  const renderPromoCodeLink = () => {
    return (
      <Container className="click-promo-code">
        <Row>
          <Col></Col>
          <Col xs={8} md={4} className="bordered-col">
            <a href={ROUTES.AUTH} className="text-center text-bold">
              <span className="up-row text-bold">HAVE A PROMO CODE?</span>
              <br /><span className="down-row text-bold">CLICK HERE</span>
            </a>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    )
  }

  /**
   * Render
   */
  return (
    <PaperContent className="landing">
      <Helmet>
        <title>Project Genesis</title>
        <meta name="description" content="Project Genesis home page featuring links to Steam and to Alpha playtest application, game trailer, screenshots and links to social media channels." />
      </Helmet>
      {!isPlayTest && (<img className="d-md-none logo" alt="Mobile Project Genesis Logo" src={logoMobile} />)}
      {!isPlayTest && (<img className="d-none d-md-block logo" alt="Desktop Project Genesis Logo" src={logoDesktop} />)}
      {isPlayTest && (<div className="logo-block"></div>)}
      {renderPlayTestPopup()}
      {!isPlayTest && renderLanding()}
    </PaperContent>
  )
}

export default withRouter(Landing)
