import React from 'react'

import * as TYPES from './types'

import { withRedux } from '../../Redux/'
import { withRouter } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import PublicProfilePage from './PublicProfilePage'
import { LoadingPage, AbsentPage } from '../404'

class PublicProfile extends React.Component<TYPES.PublicProfileProps, TYPES.PublicProfileState> {
  state: Readonly<TYPES.PublicProfileState> = {
    userName: '',
    displayName: '',
    data: null,
    stats: [],
    level: 1,
    isAbsent: false
  };

  // start profile request on page initialized
  componentDidMount () {
    // get needed props
    const { getPublicProfile, getUserStats, match } = this.props
    // get url params
    const { params } = match
    const { userName } = params as TYPES.UrlProps

    getPublicProfile(userName).then((data: any) => {
      if (!data) this.setState({ userName, data: {}, stats: [], level: 0, isAbsent: true })
      else {
        getUserStats(data.userName).then((json: any) => {
          // response blocked by CORS on localhost
          if (!json) this.setState({ userName, data: {}, stats: [], level: 0, isAbsent: true })
          else this.setState({ userName, displayName: data.displayName, data, stats: json.leaderboards, level: json.playerlevel })
        })
      }
    })
  }

  /**
   * Render
   */
  render () {
    return (
      <div className="public-profile">
        <Helmet>
          <title>Project Genesis | Profile {this.state.displayName ? '| ' + this.state.displayName : ''}</title>
          <meta name="description" content={'Project Genesis ' + this.state.displayName + ' public profile page'} />
        </Helmet>
        {(!this.state || !this.state.data) && <LoadingPage/>}
        {this.state && this.state.isAbsent && <AbsentPage/>}
        {this.state && !this.state.isAbsent && this.state.data && <PublicProfilePage level={this.state.level} data={this.state.data} stats={this.state.stats}/> }
      </div>
    )
  }
}

export default withRedux(withRouter(PublicProfile))
