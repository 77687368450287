import React from 'react'

import * as TYPES from './types'

const GrayContent: React.FC<TYPES.GrayContentProps> = (props: TYPES.GrayContentProps) => {
  const { className } = props
  return (
    <div className={'pg-dark-blue-content ' + className}>
      {props.children}
    </div>
  )
}

export default GrayContent
