import React from 'react'

import { Container, Col, Row, Spinner } from 'react-bootstrap'

import { withRedux } from '../../../Redux'
import { useWindowDimensions } from '../../../Utils'

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import * as TYPES from './types'

const Board: React.FC<TYPES.TrelloProps> = (props: TYPES.TrelloProps) => {
  // check window dimensions
  const { isMobile, isDesktopStatic } = useWindowDimensions()
  // get trello redux state objects
  const { httpInProgress, httpError, trello_lists, trello_cards } = props.trello

  // render colmn of cards
  const renderCards = (listID: string) => {
    if (!(listID in trello_cards)) return null
    return trello_cards[listID].map((item: string, index: number) => (
      <div key={'card_' + listID + index} className="trello-card">
        <div className="card-title">{item}</div>
      </div>
    ))
  }

  // render board of lists
  const renderBoard = () => {
    return trello_lists.map((item: Record<string, any>) => (
      <div key={'list_' + item.id} className="trello-list">
        <div className="list-title text-bold">{item.name}</div>
        <div className='cards-container'>
          {httpInProgress && !(item.id in trello_cards) && (<Spinner animation="grow" variant="dark"/>)}
          {renderCards(item.id)}
        </div>
      </div>
    ))
  }

  // define the trello container width (based on scss styles)
  const trelloContainerWidth = isMobile
    ? (trello_lists.length * 91.6 + 'vw')
    : (
      isDesktopStatic
        ? (trello_lists.length * 255 + 'px')
        : (trello_lists.length * 20.7 + 'vw')
    )
  // define scroll Overlay Options
  const overlayOptions: OverlayScrollbars.Options = {
    resize: 'none',
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
      clickScrolling: true
    }
  }

  // render
  return (
    <Container>
      <Row>
        <Col xs={12} className="text-center">
          <OverlayScrollbarsComponent options={overlayOptions} className="trello">
            { httpInProgress && !trello_lists.length && (<Spinner animation="grow" variant="dark"/>) }
            <div className="trello-container" style={{ width: trelloContainerWidth }}>
              { httpInProgress && !!trello_lists.length && renderBoard() }
            </div>
          </OverlayScrollbarsComponent>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center pg-text">
          <h4>{ httpError }</h4>
        </Col>
      </Row>
    </Container>
  )
}

export default withRedux(Board)
