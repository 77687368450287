import React from 'react'
import { Container, Col, Row, Form, Button, Spinner, InputGroup } from 'react-bootstrap'

import * as TYPES from './types'
import { withRedux } from '../../Redux'
import { withWindowDimensions } from '../../Utils'

import { PaperContent } from '../../Components/Contents'
import Separator from '../../Components/Separator'

import btnEdit from '../../assets/account/BtnEdit.png'
import btnEditHover from '../../assets/account/BtnEditHover.png'
import btnEditActive from '../../assets/account/BtnEditActive.png'
import btnEditMobile from '../../assets/account/BtnEditMobile.png'

import btnCancel from '../../assets/account/BtnCancel.png'
import btnCancelHover from '../../assets/account/BtnCancelHover.png'
import btnCancelActive from '../../assets/account/BtnCancelActive.png'
import btnCancelMobile from '../../assets/account/BtnCancelMobile.png'

import btnSave from '../../assets/account/BtnSave.png'
import btnSaveHover from '../../assets/account/BtnSaveHover.png'
import btnSaveActive from '../../assets/account/BtnSaveActive.png'
import btnSaveMobile from '../../assets/account/BtnSaveMobile.png'

class Account extends React.Component<TYPES.AccountProps, TYPES.AccountState> {
  state: Readonly<TYPES.AccountState> = {
    passwordValidated: false,
    password: '',
    displayNameEditable: false,
    displayNameValidated: false,
    displayName: '',
    displayNameError: '',
    editBtnSrc: btnEdit,
    saveBtnSrc: btnSave,
    cancelBtnSrc: btnCancel
  };

  /**
   * Overwrite from the base React.Component
   */
  componentDidMount () {
    // get constants from props
    const { accountGet } = this.props

    // update user profile data/steam key and stats
    accountGet()
  }

  /**
   * Render()
   */
  render () {
    // get state params from Redux & WindowDimensions
    const { isMobile, account, accountSetNewDisplayName, accountSetNewPassword } = this.props
    const {
      httpInProgress,
      httpError,
      account_displayName,
      account_email
    } = account

    /**
     * Handle cancel button click for Display Name field
     */
    const handleDisplayNameCancel = () => {
      this.setState({ displayName: '', displayNameError: '', displayNameEditable: false, displayNameValidated: false, cancelBtnSrc: btnCancel })
    }

    /**
     * Handle cancel button click for Password field
     */
    const handlePasswordCancel = () => {
      this.setState({ password: '', passwordValidated: false, cancelBtnSrc: btnCancel })
    }

    /**
    * Handle Password field Form submit
    * @param event - submit form event
    */
    const handlePasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // get form element
      const form = event.currentTarget
      // check if form is valid
      if (form.checkValidity()) {
      // disable validation errors or approves feedback
        this.setState({ passwordValidated: false, saveBtnSrc: btnSave })
        // request registration
        accountSetNewPassword(this.state.password)
      } else this.setState({ passwordValidated: true }) // show validation errors or approves feedback
      // disable default submit event
      event.preventDefault()
      event.stopPropagation()
    }

    /**
     * Handle Display Name field Form Submit
     * @param event - submit form event
     */
    const handleDisplayNameSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      // get form element
      const form = event.currentTarget
      // check if form is valid
      if (form.checkValidity()) {
        // disable validation errors or approves feedback
        this.setState({ displayNameValidated: false, saveBtnSrc: btnSave })
        // request Display Name Update
        accountSetNewDisplayName(this.state.displayName)
          .then((json: any) => {
            // displayName change error
            if (json.error) this.setState({ displayName: account_displayName, displayNameError: (json.error.length ? json.error : httpError), displayNameEditable: false, displayNameValidated: false })
            else handleDisplayNameCancel()
          })
      } else this.setState({ displayNameValidated: true }) // show validation errors or approves feedback

      // disable default submit event
      event.preventDefault()
      event.stopPropagation()
    }

    /**
     * Render
     */
    return (
      <PaperContent className="account">
        <Container>
          <Row>
            <Col className="px-0"><h1 className="text-bold text-center">USER ACCOUNT</h1></Col>
          </Row>
          <Row>
            <Col xs={1} className="d-block d-md-none"/><Col className="d-none d-md-block"/>
            <Col xs={10} md={7} className="account-form px-0 mx-0">
              <Row className="account-form-header w-100 mx-0">
                <Col className="px-0"><h2 className="text-bold text-black">ACCOUNT INFORMATION</h2></Col>
              </Row>
              <Separator type="white-50t"/>
              <Row className="account-form-content-row">
                <Col xs={3} className="px-0 py-0"><h3>Username</h3></Col>
                <Col xs={9} className="px-0 py-0">
                  {this.state.displayNameEditable && (
                    <Form noValidate={true} validated={this.state.displayNameValidated} onSubmit={handleDisplayNameSubmit}>
                      <Form.Row>
                        <Col xs={10} md={10}>
                          <InputGroup>
                            <Form.Control
                              type='text'
                              placeholder="New Username"
                              required={true}
                              minLength={3}
                              disabled={httpInProgress}
                              className="account-form-input"
                              onChange={(event: React.FormEvent<any>) => { this.setState({ displayName: (event.currentTarget as any).value }); handlePasswordCancel() }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid"> Please provide at least 3 symbols. </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                        <Col xs={2} md={2} className="pl-3 pr-2 pl-md-2 pr-md-4">
                          {!httpInProgress && !!this.state.displayName.length && (
                            <Row className="py-0 my-0">
                              <Col xs={5} md={12} className="px-0 mx-0 px-md-2 mx-md-2 py-0 my-0">
                                <Button
                                  type="submit"
                                  block={true}
                                  disabled={httpInProgress}
                                  className="account-form-save-button"
                                >
                                  {!httpInProgress && (
                                    <img
                                      src={isMobile ? btnSaveMobile : this.state.saveBtnSrc}
                                      alt="save display name icon"
                                      onMouseOut={() => this.setState({ saveBtnSrc: btnSave })}
                                      onMouseEnter={() => this.setState({ saveBtnSrc: btnSaveHover })}
                                      onMouseDown={() => this.setState({ saveBtnSrc: btnSaveActive })}
                                    />
                                  )}
                                </Button>
                              </Col>
                              <Col xs={5} md={12} className="px-0 ml-1 mr-0 px-md-2 ml-md-2 mr-md-2 py-0 my-0">
                                <Button
                                  block={true}
                                  disabled={httpInProgress}
                                  className="account-form-cancel-button"
                                  onClick={() => handleDisplayNameCancel()}
                                >
                                  {!httpInProgress && (
                                    <img
                                      src={isMobile ? btnCancelMobile : this.state.cancelBtnSrc}
                                      alt="cancel save display name icon"
                                      onMouseOut={() => this.setState({ cancelBtnSrc: btnCancel })}
                                      onMouseEnter={() => this.setState({ cancelBtnSrc: btnCancelHover })}
                                      onMouseDown={() => this.setState({ cancelBtnSrc: btnCancelActive })}
                                    />
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Form.Row>
                    </Form>
                  )}
                  {!this.state.displayNameEditable && (
                    <Form noValidate={true} >
                      <Form.Row>
                        <Col xs={11} md={10} className="py-0">
                          {!httpInProgress && (<h3 className="text-bold">{account_displayName}</h3>)}
                          {httpInProgress && (<Spinner as="span" animation="border" size={isMobile ? 'sm' : undefined} role="status" aria-hidden="true" />)}
                        </Col>
                        <Col xs={1} md={2} className="px-0 pl-md-2 pr-md-1 py-0">
                          {!httpInProgress && (
                            <Button
                              block={true}
                              disabled={httpInProgress}
                              className="account-form-edit-button"
                              onClick={() => { this.setState({ displayNameEditable: true, editBtnSrc: btnEdit }) }}
                            >
                              {!httpInProgress && (
                                <img
                                  src={isMobile ? btnEditMobile : this.state.editBtnSrc}
                                  alt="edit display name icon"
                                  onMouseOut={() => this.setState({ editBtnSrc: btnEdit })}
                                  onMouseEnter={() => this.setState({ editBtnSrc: btnEditHover })}
                                  onMouseDown={() => this.setState({ editBtnSrc: btnEditActive })}
                                />
                              )}
                            </Button>
                          )}
                        </Col>
                      </Form.Row>
                      {!!this.state.displayNameError.length && (
                        <Form.Row>
                          <Col className="display-name-error">
                            {this.state.displayNameError}
                          </Col>
                        </Form.Row>
                      )}
                    </Form>
                  )}
                </Col>
              </Row>

              <Row className="account-form-content-row border-content">
                <Col xs={3} className="px-0"><h3>Email</h3></Col>
                <Col xs={9} className="px-0">
                  {(!account_email.length || httpInProgress) && (<Spinner as="span" animation="border" size={isMobile ? 'sm' : undefined} role="status" aria-hidden="true" />)}
                  {!!account_email.length && !httpInProgress && (<h3 className="text-bold">{account_email}</h3>)}
                </Col>
              </Row>

              <Row className="account-form-content-row">
                <Col xs={3} className="px-0"><h3>{isMobile ? 'Password' : 'Reset Password'}</h3></Col>
                <Col xs={9} className="px-0">
                  <Form noValidate={true} validated={this.state.passwordValidated} onSubmit={handlePasswordSubmit}>
                    <Form.Row>
                      <Col xs={10} md={10}>
                        <InputGroup>
                          <Form.Control
                            type='password'
                            placeholder="New Password"
                            required={true}
                            minLength={6}
                            disabled={httpInProgress}
                            value={this.state.password}
                            className="account-form-input"
                            onChange={(event: React.FormEvent<any>) => { this.setState({ password: (event.currentTarget as any).value }); handleDisplayNameCancel() }}
                          />
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> Please provide at least 6 symbols. </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                      <Col xs={2} md={2} className="pl-3 pr-2 pl-md-2 pr-md-4">
                        {!httpInProgress && !!this.state.password.length && (
                          <Row className="py-0 my-0">
                            <Col xs={5} md={12} className="px-0 mx-0 px-md-2 mx-md-2 py-0 my-0">
                              <Button
                                type="submit"
                                block={true}
                                disabled={httpInProgress || !this.state.password.length}
                                className="account-form-save-button"
                              >
                                {!httpInProgress && (
                                  <img
                                    src={isMobile ? btnSaveMobile : this.state.saveBtnSrc}
                                    alt="save password icon"
                                    onMouseOut={() => this.setState({ saveBtnSrc: btnSave })}
                                    onMouseEnter={() => this.setState({ saveBtnSrc: btnSaveHover })}
                                    onMouseDown={() => this.setState({ saveBtnSrc: btnSaveActive })}
                                  />
                                )}
                              </Button>
                            </Col>
                            <Col xs={5} md={12} className="px-0 ml-1 mr-0 px-md-2 ml-md-2 mr-md-2 py-0 my-0">
                              <Button
                                block={true}
                                disabled={httpInProgress}
                                className="account-form-cancel-button"
                                onClick={() => handlePasswordCancel()}
                              >
                                {!httpInProgress && (
                                  <img
                                    src={isMobile ? btnCancelMobile : this.state.cancelBtnSrc}
                                    alt="cancel save password icon"
                                    onMouseOut={() => this.setState({ cancelBtnSrc: btnCancel })}
                                    onMouseEnter={() => this.setState({ cancelBtnSrc: btnCancelHover })}
                                    onMouseDown={() => this.setState({ cancelBtnSrc: btnCancelActive })}
                                  />
                                )}
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Form.Row>
                  </Form>
                </Col>
              </Row>

            </Col>
            <Col className="d-none d-md-block"/><Col xs={1} className="d-block d-md-none"/>
          </Row>
        </Container>
      </PaperContent>
    )
  }
}

export default withRedux(withWindowDimensions(Account))
