export interface BadgeProps extends React.DetailedHTMLProps<React.BaseHTMLAttributes<HTMLBaseElement>, HTMLBaseElement>{
    loading?: boolean;
    badge?: string;
    locked?: boolean;
    onClick?: () => void;
    badgeClass?: string;
}

export const BadgeMap: Record<string, any> = {
  default_lvl1: require('../../assets/profile/badges/badge-default.png'),
  '8circuit_lvl1': require('../../assets/profile/badges/badge-8cs.png'),
  '8circuit_lvl2': require('../../assets/profile/badges/badge-8cs.png'),
  '8circuit_lvl3': require('../../assets/profile/badges/badge-8cs.png'),
  '8circuit_lvl4': require('../../assets/profile/badges/badge-8cs.png'),
  '8circuit_lvl5': require('../../assets/profile/badges/badge-8cs.png'),
  founder_lvl1: require('../../assets/profile/badges/badge-founder.png'),
  founder_lvl2: require('../../assets/profile/badges/badge-founder.png'),
  founder_lvl3: require('../../assets/profile/badges/badge-founder.png'),
  founder_lvl4: require('../../assets/profile/badges/badge-founder.png'),
  founder_lvl5: require('../../assets/profile/badges/badge-founder.png'),
  acepilot_lvl1: require('../../assets/profile/badges/badge-ace-1.png'),
  acepilot_lvl2: require('../../assets/profile/badges/badge-ace-2.png'),
  acepilot_lvl3: require('../../assets/profile/badges/badge-ace-3.png'),
  acepilot_lvl4: require('../../assets/profile/badges/badge-ace-4.png'),
  acepilot_lvl5: require('../../assets/profile/badges/badge-ace-5.png'),
  pioneer_lvl1: require('../../assets/profile/badges/badge-creator-1.png'),
  pioneer_lvl2: require('../../assets/profile/badges/badge-creator-2.png'),
  pioneer_lvl3: require('../../assets/profile/badges/badge-creator-3.png'),
  pioneer_lvl4: require('../../assets/profile/badges/badge-creator-4.png'),
  pioneer_lvl5: require('../../assets/profile/badges/badge-creator-5.png'),
  ambassador_lvl1: require('../../assets/profile/badges/badge-ambassador-1.png'),
  ambassador_lvl2: require('../../assets/profile/badges/badge-ambassador-2.png'),
  ambassador_lvl3: require('../../assets/profile/badges/badge-ambassador-3.png'),
  ambassador_lvl4: require('../../assets/profile/badges/badge-ambassador-4.png'),
  ambassador_lvl5: require('../../assets/profile/badges/badge-ambassador-5.png'),
  testpilot_lvl1: require('../../assets/profile/badges/badge-pilot-1.png'),
  testpilot_lvl2: require('../../assets/profile/badges/badge-pilot-2.png'),
  testpilot_lvl3: require('../../assets/profile/badges/badge-pilot-3.png'),
  testpilot_lvl4: require('../../assets/profile/badges/badge-pilot-4.png'),
  testpilot_lvl5: require('../../assets/profile/badges/badge-pilot-5.png'),
  anubis_lvl1: require('../../assets/profile/badges/anubis.png'),
  skull_lvl1: require('../../assets/profile/badges/skull.png'),
  halloween_1_lvl1: require('../../assets/profile/badges/halloween-1.png'),
  halloween_2_lvl1: require('../../assets/profile/badges/halloween-2.png'),
  halloween_3_lvl1: require('../../assets/profile/badges/halloween-3.png'),
  halloween_4_lvl1: require('../../assets/profile/badges/halloween-4.png')
}

export const BadgeMaxLevel: Record<string, number> = {
  '8circuit': 1,
  acepilot: 5,
  pioneer: 5,
  testpilot: 5,
  ambassador: 5,
  anubis: 1,
  skull: 1
  // halloween_1: 1,
  // halloween_2: 1,
  // halloween_3: 1,
  // halloween_4: 1
}
