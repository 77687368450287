import React from 'react'
// import { Container, Col, Row } from 'react-bootstrap'

import * as TYPES from './types'

import { PaperContent } from '../../../Components/Contents'

import ProfileHeader from './ProfileHeader'
import ProfileStats from '../../../Components/ProfileStats'
import Up from '../../../Components/Up'

const PublicProfilePage: React.FC<TYPES.PublicProfilePageProps> = (props: TYPES.PublicProfilePageProps) => {
  const { data, stats, level } = props
  const { userSelectedBadge } = data
  /**
   * Render
   */
  return (
    <PaperContent>
      <ProfileHeader displayName={data.displayName} userSelectedBadge={userSelectedBadge} level={level} />
      <ProfileStats stats={stats}/>
      <Up/>
    </PaperContent>
  )
}

export default PublicProfilePage
