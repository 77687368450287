import React from 'react'

import { Container, Col, Row, Form, Button, Spinner } from 'react-bootstrap'

import SearchList from './SearchList'

import { withRedux } from '../../Redux/'
import * as TYPES from './types'
import { Helmet } from 'react-helmet'

const Search: React.FC<TYPES.SearchProps> = (props: TYPES.SearchProps) => {
  // get needed props
  const { searchUsers, search } = props
  /**
   * define state if it is needed to present validated results
   * (activate <Form.Control.Feedback> Components)
   */
  const [validated, setValidated] = React.useState(false)
  /**
   * fields states
   */
  const [searchString, setSearchString] = React.useState('')
  /**
   * Form Submit Handler
   * @param {React.FormEvent<HTMLFormElement>} event - Submit Form Event
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // get form element
    const form = event.currentTarget
    // check if form is valid
    if (form.checkValidity()) {
      // disable validation errors or approves feedback
      setValidated(false)
      // request Search reset
      searchUsers(searchString)
    } else setValidated(true) // show validation errors or approves feedback
    // disable default submit event
    event.preventDefault()
    event.stopPropagation()
  }
  /**
   * Render
   */
  return (
    <div className="search">
      <div className="pg-search-content">
        <Helmet>
          <title>Project Genesis | Search</title>
          <meta name="description" content="Search Player Public Profiles" />
        </Helmet>
        <Container>
          <Row>
            <Col className="text-center">
              <h1 className="text-bold search-title">&#8725;&#8725; PLAYER
                <br className="d-md-none"/> SEARCH</h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <p className="text-center search-paragraph">
              Please check if you have entered the&nbsp;
                <br className="d-md-none"/>correct username.&nbsp;
                <br className="d-none d-md-block"/>Special characters&nbsp;
                <br className="d-md-none"/>and symbols are allowed.&nbsp;
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={0} md={3}></Col>
            <Col xs={0} md={1} className="d-none d-md-block pr-0 mr-0">
              <div className="div-divider-hr-white"></div>
            </Col>
            <Col>
              <Form noValidate={true} validated={validated} onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="search-input">
                    <Form.Control
                      type="text"
                      minLength={1}
                      required={true}
                      value={searchString}
                      onChange={(event: React.FormEvent<any>) => setSearchString((event.currentTarget as any).value)}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Col/>
                  <Col xs={6} className="px-0">
                    <Button
                      type="submit"
                      block={true}
                      disabled={search.httpInProgress}
                      className="search-button text-bold text-center"
                    >
                  SEARCH
                    </Button>
                  </Col>
                  <Col/>
                </Form.Row>
              </Form>
            </Col>
            <Col xs={0} md={1} className="d-none d-md-block pl-0 ml-0">
              <div className="div-divider-hr-white"></div>
            </Col>
            <Col xs={0} md={3}></Col>
          </Row>
          {!search.httpInProgress && (search.isFirstSearch || !!search.search_data.length) && (<Row><Col className="search-padding-bottom"></Col></Row>)}
          {!search.httpInProgress && !search.isFirstSearch && !search.search_data.length && (
            <div className="search-loading">
              <Row>
                <Col className="text-center">
                  <h2 className="loading-text text-bold">
                    WE FOUND NO PLAYERS BY THAT&nbsp;
                    <br className="d-block d-md-none"/>NAME. PLEASE CHECK TO&nbsp;
                    <br className="d-none d-md-block"/>MAKE&nbsp;
                    <br className="d-block d-md-none"/>SURE YOU’VE ENTERED THE&nbsp;
                    <br className="d-block d-md-none"/>ACCOUNT NAME PRECISELY.</h2>
                </Col>
              </Row>
            </div>
          )}
          {search.httpInProgress && (
            <div className="search-loading">
              <Row><Col className="text-center"><h2 className="loading-text text-bold">LOADING RESULTS</h2></Col></Row>
              <Row><Col className="text-center"><Spinner as="span" animation="border" role="status" aria-hidden="true" variant="light" /></Col></Row>
            </div>
          )}
        </Container>
      </div>
      <SearchList/>
    </div>
  )
}

export default withRedux(Search)
