import * as TYPES from './types'
import * as API from '../api'

// import { prfoileGetStats } from '../profile/actions'

/**
 * Get Leaderboards List
 */
export function leaderboardsList () {
  return async (dispatch: any) => {
    // success
    function onSuccess (json: any) {
      if (json.error) {
        dispatch({ type: TYPES.LEADERBOARDS_ERROR, error: json.error.DETAILS || 'wrong request' })
        return null
      }

      // temporary solution to exclude TotalWins leaderboards & stats.
      if (json.leaderboards) {
        json.leaderboards.forEach((item: any, index: number) => {
          if (item.shortCode === 'totalwins') json.leaderboards.splice(index, 1)
        })
      }

      dispatch({
        type: TYPES.LEADERBOARDS_LIST,
        payload: {
          leaderboards_list: json.leaderboards || []
        }
      })

      return json
    }
    // error
    function onError (json: any) {
      dispatch({
        type: TYPES.LEADERBOARDS_LIST,
        payload: { leaderboards_list: [] }
      })
      dispatch({ type: TYPES.LEADERBOARDS_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
      return null
    }

    try {
      const json = await API.httpRequest(API.URL.LEADERBOARDS_LIST, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
      })
      return onSuccess(json)
    } catch (error) {
      return onError(error)
    }
  }
}
/**
 * Get Leaderboards Data
 */
export function leaderboardsGet (type: string) {
  // request function
  const leaderboardsRequestBoard = (type: string) => {
    return async (dispatch: any, getState: any) => {
      // get other reducers from Redux
      const { auth } = getState()
      // define request state as in progress
      dispatch({ type: TYPES.LEADERBOARDS_IN_PROGRESS })

      // success
      function onSuccess (json: any) {
        if (json.error) {
          dispatch({ type: TYPES.LEADERBOARDS_ERROR, error: json.error.DETAILS || 'wrong request' })
          return null
        }

        dispatch({
          type: TYPES.LEADERBOARDS_GET,
          payload: {
            leaderboards_data: json.map((item: any) => {
              item.value = item[type]
              if (item.badge && (item.badge as string).includes('b_')) item.badge = (item.badge as string).substring(2)
              item.badge += '_lvl1'
              return item
            })
              .filter((item: any, index: number) => {
                // add authenticated user if this one is in a leaderboard and out of first 50 players
                if (index > 49 && item.userName !== auth.auth_userName) return false
                return true
              })
              .filter((item: any) => {
              // cut off all whose value is 0
                return !!item.value || (item.userName === auth.auth_userName)
              })
          }
        })

        // request profile stats if auth user isn't in the list
        // if (auth.auth_isLoggedIn && !json.data.find((item: any) => item.userId === auth.auth_userId)) {
        //   dispatch(leaderboardsAddAuthUser(type))
        // }

        return json
      }

      // error
      function onError (json: any) {
        dispatch({ type: TYPES.LEADERBOARDS_ERROR, error: 'server connection error: ' + JSON.stringify(json) })
        return null
      }

      try {
        const json = await API.httpRequest(API.URL.LEADERBOARDS_BASE + type, {
          method: 'get',
          headers: { 'Content-Type': 'application/json' }
        })
        return onSuccess(json)
      } catch (error) {
        return onError(error)
      }
    }
  }
  return async (dispatch: any, getState: any) => {
    // get other reducers from Redux
    const { leaderboards } = getState()
    // define request state as in progress
    dispatch({ type: TYPES.LEADERBOARDS_IN_PROGRESS })

    // request a leaderboard list if it is empty
    if (!leaderboards.leaderboards_list.length) {
      return dispatch(leaderboardsList()).then((json: any) => {
        if (!json) return dispatch({ type: TYPES.LEADERBOARDS_IN_PROGRESS })
        // request a leaderboard
        return dispatch(leaderboardsRequestBoard(type || json.leaderboards[0].shortCode))
      })
    // request a leaderboard
    } else {
      return dispatch(leaderboardsRequestBoard(type || leaderboards.leaderboards_list[0].shortCode))
    }
  }
}
