/**
 * Treelo State Interface
 * */
export interface TrelloState {
    /* api status */
    httpInProgress: boolean;
    httpError: string;
    /* trello data */
    trello_lists: Array<any>;
    trello_cards: Record<string, any>;
}

/**
 * Actions
 */
export const TRELLO_IN_PROGRESS = 'TRELLO_IN_PROGRESS'
export const TRELLO_ERROR = 'TRELLO_ERROR'
export const TRELLO_SET_LISTS = 'TRELLO_SET_LISTS'
export const TRELLO_SET_CARDS = 'TRELLO_SET_CARDS'

/**
 * Action Interfaces
 */
interface TrelloInProgressAction {
    type: typeof TRELLO_IN_PROGRESS;
}
interface TrelloErrorAction {
    type: typeof TRELLO_ERROR;
    error: string;
}
interface TrelloSetListsAction {
    type: typeof TRELLO_SET_LISTS;
    trello_lists: Array<any>;
}
interface TrelloSetCardsAction {
    type: typeof TRELLO_SET_CARDS;
    listID: string;
    trello_cards: Array<string>;
}

/**
 * Export all action interfaces
 */
export type TrelloActionTypes = TrelloInProgressAction | TrelloErrorAction | TrelloSetListsAction | TrelloSetCardsAction;

/**
 * Export Actions Interface
 */
export interface TrelloActionsInterface {
    trelloGetBoard: () => void;
    trelloGetListCards: (listID: string) => Promise<JSON>;
}
