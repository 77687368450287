import React from 'react'

import * as TYPES from './types'

const Separator: React.FC<TYPES.SeparatorProps> = (props: TYPES.SeparatorProps) => {
  return (
    <div className={props.type ? 'div-separator-' + props.type : 'div-separator-default'}></div>
  )
}

export default Separator
