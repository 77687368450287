import * as TYPES from './types'

// define initial state object
const initialState: TYPES.ProfileState = {
  httpInProgress: false,
  httpError: '',
  profile_verificationToken: localStorage.getItem('profile_verificationToken') || '',
  profile_isEmailVerified: localStorage.getItem('profile_isEmailVerified') === 'true' || false,
  profile_avatar: '',
  profile_credits: 0,
  profile_xp: 0,
  profile_rank: 0,
  profile_selectedBadge: '',
  profile_badges: [],
  profile_steamKey: '',
  profile_personalCode: '',
  profile_is_ea: false,
  profile_stats: []
}

// define profile reducer
export function profileReducer (
  state = initialState,
  action: TYPES.ProfileActionTypes
): TYPES.ProfileState {
  switch (action.type) {
    case TYPES.PROFILE_IN_PROGRESS: {
      return {
        ...state,
        ...{ httpInProgress: true, httpError: '' }
      }
    }
    case TYPES.PROFILE_ERROR: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: action.error }
      }
    }
    case TYPES.PROFILE_EMPTY_SUCCESS: {
      return {
        ...state,
        ...{ httpInProgress: false, httpError: '' }
      }
    }
    case TYPES.PROFILE_SET_VERIFICATION_TOKEN: {
      return {
        ...state,
        ...{ profile_verificationToken: action.token }
      }
    }
    case TYPES.PROFILE_GET: {
      return {
        ...state,
        ...action.payload,
        ...{ httpInProgress: false, httpError: '' }
      }
    }
    case TYPES.PROFILE_SELECT_BADGE: {
      return {
        ...state,
        ...action.payload
      }
    }
    case TYPES.PROFILE_GET_STEAM_KEY: {
      return {
        ...state,
        ...{ profile_steamKey: action.key, profile_personalCode: action.key }
      }
    }
    case TYPES.PROFILE_GET_STATS: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}
