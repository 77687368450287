import * as TYPES from './types'
import * as API from '../api'

/**
 * Send ContactUs message
 * @param email {string} -> Feedback email
 * @param userName {string} -> Feedback user Name
 * @param message {string} -> Message
 */
export function contactSendEmail (email: string, userName: string, message: string) {
  return async (dispatch: any) => {
    dispatch({ type: TYPES.CONTACT_IN_PROGRESS })

    const body = {
      email,
      subject: userName,
      contents: message
    }

    try {
      await API.httpRequest(API.URL.CONTACT_SEND_EMAIL, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })

      return dispatch({ type: TYPES.CONTACT_SUCCESS })
    } catch (error) {
      if (error && Object.keys(error).length) return dispatch({ type: TYPES.CONTACT_ERROR, error: JSON.stringify(error) })
      else return dispatch({ type: TYPES.CONTACT_IN_PROGRESS })
    }
  }
}
