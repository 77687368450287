import React from 'react'

import { withRedux } from '../../../Redux'

import * as TYPES from './types'

import Board from './Board'

class Trello extends React.Component<TYPES.TrelloProps, {}> {
  componentDidMount () {
    this.props.trelloGetBoard()
  }

  render () { return (<Board/>) }
}

export default withRedux(Trello)
