/**
 * Leaderboards State Interface
 * */
export interface SearchState {
    /* api status */
    httpInProgress: boolean;
    httpError: string;
    /* search data */
    search_data: Array<any>;
    isFirstSearch: boolean;
}

/**
 * Actions
 */
export const SEARCH_IN_PROGRESS = 'SEARCH_IN_PROGRESS'
export const SEARCH_ERROR = 'SEARCH_ERROR'
export const SEARCH_USERS = 'SEARCH_USERS'

/**
 * Action Interfaces
 */
interface SearchInProgressAction {
    type: typeof SEARCH_IN_PROGRESS;
}
interface SearchErrorAction {
    type: typeof SEARCH_ERROR;
    error: string;
}
interface SearchUsersAction {
    type: typeof SEARCH_USERS;
    search_data: any;
}

/**
 * Export all action interfaces
 */
export type SearchActionTypes = SearchInProgressAction | SearchErrorAction | SearchUsersAction;

/**
 * Export Actions Interface
 */
export interface SearchActionsInterface {
    searchUsers: (searchString: string) => void;
    getPublicProfile: (profileUserName: string) => Promise<JSON>;
    getUserStats: (userName: string) => Promise<JSON>;
}
