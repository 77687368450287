import React from 'react'

import { Container, Col, Row, Dropdown, Spinner } from 'react-bootstrap'

import { withRedux } from '../../Redux/'
import { withRouter } from 'react-router-dom'

import * as TYPES from './types'
import * as ROUTES from '../../Constants/routes'

import { PaperContent } from '../../Components/Contents'
import { useWindowDimensions } from '../../Utils'

import LeaderboardList from './LeaderboardList'

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Helmet } from 'react-helmet'

const Leaderboards: React.FC<TYPES.LeaderboardsProps> = (props: TYPES.LeaderboardsProps) => {
  // get some props from Redux
  const { leaderboards, location } = props
  const { leaderboards_list } = leaderboards

  // url params
  const { pathname } = location
  const withoutBG = pathname === ROUTES.LEADERBOARDS_WITHOUT_BG

  // set type state
  const [type, setType] = React.useState(null)
  if (leaderboards_list.length && !type) setType(leaderboards_list[0].shortCode)

  // check if the render is for Mobile
  const { isMobile } = useWindowDimensions()

  // define scroll Overlay Options
  const overlayOptions: OverlayScrollbars.Options = {
    resize: 'none',
    paddingAbsolute: true,
    scrollbars: {
      autoHide: isMobile ? 'scroll' : 'never',
      clickScrolling: true
    },
    overflowBehavior: {
      x: 'hidden',
      y: 'scroll'
    }
  }
  /**
   * Render
   */
  return (
    <PaperContent className={withoutBG ? 'leaderboards leaderboards-wb' : 'leaderboards'}>
      <Helmet>
        <title>Project Genesis | Leaderboards</title>
        <meta name="description" content="Leaderboards page featuring seven different stats from Project Genesis." />
      </Helmet>
      <Container>
        <Row>
          <Col className="text-center">
            <h1 className="text-bold leaderboards-title">LEADERBOARDS</h1>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p className="text-center text-bold leaderboards-paragraph">Disclaimer: Project Genesis is currently in pre-alpha.
              <br/>This leaderboard may contain errors,&nbsp;
              <br className="d-md-none"/>or may require a reset from time to time,
              <br/>as new Project Genesis builds become available.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            { !!leaderboards_list.length &&
              (<h3 className="text-bold leaderboards-comment">&#8725;&#8725; SELECT THE STAT&nbsp;
                <br className="d-md-none"/>YOU WANT TO CHECK
              </h3>)
            }
          </Col>
        </Row>
        <Row>
          <Col xs={1} md={3}/>
          <Col md={1} className="d-none d-md-block"><div className="separator-line"/></Col>
          <Col xs={10} md={4} className="text-center px-mx-0">
            {!leaderboards_list.length && (<Spinner as="span" animation="grow" role="status" aria-hidden="true" variant="dark" />)}
            { !!leaderboards_list.length && (
              <Dropdown>
                <Dropdown.Toggle variant="light" id="leaderboards_select" className="text-bold">
                  { leaderboards_list.find(item => { return item.shortCode === type }) ? leaderboards_list.find(item => { return item.shortCode === type }).name : ''}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {
                    leaderboards_list.filter(item => {
                      return item.shortCode !== type
                    }).map(leaderboard => (
                      <Dropdown.Item
                        key={'select_' + leaderboard.shortCode}
                        onClick={() => setType(leaderboard.shortCode)}
                      >
                        {leaderboard.name}
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Col>
          <Col md={1} className="d-none d-md-block"><div className="separator-line"/></Col>
          <Col xs={1} md={3}/>
        </Row>
        <Row>
          <Col xs={1} md={3}/>
          <Col xs={10} md={6} className="text-center px-mx-0">
            <OverlayScrollbarsComponent options={overlayOptions} className="leaderboards-block">
              <Container className="leaderboards-container">
                <LeaderboardList type={type} withBadge={!isMobile}/>
              </Container>
            </OverlayScrollbarsComponent>
          </Col>
          <Col xs={1} md={3}/>
        </Row>
      </Container>
    </PaperContent>
  )
}

export default withRedux(withRouter(Leaderboards))
